import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { clsx } from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import DataSetsItemButtons from 'components/DataSetsItemButtons/DataSetsItemButtons';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';
import { SELECTED_ITEMS } from 'constants/formFields';
import { NUMBER_FORMATS } from 'constants/interfaces';
import formatNumber from 'utils/formatNumber';
import getMultiLabels from 'utils/getMultiLabels';
import getNaceLabel from 'utils/getNaceLabel';

import type { IOwnOperationsItem } from './interfaces/IOwnOperationsItem';

import styles from './scss/OwnOperationsItem.module.scss';

function OwnOperationsItem({
  item, control, onArchiveModalInfoOpen, onMoveToArchive, onDeArchive, onEditRecord, isActionsVisible, naceCodeTreeData,
}: IOwnOperationsItem): React.ReactElement {
  const { t } = useTranslation();
  const {
    id, name, valueChainSection, legalName,
    archived, latestArchivation, uniqueIdentifier, profitCenterId, ownership, equityShareholding,
    financialControl, businessUnitType, assetCategory, ownedLeased, numberOfEmployees, dateOpened, dateClosed,
    naceSection, naceCode, euTaxonomyCode, netBookValue, addressCountry, addressState, addressPostCode, addressCity,
    addressStreet, addressBuilding, addressCoordinates,
  } = item;

  const { naceCodeLabel, naceSectionLabel } = getNaceLabel(
    { naceCode, naceSection, naceCodeTreeData },
  );

  const hyphen = t('common.hyphen');

  return (
    <TableRow className={clsx(undefined, { [styles.archived]: archived })}>
      <TableCell>
        <div className={styles.staticCell}>
          {archived ? (
            <span>
              {name}
              {latestArchivation ? (
                <InfoIcon
                  onClick={() => onArchiveModalInfoOpen(latestArchivation)}
                />
              ) : null}
            </span>
          ) : null}

          {!archived && (
            isActionsVisible ? (
              <Controller
                control={control}
                render={({ field: { onChange, value, disabled } }) => (
                  <Checkbox
                    key={id}
                    name={`${SELECTED_ITEMS}-${id}`}
                    checked={!!value.includes(id)}
                    value={id}
                    disabled={disabled}
                    onChange={(event) => {
                      onChange(event.target.checked
                        ? [...value, id]
                        : value.filter((selectedItem) => selectedItem !== id));
                    }}
                    label={(<span>{name}</span>)}
                  />
                )}
                name={SELECTED_ITEMS}
              />
            ) : (<span>{name}</span>)
          )}
        </div>
      </TableCell>
      <TableCell>
        {uniqueIdentifier || hyphen}
      </TableCell>
      <TableCell>
        {legalName || hyphen}
      </TableCell>
      <TableCell>
        {profitCenterId ? getMultiLabels({ values: profitCenterId }) : hyphen}
      </TableCell>
      <TableCell>
        {valueChainSection || hyphen}
      </TableCell>
      <TableCell>
        {ownership || hyphen}
      </TableCell>
      <TableCell>
        {formatNumber(
          {
            value: equityShareholding,
            type: NUMBER_FORMATS.percent,
          },
        )}
      </TableCell>
      <TableCell>
        {formatNumber(
          {
            value: financialControl,
            type: NUMBER_FORMATS.percent,
          },
        )}
      </TableCell>
      <TableCell>
        {businessUnitType || hyphen}
      </TableCell>
      <TableCell>
        {assetCategory ? getMultiLabels({ values: assetCategory }) : hyphen}
      </TableCell>
      <TableCell>
        {ownedLeased || hyphen}
      </TableCell>
      <TableCell>
        {formatNumber(
          {
            value: numberOfEmployees,
            type: NUMBER_FORMATS.number,
          },
        )}
      </TableCell>
      <TableCell>
        {dateOpened || hyphen}
      </TableCell>
      <TableCell>
        {dateClosed || hyphen}
      </TableCell>
      <TableCell>
        {naceSectionLabel || hyphen}
      </TableCell>
      <TableCell>
        {naceCodeLabel || hyphen}
      </TableCell>
      <TableCell>
        {euTaxonomyCode || hyphen}
      </TableCell>
      <TableCell>
        {formatNumber(
          {
            value: netBookValue?.amount,
            currency: netBookValue.currency,
          },
        )}
      </TableCell>
      <TableCell>
        {addressCountry || hyphen}
      </TableCell>
      <TableCell>
        {addressState || hyphen}
      </TableCell>
      <TableCell>
        {addressPostCode || hyphen}
      </TableCell>
      <TableCell>
        {addressCity || hyphen}
      </TableCell>
      <TableCell>
        {addressStreet || hyphen}
      </TableCell>
      <TableCell>
        {addressBuilding || hyphen}
      </TableCell>
      <TableCell>
        {addressCoordinates || hyphen}
      </TableCell>
      {isActionsVisible ? (
        <TableCell>
          <DataSetsItemButtons
            id={id}
            archived={archived}
            onEditRecord={onEditRecord}
            onMoveToArchive={onMoveToArchive}
            onDeArchive={onDeArchive}
          />
        </TableCell>
      ) : null}
    </TableRow>
  );
}

export default OwnOperationsItem;
