import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import TextField from 'components/TextField/TextField';
import { AppRoutes } from 'constants/AppRoutes';
import { EMAIL, PASSWORD } from 'constants/formFields';
import { INPUT_TYPES } from 'constants/interfaces';

import type { ReactElement } from 'react';
import type { ILoginView } from './interfaces/ILoginView';

import styles from './sass/LoginView.module.scss';

function LoginView({
  control, onLoginSubmit, isButtonDisabled, onChangeEmailField, isPasswordVisible, isLoading,
}: ILoginView): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className="authTitle">
        {t('login.title')}
      </div>
      <div className={styles.formWrapper}>
        <Controller
          control={control}
          name={EMAIL}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextField
              name={name}
              type={INPUT_TYPES.email}
              value={value}
              label={t('common.email')}
              placeholder={t('common.emailPlaceholder')}
              error={error?.message}
              onChange={(event) => {
                onChange(event.target.value);
                onChangeEmailField(event);
              }}
              required
            />
          )}
        />

        {isPasswordVisible ? (
          <Controller
            control={control}
            name={PASSWORD}
            render={({ field: { name, value, onChange }, fieldState: { error: fieldError } }) => (
              <TextField
                name={name}
                type={INPUT_TYPES.password}
                value={value}
                label={t('common.password')}
                placeholder={t('common.enterPassword')}
                error={fieldError?.message}
                onChange={(event) => {
                  onChange(event.target.value);
                }}
              />
            )}
          />
        ) : null}

        <Link to={AppRoutes.recoverPassword}>
          {t('login.forgotPassword')}
        </Link>

        <Button
          className={styles.button}
          title={t('common.continue')}
          onClick={onLoginSubmit}
          disabled={isButtonDisabled}
          isLoading={isLoading}
        />
      </div>
    </div>

  );
}

export default LoginView;
