import type { DropOptions } from '@minoru/react-dnd-treeview';
import type INodeModelData from '../pages/UserPages/OwnOperations/components/FiltersBar/interfaces/INodeModelData';

function getNewPathStructure(currenDragItem: DropOptions<INodeModelData> | undefined) {
  const { dragSource, dropTarget } = currenDragItem || {};
  const newDragItemLastPath = dragSource?.data?.path.split('/')?.slice(-1)?.[0] || '';
  return `${dropTarget?.data?.path.trim()} / ${newDragItemLastPath.trim()}`;
}

export default getNewPathStructure;
