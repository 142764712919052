import { useUserMeQuery } from 'store/slices/user/apis/user';

import LaunchpadView from './LaunchpadView';

import type { ReactElement } from 'react';

function Launchpad(): ReactElement {
  const { isFetching } = useUserMeQuery();
  return (<LaunchpadView isLoading={isFetching} />);
}

export default Launchpad;
