import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import useCompanyStatusChange from 'hooks/useCompanyStatusChange';
import useTheme from 'hooks/useTheme';
import { useAppDispatch } from 'store/hooks/useApp';
import { setAuthState } from 'store/slices/auth/slice';
import {
  useGetCompanyDetailsQuery,
  useGetCompanyPermissionsQuery,
} from 'store/slices/companies/apis/companiesApi';
import { useUserMeQuery } from 'store/slices/user/apis/user';

import CompanyProfileView from './CompanyProfileView';

import type { TCompanyStatus } from 'constants/interfaces';

function CompanyProfile() {
  const { clientId = '' } = useParams();
  const dispatch = useAppDispatch();

  const { data: userMe, refetch: refetchMe, isFetching: isUserMeFetching } = useUserMeQuery();
  const isInitiator = !!userMe?.data?.initiator;
  const { resetToDefaultTheme } = useTheme();
  useEffect(() => {
    if (isInitiator && !isUserMeFetching) {
      resetToDefaultTheme();
      dispatch(setAuthState({ userAccessToken: '', userRefreshToken: '' }));
      refetchMe();
    }
  }, [isInitiator, dispatch, resetToDefaultTheme, refetchMe, isUserMeFetching]);

  const fetchQuery = clientId ? {
    companyId: +clientId,
  } : skipToken;

  const { data: company, isFetching } = useGetCompanyDetailsQuery(fetchQuery);
  const { data: permissions, isFetching: isCompanyPermissionsFetching } = useGetCompanyPermissionsQuery(fetchQuery);

  const {
    isDeactivationModalOpen,
    onToggleDeactivationModal,
    onChangeCompanyStatus,
    onCompanyDeactivate,
    isActionsLoading,
  } = useCompanyStatusChange({
    companyId: +clientId,
    status: company?.data.status as TCompanyStatus,
  });

  const isPageLoading = isFetching || isCompanyPermissionsFetching || isActionsLoading;

  return (
    <CompanyProfileView
      company={company?.data}
      permissions={permissions?.data}
      onChangeCompanyStatus={onChangeCompanyStatus}
      isDeactivateCompanyModalOpen={isDeactivationModalOpen}
      onCloseDeactivateCompanyModal={onToggleDeactivationModal}
      onConfirmDeactivateCompanyModal={onCompanyDeactivate}
      isPageLoading={isPageLoading}
    />
  );
}

export default CompanyProfile;
