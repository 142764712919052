import { useEffect, useState } from 'react';
import {
  generatePath,
  useBlocker, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/query';
import AppRoutes from 'constants/AppRoutes';
import { BLOCKER_STATES } from 'constants/general';
import { USER_PILLAR_ACCESS_TYPES, USER_ROLE_TYPES } from 'constants/interfaces';
import i18n from 'i18n';
import { useAppDispatch, useAppSelector } from 'store/hooks/useApp';
import { useInviteUserMutation } from 'store/slices/auth/apis/auth';
import {
  useUpdateUserPermissionsMutation, useUserDetailsQuery,
  useUserPermissionsQuery,
} from 'store/slices/companyAdminUsers/apis/companyAdminUsersApi';
import selectUserPermissions, { selectCreateFlowUserId } from 'store/slices/companyAdminUsers/selectors';
import { resetState } from 'store/slices/companyAdminUsers/slice';

import usePermissionsForm from './hooks/usePermissionsForm';
import PermissionsView from './PermissionsView';

import type { TPillarModules } from 'store/slices/companyAdminUsers/interfaces/IUpdateUserPermissionsRequest';
import type { TUserPermissionsSchema } from 'utils/validators';
import type { IPermissions } from './interfaces/IPermissions';

function Permissions({ isCreateFlow }: IPermissions) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { userId = '' } = useParams();

  const newUserId = useAppSelector(selectCreateFlowUserId);

  const currentUserId = userId || newUserId;

  const fetchQuery = userId ? {
    userId,
  } : skipToken;

  const { pillars, isLoading: isUserPermissionsLoading } = useUserPermissionsQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, pillars: selectUserPermissions(result.data) }),
    },
  );

  const [inviteUser, { isLoading: isInviteUserLoading }] = useInviteUserMutation();

  const fetchUserDetailsQuery = currentUserId ? {
    userId: +currentUserId,
  } : skipToken;
  const { data, isLoading: isUserDetailsLoading } = useUserDetailsQuery(fetchUserDetailsQuery);

  const {
    control,
    handleSubmit,
    isDirty,
    reset,
  } = usePermissionsForm({ pillars });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => isDirty
      && currentLocation.pathname !== nextLocation.pathname
      && !isConfirmationModalOpen);

  const [updateUserPermissions, { isLoading: isUpdateUserLoading }] = useUpdateUserPermissionsMutation();

  const { role } = data?.data || {};
  const isDisabled = role === USER_ROLE_TYPES.companyAdmin;

  const isLoading = isUpdateUserLoading || isInviteUserLoading || isUserPermissionsLoading || isUserDetailsLoading;

  useEffect(() => {
    if (blocker.state === BLOCKER_STATES.blocked) {
      if (isDirty) {
        setIsConfirmationModalOpen(true);
      } else {
        blocker.proceed?.();
      }
    }
  }, [blocker, isDirty]);

  const onPermissionsSubmit = handleSubmit((values: TUserPermissionsSchema) => {
    const { email } = data?.data || {};
    if (isDisabled && email) {
      if (newUserId) {
        inviteUser({ email }).unwrap().then(() => {
          toast.success(i18n.t('notifications.informationUpdatedAndSendInvite'));
        }).catch(() => {});
      }
      dispatch(resetState());
      navigate(AppRoutes.userManagement);
    } else {
      const formattedModules = values?.pillars?.reduce(
        (acc: TPillarModules[], pillar) => {
          const modules = pillar.pillarModules.map((module) => ({
            id: module.id,
            access: module.access || USER_PILLAR_ACCESS_TYPES.noAccess,
          }));

          return [...acc, ...modules];
        },
        [],
      )?.filter((module) => module.access !== USER_PILLAR_ACCESS_TYPES.dimmedDisabled);

      const requestData = {
        pillarModules: formattedModules,
      };

      updateUserPermissions({
        userId,
        data: requestData,
      }).unwrap().then(() => {
        if (newUserId && email) {
          inviteUser({ email }).unwrap().then(() => {
            toast.success(i18n.t('notifications.informationUpdatedAndSendInvite'));
          }).catch(() => {});
        } else {
          toast.success(i18n.t('notifications.informationUpdated'));
        }
        reset(undefined, { keepValues: true });
        dispatch(resetState());
        if (location.state?.redirectPath) {
          navigate(location.state.redirectPath);
        } else {
          navigate(
            generatePath(AppRoutes.userInfo, { userId: String(userId) }),
          );
        }
      }).catch(() => {});
    }
  });

  const onHandleClose = () => {
    if (isDirty) {
      setIsConfirmationModalOpen(true);
    } else if (location.state?.redirectPath) {
      navigate(location.state.redirectPath);
    } else {
      navigate(-1);
    }
  };

  const onHandleCloseConfirmation = (isOpen = false) => {
    setIsConfirmationModalOpen(isOpen);
  };
  const onPrevClick = () => {
    navigate(-1);
  };

  const onDiscardChangesConfirm = () => {
    const currentLocation = blocker?.location?.pathname;
    if (currentLocation) {
      navigate(currentLocation);
    } else if (location.state?.redirectPath) {
      navigate(location.state.redirectPath);
    }

    reset(undefined, { keepValues: true });
  };

  return (
    <PermissionsView
      control={control}
      onPermissionsSubmit={onPermissionsSubmit}
      onHandleCloseConfirmation={onHandleCloseConfirmation}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onPrevClick={onPrevClick}
      onDiscardChangesConfirm={onDiscardChangesConfirm}
      pillars={pillars}
      isCreateFlow={isCreateFlow}
      onHandleClose={onHandleClose}
      isDisabled={isDisabled}
      isLoading={isLoading}
    />
  );
}

export default Permissions;
