import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { AppRoutes } from 'constants/AppRoutes';
import { PHONE } from 'constants/formFields';
import { useAppSelector } from 'store/hooks/useApp';
import { useStartSyncWithPhoneMutation } from 'store/slices/auth/apis/auth';
import { authTokensSelector } from 'store/slices/auth/selectors';
import { useLoginInfoQuery } from 'store/slices/user/apis/user';

import useVerificationSmsSetupForm from './hooks/useVerificationSmsSetupForm';
import VerificationSmsSetupView from './VerificationSmsSetupView';

import type { ReactElement } from 'react';
import type { ParsedCountry } from 'react-international-phone/dist/types';

function VerificationSmsSetup(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [parsedCountry, setParsedCountry] = useState<ParsedCountry | undefined>(undefined);

  const { verifyToken } = useAppSelector(authTokensSelector);

  const queryParams = verifyToken ? {} : skipToken;

  const { refetch, isLoading: isLoginInfoLoading } = useLoginInfoQuery(queryParams);

  const [startSyncWithPhone, { isLoading: isStartSyncLoading }] = useStartSyncWithPhoneMutation();
  const {
    control,
    handleSubmit,
    isValid,
    setError,
  } = useVerificationSmsSetupForm({ parsedCountry });

  const onVerificationCodeSubmit = handleSubmit((values) => {
    const requestData = {
      data: values,
      verifyToken,
    };
    startSyncWithPhone(requestData)
      .unwrap().then(() => {
        refetch();
        navigate(AppRoutes.verificationSms);
      }).catch(({ data }) => {
        if (data?.error) {
          setError(PHONE, { message: t('common.invalidPhone') });
        }
      });
  });

  const isLoading = isLoginInfoLoading || isStartSyncLoading;

  return (
    <VerificationSmsSetupView
      control={control}
      onVerificationCodeSubmit={onVerificationCodeSubmit}
      isValid={isValid}
      setParsedCountry={setParsedCountry}
      isLoading={isLoading}
    />
  );
}

export default VerificationSmsSetup;
