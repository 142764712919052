import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal/Modal';
import Separator from 'components/Separator/Separator';
import { DATE_FORMAT } from 'constants/general';
import dayjs from 'dayjs';

import type IDeactivationInfoModal from './interfaces/IDeactivationInfoModal';

import styles from './scss/DeactivationInfoModal.module.scss';

function DeactivationInfoModal({
  open, title, deactivationInfo, onClose, isLoading,
}: IDeactivationInfoModal) {
  const { t } = useTranslation();

  const initiatorName = deactivationInfo?.initiator
    ? `${deactivationInfo.initiator.firstName} ${deactivationInfo.initiator.lastName}`
    : null;
  const formattedDate = dayjs(deactivationInfo?.date).format(DATE_FORMAT);

  return (
    <Modal
      open={open}
      title={title || t('common.deactivationDetails')}
      onClose={onClose}
      isButtonsVisible={false}
      isLoading={isLoading}
    >
      <div className={styles.root}>
        {initiatorName ? (
          <>
            <div className={styles.item}>
              <p>{t('common.person')}</p>
              <p>{initiatorName}</p>
            </div>
            <Separator className={styles.separator} />
          </>
        ) : null}
        <div className={styles.item}>
          <p>{t('common.date')}</p>
          <p>{formattedDate}</p>
        </div>
        <Separator className={styles.separator} />
        <div className={styles.item}>
          <p>{t('common.reason')}</p>
          <p>{deactivationInfo?.reason || '-'}</p>
        </div>
      </div>
    </Modal>
  );
}

export default DeactivationInfoModal;
