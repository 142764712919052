import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';
import { SMS_CODE } from 'constants/formFields';
import { SMS_TIME_LEFT } from 'constants/general';
import { STATUS } from 'constants/interfaces';
import LOCAL_STORAGE_KEYS from 'constants/localStorageKeys';
import useTimer from 'hooks/useTimer';
import { useAppDispatch } from 'store/hooks/useApp';
import {
  useEndSyncWithPhoneMutation,
  useSendSmsMutation,
  useVerifyViaPhoneMutation,
} from 'store/slices/auth/apis/auth';
import { setAuthState } from 'store/slices/auth/slice';
import { useLazyUserMeQuery, useLoginInfoQuery } from 'store/slices/user/apis/user';

import useVerificationSmsForm from './useVerificationSmsForm';

import type { IUseVerificationSms } from '../interfaces/IUseVerificationSms';

const useVerificationSms = ({ onModalClose = () => {}, onResetBlockers = () => {} }: IUseVerificationSms) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const isAuthVerificationSmsPage = !!useMatch(AppRoutes.verificationSms);

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    isValid,
    setError,
  } = useVerificationSmsForm();

  const { data: userData, isLoading: isLoginInfoLoading } = useLoginInfoQuery({});
  const [getUserMe] = useLazyUserMeQuery();
  const { phone, profile } = userData?.data || {};

  const { mfaSmsEnabled } = profile || {};

  const [verifyViaPhone, { isLoading: isVerifyViaPhoneLoading }] = useVerifyViaPhoneMutation();
  const [endSyncWithPhone, { isLoading: isEndSyncLoading }] = useEndSyncWithPhoneMutation();
  const [sendSms, { isLoading: isSendSmsLoading }] = useSendSmsMutation();

  const { time, isTimeUp, resetTimer } = useTimer(
    { storageKey: LOCAL_STORAGE_KEYS.smsTimer, timeLeft: SMS_TIME_LEFT },
  );

  const onResendCode = () => {
    sendSms();
    resetTimer();
  };

  const sendSmsEndpoint = mfaSmsEnabled === STATUS.enabled ? verifyViaPhone : endSyncWithPhone;

  const onVerificationSmsSubmit = handleSubmit((values) => {
    sendSmsEndpoint(values)
      .unwrap().then(({ data }) => {
        if (data) {
          dispatch(
            setAuthState({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              verifyToken: data.verifyToken,
            }),
          );
          onModalClose?.(true);
          onResetBlockers?.();
          if (isAuthVerificationSmsPage) {
            navigate(AppRoutes.launchpad);
          } else {
            getUserMe();
          }
        }
      }).catch(({ data }) => {
        if (data?.error) {
          setError(SMS_CODE, { message: t('authenticationModal.invalidCode') });
        }
      });
  });

  const isLoading = isVerifyViaPhoneLoading || isEndSyncLoading || isSendSmsLoading || isLoginInfoLoading;

  return {
    control,
    onVerificationSmsSubmit,
    onResendCode,
    time,
    isTimeUp,
    phone,
    isButtonDisabled: !isValid,
    isLoading,
  };
};

export default useVerificationSms;
