import { useEffect, useState } from 'react';
import {
  generatePath, useBlocker, useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';
import { BLOCKER_STATES } from 'constants/general';
import { useAppDispatch, useAppSelector } from 'store/hooks/useApp';
import { useCreateUserDetailsMutation, useUpdateUserDetailsMutation } from 'store/slices/companyAdminUsers/apis/companyAdminUsersApi';
import { selectCreateFlowUserId } from 'store/slices/companyAdminUsers/selectors';
import { setCompanyAdminUsersState } from 'store/slices/companyAdminUsers/slice';

import useCreateNewUserForm from './hooks/useCreateNewUserForm';
import CreateNewUserView from './CreateNewUserView';

import type { ReactElement } from 'react';

function CreateNewUser(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { userId = '' } = useParams();

  const newUserId = useAppSelector(selectCreateFlowUserId);
  const currentUserId = userId || newUserId;

  const {
    control,
    handleSubmit,
    isDirty,
    reset,
  } = useCreateNewUserForm({ currentUserId });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [updateUserDetails, { isLoading: isUpdateUserLoading }] = useUpdateUserDetailsMutation();
  const [createUserDetails, { isLoading: isCreateUserLoading }] = useCreateUserDetailsMutation();

  const blocker = useBlocker(({ currentLocation, nextLocation }) => isDirty
      && currentLocation.pathname !== nextLocation.pathname
      && !isConfirmationModalOpen);

  const isLoading = isUpdateUserLoading || isCreateUserLoading;

  useEffect(() => {
    if (blocker.state === BLOCKER_STATES.blocked) {
      if (isDirty) {
        setIsConfirmationModalOpen(true);
      } else {
        blocker.proceed?.();
      }
    }
  }, [blocker, isDirty]);

  const onCreateNewUserSubmit = handleSubmit((values) => {
    const requestData = {
      firstName: values.firstName,
      lastName: values.lastName,
      role: values.role.value,
    };
    if (currentUserId) {
      updateUserDetails({ userId: Number(currentUserId), data: requestData }).unwrap().then(() => {
        reset(undefined, { keepValues: true });
        if (userId) {
          navigate(
            generatePath(AppRoutes.editUserPermissions, { userId: String(userId) }),
            { state: location.state },
          );
        } else {
          navigate(
            generatePath(AppRoutes.createUserPermissions, { userId: String(newUserId) }),
            { state: location.state },
          );
        }
      });
    } else {
      createUserDetails({ data: { ...requestData, ...{ email: values.email } } }).unwrap().then(({ data }) => {
        const { id } = data;
        reset(undefined, { keepValues: true });
        dispatch(setCompanyAdminUsersState({ createFlowUserId: data.id }));
        navigate(generatePath(AppRoutes.createUserPermissions, { userId: `${id}` }));
      }).catch(() => {});
    }
  });

  const onHandleCloseConfirmation = (isOpen = false) => {
    if (!isConfirmationModalOpen && !isDirty) {
      if (location.state?.redirectPath) {
        navigate(location.state.redirectPath);
      } else {
        navigate(-1);
      }
    } else {
      setIsConfirmationModalOpen(isOpen);
    }
  };

  const onDiscardChangesConfirm = () => {
    const currentLocation = blocker?.location?.pathname;
    if (currentLocation) {
      navigate(currentLocation);
    } else if (location.state?.redirectPath) {
      navigate(location.state.redirectPath);
    }

    reset(undefined, { keepValues: true });
  };

  return (
    <CreateNewUserView
      control={control}
      onCreateNewUserSubmit={onCreateNewUserSubmit}
      onHandleCloseConfirmation={onHandleCloseConfirmation}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onDiscardChangesConfirm={onDiscardChangesConfirm}
      isLoading={isLoading}
    />
  );
}

export default CreateNewUser;
