import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppRoutes } from 'constants/AppRoutes';
import {
  CURRENT_PASSWORD, PASSWORD, REPEAT_NEW_PASSWORD,
} from 'constants/formFields';
import { EMPTY_SYMBOL } from 'constants/general';
import { ERROR_CODES } from 'constants/statusCodes';
import { useAppDispatch } from 'store/hooks/useApp';
import { useChangePasswordMutation } from 'store/slices/auth/apis/auth';
import { logoutUser } from 'store/slices/auth/slice';
import helpersPasswordStrengthMeter from 'utils/helpersPasswordStrengthMeter';

import useProfilePasswordForm from './hooks/useProfilePasswordForm';
import ProfilePasswordView from './ProfilePasswordView';

import type { ReactElement } from 'react';

function ProfilePassword(): ReactElement {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    password,
    setError,
    isValid,
  } = useProfilePasswordForm();

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const onProfilePasswordSubmit = handleSubmit((values) => {
    setIsModalOpen(false);
    changePassword(values).unwrap().then(() => {
      toast.success(t('notifications.passwordSuccessfullyChanged'));
      dispatch(
        logoutUser(),
      );
    }).catch(({ data }) => {
      if (data?.errorCode === ERROR_CODES.badRequest) {
        toast.error(t('notifications.unableSaveNewPassword'));
        setError(CURRENT_PASSWORD, { message: EMPTY_SYMBOL });
        setError(PASSWORD, { message: EMPTY_SYMBOL });
        setError(REPEAT_NEW_PASSWORD, { message: EMPTY_SYMBOL });
      }
    });
  });

  const onCancel = () => {
    navigate(-1);
  };

  const onConfirmationModalOpen = () => {
    setIsModalOpen(true);
  };

  const onReject = () => {
    setIsModalOpen(false);
  };

  const passwordStrengthMeter = helpersPasswordStrengthMeter({ value: password });

  const isRecoverPasswordPath = !!useMatch(AppRoutes.profilePassword);

  return (
    <ProfilePasswordView
      control={control}
      onProfilePasswordSubmit={onProfilePasswordSubmit}
      passwordStrengthMeter={passwordStrengthMeter}
      onCancel={onCancel}
      isModalOpen={isModalOpen}
      onConfirmationModalOpen={onConfirmationModalOpen}
      isValid={isValid}
      onReject={onReject}
      isRecoverPasswordPath={isRecoverPasswordPath}
      isLoading={isLoading}
    />
  );
}

export default ProfilePassword;
