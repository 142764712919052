import { useTranslation } from 'react-i18next';
import ClientPanel from 'components/ClientPanel/ClientPanel';
import SaveDraftButton from 'components/SaveDraftButton/SaveDraftButton';

import UIConfigurationForm from './components/UIConfigurationForm/UIConfigurationForm';
import UIConfigurationPreview from './components/UIConfigurationPreview/UIConfigurationPreview';

import type IUIConfigurationView from './interfaces/IUIConfigurationView';

import styles from './scss/UIConfigurationView.module.scss';

function UIConfigurationView({
  control, isCreatePage, isDisabledSave, onPrevClick, onSave, isDirty,
  isSuperAdmin, onDraftSave, isActionsVisible, isCompanyUiConfigurationPage, isLoading,
}: IUIConfigurationView) {
  const { t } = useTranslation();

  return (
    <>
      {isCreatePage ? <SaveDraftButton isDisabled={!isDirty || isDisabledSave} onDraftSave={onDraftSave} /> : null }
      <ClientPanel
        title={isCompanyUiConfigurationPage ? '' : t('clients.uiConfiguration')}
        text={isCompanyUiConfigurationPage ? '' : t('clients.uploadLogoAndPalette')}
        onPrevClick={onPrevClick}
        onSave={onSave}
        isCreate={isCreatePage}
        isDisabledSave={isDisabledSave}
        isDirty={isDirty}
        isActionsVisible={isActionsVisible}
        isLoading={isLoading}
      >
        <div className={styles.wrapper}>
          <UIConfigurationForm
            control={control}
            isSuperAdmin={isSuperAdmin}
            isActionsVisible={isActionsVisible}
          />
          <UIConfigurationPreview
            control={control}
            isSuperAdmin={isSuperAdmin}
            isActionsVisible={isActionsVisible}
          />
        </div>
      </ClientPanel>
    </>
  );
}

export default UIConfigurationView;
