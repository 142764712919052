import { DATA_SETS_ENUM_TYPES, UID_NAME_TUPLES, VALUE_CHAIN_SECTION_DEFAULT_VALUES } from 'constants/dataSets';
import {
  ADDRESS_BUILDING,
  ADDRESS_CITY,
  ADDRESS_COORDINATES,
  ADDRESS_COUNTRY,
  ADDRESS_POST_CODE,
  ADDRESS_STATE,
  ADDRESS_STREET,
  ASSET_CATEGORY,
  BUSINESS_UNIT_TYPE,
  DATE_CLOSED,
  DATE_OPENED,
  EQUITY_SHAREHOLDING,
  EU_TAXONOMY_CODE,
  FINANCIAL_CONTROL,
  LEGAL_NAME,
  NACE_CODE,
  NACE_SECTION,
  NAME,
  NET_BOOK, NET_BOOK_CURRENCY,
  NUMBER_OF_EMPLOYEES,
  ORG_PATH,
  OWNED_LEASED,
  OWNERSHIP,
  PROFIT_CENTER_ID,
  UNIQUE_IDENTIFIER,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { DATA_SETS_DATE_FORMAT } from 'constants/general';
import dayjs from 'dayjs';

import createEnumOptions from '../createEnumOptions';
import createUidNameTuplesOptions from '../createUidNameTuplesOptions';
import getFormValue from '../getFormValue';
import getNaceLabel from '../getNaceLabel';

import type { IGetOwnOperationsDefaultValues } from './interfaces/IGetOwnOperationsDefaultValues';

function getOwnOperationsDefaultValues({
  values, naceCodeTreeData, uidNameTuples, enumData,
}: IGetOwnOperationsDefaultValues) {
  const {
    name, orgPath, uniqueIdentifier, legalName, profitCenterId, valueChainSection, ownership, equityShareholding,
    financialControl, businessUnitType, assetCategory, ownedLeased, numberOfEmployees, dateOpened, dateClosed,
    naceSection, naceCode, euTaxonomyCode, netBookValue, addressCountry, addressState, addressPostCode,
    addressCity, addressStreet, addressBuilding, addressCoordinates,
  } = values || {};

  const defaultOption = undefined;

  const { naceSectionLabel, naceDvisions, naceCodeLabel } = getNaceLabel(
    { naceCode: naceCode || '', naceSection, naceCodeTreeData },
  );
  const profitCenterOptions = createUidNameTuplesOptions({
    uidNameTuples,
    values: profitCenterId || [],
    tuplesKey: UID_NAME_TUPLES.profitCenter,
  });

  const assetCategoryOptions = createEnumOptions({
    enumData,
    values: assetCategory || [],
    enumKey: DATA_SETS_ENUM_TYPES.assetCategory,
  });

  return {
    [NAME]: name || '',
    [ORG_PATH]: orgPath || '',
    [UNIQUE_IDENTIFIER]: uniqueIdentifier || '',
    [LEGAL_NAME]: legalName || '',
    [PROFIT_CENTER_ID]: profitCenterOptions?.length > 0 ? profitCenterOptions : [],
    [VALUE_CHAIN_SECTION]: valueChainSection ? {
      value: valueChainSection, label: valueChainSection,
    } : {
      value: VALUE_CHAIN_SECTION_DEFAULT_VALUES.ownOperations,
      label: VALUE_CHAIN_SECTION_DEFAULT_VALUES.ownOperations,
    },
    [OWNERSHIP]: ownership ? {
      value: ownership, label: ownership,
    } : defaultOption,
    [EQUITY_SHAREHOLDING]: getFormValue({ value: equityShareholding }),
    [FINANCIAL_CONTROL]: getFormValue({ value: financialControl }),
    [BUSINESS_UNIT_TYPE]: businessUnitType ? {
      value: businessUnitType, label: businessUnitType,
    } : defaultOption,
    [ASSET_CATEGORY]: assetCategoryOptions?.length > 0 ? assetCategoryOptions : [],
    [OWNED_LEASED]: ownedLeased ? {
      value: ownedLeased, label: ownedLeased,
    } : defaultOption,
    [NUMBER_OF_EMPLOYEES]: getFormValue({ value: numberOfEmployees }),
    [DATE_OPENED]: dateOpened ? dayjs(dateOpened).format(DATA_SETS_DATE_FORMAT) : '',
    [DATE_CLOSED]: dateClosed ? dayjs(dateClosed).format(DATA_SETS_DATE_FORMAT) : '',
    [NACE_SECTION]: naceSection ? {
      value: naceSection,
      label: naceSectionLabel || '',
      divisions: naceDvisions,
    } : defaultOption,
    [NACE_CODE]: naceCode ? {
      value: naceCode,
      label: naceCodeLabel || '',
    } : defaultOption,
    [EU_TAXONOMY_CODE]: euTaxonomyCode || '',
    [NET_BOOK]: getFormValue({ value: netBookValue?.amount }),
    [NET_BOOK_CURRENCY]: netBookValue?.currency ? {
      value: netBookValue.currency, label: netBookValue.currency,
    } : defaultOption,
    [ADDRESS_COUNTRY]: addressCountry ? {
      value: addressCountry, label: addressCountry,
    } : defaultOption,
    [ADDRESS_STATE]: addressState ? {
      value: addressState, label: addressState,
    } : defaultOption,
    [ADDRESS_POST_CODE]: addressPostCode || '',
    [ADDRESS_CITY]: addressCity ? {
      value: addressCity, label: addressCity,
    } : defaultOption,
    [ADDRESS_STREET]: addressStreet || '',
    [ADDRESS_BUILDING]: addressBuilding || '',
    [ADDRESS_COORDINATES]: addressCoordinates || '',
  };
}

export default getOwnOperationsDefaultValues;
