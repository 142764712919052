import { useEffect, useState } from 'react';
import {
  useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/query';
import AppRoutes from 'constants/AppRoutes';
import { DATA_SETS_ENUM_TYPES } from 'constants/dataSets';
import {
  NACE_CODE,
  NACE_SECTION,
} from 'constants/formFields';
import { BLOCKER_STATES } from 'constants/general';
import useDataSetsDate from 'hooks/useDataSetsDate';
import useFieldsErrors from 'hooks/useFieldsErrors';
import useGeoLocations from 'hooks/useGeoLocations';
import i18n from 'i18n';
import {
  useEnumQuery, useGetNaceCodeTreeQuery, useSupplierCreateMutation, useSupplierRecordQuery,
  useSupplierRecordUpdateMutation,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectEnums, selectNaceCodes } from 'store/slices/dataSets/selectors';
import getRequestDataForSupplier from 'utils/formHelpers/getRequestDataForSupplier';

import useCreateSupplierForm from './hooks/useCreateSupplierForm';
import CreateSupplierView from './CreateSupplierView';

import type { ReactElement } from 'react';
import type { Path, PathValue } from 'react-hook-form';
import type ISupplierRecordRequest from 'store/slices/dataSets/interfaces/ISupplierRecordRequest';
import type { TCreateSupplierSchema } from 'utils/validators';

function CreateSupplier(): ReactElement {
  const navigate = useNavigate();
  const { recordId = '' } = useParams();

  const { data: naceCodesData, isLoading: isNaceCodesTreeLoading } = useGetNaceCodeTreeQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: selectNaceCodes(result.data) }),
  });

  const [supplierCreate, { isLoading: isSupplierCreateLoading }] = useSupplierCreateMutation();

  const [supplierRecordUpdate, { isLoading: isSupplierRecordUpdateLoading }] = useSupplierRecordUpdateMutation();
  const { setFieldsErrors } = useFieldsErrors();

  const { data: enumData, isLoading: isEnumLoading } = useEnumQuery({
    types: [DATA_SETS_ENUM_TYPES.currency, DATA_SETS_ENUM_TYPES.ownership, DATA_SETS_ENUM_TYPES.valueChainSection,
      DATA_SETS_ENUM_TYPES.businessUnitType, DATA_SETS_ENUM_TYPES.assetCategory, DATA_SETS_ENUM_TYPES.ownedLeased,
      DATA_SETS_ENUM_TYPES.upstreamTitle],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectEnums(result.data) }),
  });

  const fetchQuery = recordId ? {
    recordId,
  } : skipToken;
  const { isLoading: isSupplierRecordLoading } = useSupplierRecordQuery(fetchQuery);

  const isPageLoading = isEnumLoading || isNaceCodesTreeLoading
      || isSupplierRecordLoading || isSupplierCreateLoading || isSupplierRecordUpdateLoading;

  const supplierEndpoint = recordId ? supplierRecordUpdate : supplierCreate;
  const {
    control,
    handleSubmit,
    isDirty,
    reset,
    setError,
    fieldsName,
    watch,
    setValue,
  } = useCreateSupplierForm({ recordId, isPageLoading });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => isDirty
      && currentLocation.pathname !== nextLocation.pathname
      && !isConfirmationModalOpen);

  useEffect(() => {
    if (blocker.state === BLOCKER_STATES.blocked) {
      if (isDirty) {
        setIsConfirmationModalOpen(true);
      } else {
        blocker.proceed?.();
      }
    }
  }, [blocker, isDirty]);

  const onCreateSupplierSubmit = handleSubmit((values) => {
    const requestData: ISupplierRecordRequest = getRequestDataForSupplier({ values, recordId });

    supplierEndpoint(requestData).unwrap()
      .then(
        () => {
          toast.success(i18n.t(
            recordId ? 'notifications.recordHasBeenUpdated'
              : 'notifications.recordHasBeenAdded',
          ));
          reset(undefined, { keepValues: true });
          navigate(AppRoutes.suppliers);
        },
      ).catch((errors) => {
        setFieldsErrors<TCreateSupplierSchema>({ errors, setError, fieldsName });
      });
  });

  const onHandleCloseConfirmation = (isOpen = false) => {
    if (!isConfirmationModalOpen && !isDirty) {
      navigate(-1);
    } else {
      setIsConfirmationModalOpen(isOpen);
    }
  };

  const onDiscardChangesConfirm = () => {
    const currentLocation = blocker?.location?.pathname;
    if (currentLocation) {
      navigate(currentLocation);
    } else {
      navigate(-1);
    }
    reset(undefined, { keepValues: true });
  };

  const naceDivisions = watch(NACE_SECTION)?.divisions || [];

  const onNaceSelectionChange = () => {
    const emptyOption = null as PathValue<TCreateSupplierSchema, Path<TCreateSupplierSchema>>;
    setValue(NACE_CODE, emptyOption);
  };

  const {
    onFindCountries, onFindStates, onFindCities, isStatesDisabled, onChangeCountry, onChangeState,
    isCitiesDisabled,
  } = useGeoLocations<TCreateSupplierSchema>({ watch, setValue });

  const { dateOpened, onDateOpenedChange } = useDataSetsDate<TCreateSupplierSchema>({ watch, setValue });

  return (
    <CreateSupplierView
      control={control}
      onCreateSupplierSubmit={onCreateSupplierSubmit}
      onHandleCloseConfirmation={onHandleCloseConfirmation}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onDiscardChangesConfirm={onDiscardChangesConfirm}
      enumData={enumData}
      naceCodesData={naceCodesData}
      naceDivisions={naceDivisions}
      onNaceSelectionChange={onNaceSelectionChange}
      isPageLoading={isPageLoading}
      onFindCountries={onFindCountries}
      onFindStates={onFindStates}
      onFindCities={onFindCities}
      isStatesDisabled={isStatesDisabled}
      isCitiesDisabled={isCitiesDisabled}
      onChangeCountry={onChangeCountry}
      onChangeState={onChangeState}
      dateOpened={dateOpened}
      onDateOpenedChange={onDateOpenedChange}
    />
  );
}

export default CreateSupplier;
