import { type ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SORT_DIRECTION_INDICATOR } from 'constants/dataSets';
import {
  ORDER_KEY, ORDER_TYPE, SEARCH, SORT, STATUS,
} from 'constants/formFields';
import { DEFAULT_OFFSET } from 'constants/general';
import { ORDERS, type TOrderByIdentifier } from 'constants/interfaces';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import { useAppDispatch, useAppSelector } from 'store/hooks/useApp';
import {
  useGetCompaniesListQuery,
} from 'store/slices/companies/apis/companiesApi';
import { clientsQueryParamsSelector } from 'store/slices/companies/selectors';
import { setCompanyState } from 'store/slices/companies/slice';
import { companiesListSchema } from 'utils/validators';

import ClientsView from './ClientsView';

import type { ISearchInputHandler } from 'components/TextField/interfaces/ITextField';
import type { TCompaniesListSchema } from 'utils/validators';

function Clients(): ReactElement {
  const dispatch = useAppDispatch();

  const clientsQueryParams = useAppSelector(clientsQueryParamsSelector);

  const {
    data: companies, refetch, isFetching,
  } = useGetCompaniesListQuery({
    search: clientsQueryParams.search || undefined,
    status: clientsQueryParams.status?.value || undefined,
    orderBy: clientsQueryParams.orderBy,
    limit: DEFAULT_OFFSET,
    offset: clientsQueryParams.offset,
  });

  const {
    control,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm<TCompaniesListSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(companiesListSchema),
    defaultValues: {
      [SEARCH]: clientsQueryParams.search,
      [STATUS]: clientsQueryParams.status,
      [SORT]: {
        [ORDER_KEY]: '',
        [ORDER_TYPE]: ORDERS.asc,
      },
    },
  });

  const debouncedSearch = useDebouncedCallback(() => {
    const { search } = getValues();
    dispatch(setCompanyState({
      clientsQueryParams: { ...clientsQueryParams, search, offset: 0 },
    }));
  });

  const onSearchChange = ({ search, onChange }: ISearchInputHandler) => {
    onChange(search);
    debouncedSearch();
  };

  const onReadMoreClients = () => {
    dispatch(setCompanyState({
      clientsQueryParams: { ...clientsQueryParams, offset: clientsQueryParams.offset + DEFAULT_OFFSET },
    }));
  };

  const onFilterChange = () => {
    const { status } = getValues();
    dispatch(setCompanyState({
      clientsQueryParams: { ...clientsQueryParams, status, offset: 0 },
    }));
  };

  const debouncedSort = useDebouncedCallback((order: string) => {
    dispatch(setCompanyState({
      clientsQueryParams: {
        ...clientsQueryParams, orderBy: order, offset: 0,
      },
    }));
  });

  const onSortChange = (orderByIdentifier: TOrderByIdentifier) => {
    const { sort: { orderKey, orderType } } = getValues();
    const isAsc = orderKey === orderByIdentifier && orderType === ORDERS.asc;
    const order = isAsc ? `${SORT_DIRECTION_INDICATOR}${orderByIdentifier}` : orderByIdentifier;
    setValue(SORT, { [ORDER_KEY]: orderByIdentifier, [ORDER_TYPE]: isAsc ? ORDERS.desc : ORDERS.asc });
    debouncedSort(order);
  };

  const searchValue = watch(SEARCH);
  const statusValue = watch(STATUS);

  useEffect(() => {
    if ((searchValue && !clientsQueryParams?.search) || (statusValue && !clientsQueryParams?.status)) {
      const querySearchValue = searchValue || clientsQueryParams?.search || undefined;
      const queryStatusValue = statusValue || clientsQueryParams?.status || undefined;
      dispatch(setCompanyState({
        clientsQueryParams: {
          ...clientsQueryParams, search: querySearchValue, status: queryStatusValue, offset: 0,
        },
      }));
    }
  }, [searchValue, statusValue, clientsQueryParams, setValue, dispatch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    reset();
  }, [reset]);

  const isWithSearch = !!clientsQueryParams?.search || !!clientsQueryParams?.status?.value;

  return (
    <ClientsView
      control={control}
      companies={companies?.data.items || []}
      onReadMoreClients={onReadMoreClients}
      hasNext={!!companies?.data.meta.hasNext}
      onSearch={onSearchChange}
      onFilterChange={onFilterChange}
      onSortChange={onSortChange}
      isWithSearch={isWithSearch}
      isPageLoading={isFetching}
    />
  );
}

export default Clients;
