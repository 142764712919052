import { Trans, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { generatePath, useNavigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import EmptyList from 'components/EmptyList/EmptyList';
import Loader from 'components/Loader/Loader';
import Panel from 'components/Panel/Panel';
import Table from 'components/Table/Table';
import TableBody from 'components/Table/TableBody';
import AppRoutes from 'constants/AppRoutes';
import { NEW_CLIENT_ID } from 'constants/general';

import ClientsTableHead from '../ClientsTableHead/ClientsTableHead';
import ClientsTableRow from '../ClientsTableRow/ClientsTableRow';

import type IClientsTable from '../../interfaces/IClientsTable';

import styles from './scss/ClientsTable.module.scss';

function ClientsTable({
  control,
  onSortChange,
  companies,
  onReadMoreClients,
  hasNext,
  isWithSearch,
  isPageLoading,
}: IClientsTable) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onHandleClick = () => {
    navigate(generatePath(AppRoutes.companyInfo, { clientId: NEW_CLIENT_ID }));
  };

  const emptyListText = isWithSearch ? (
    <Trans
      i18nKey="common.noResultsWithFilters"
      t={t}
      components={{
        br: <br />,
      }}
    />
  ) : t('clients.noClientsYet');

  return (
    <Panel className={styles.root}>
      <Loader isPageLoading={isPageLoading} />

      {companies.length ? (
        <InfiniteScroll
          dataLength={companies.length}
          next={onReadMoreClients}
          hasMore={hasNext}
          loader={false}
          className={styles.infiniteScroll}
        >
          <Table>
            <ClientsTableHead
              control={control}
              onSortChange={onSortChange}
            />
            <TableBody>
              {companies.map((company) => (
                <ClientsTableRow
                  key={window.crypto.randomUUID()}
                  company={company}
                />
              ))}
            </TableBody>
          </Table>
        </InfiniteScroll>
      ) : !isPageLoading && (
        <EmptyList text={emptyListText}>
          {!isWithSearch ? (
            <Button
              title={t('clients.createClient')}
              onClick={onHandleClick}
            />
          ) : null}
        </EmptyList>
      )}
    </Panel>
  );
}

export default ClientsTable;
