import { clsx } from 'clsx';
import { ICON_BUTTON_SIZES } from 'constants/interfaces';

import type IIconButton from './interfaces/IIconButton';

import styles from './scss/IconButton.module.scss';

function IconButton({
  icon,
  onClick,
  className,
  rounded,
  disabled,
  size = ICON_BUTTON_SIZES.medium,
  isWarning,
}: IIconButton) {
  const classes = clsx(styles.button, styles[size], className, {
    [styles.rounded]: rounded,
    [styles.warning]: isWarning,
  });

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      {icon}
    </button>
  );
}

export default IconButton;
