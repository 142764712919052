import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { AppRoutes } from 'constants/AppRoutes';
import { LANGUAGES } from 'constants/formFields';
import useCompanyDraft from 'hooks/useCompanyDraft';
import useCreateClient from 'hooks/useCreateClient';
import { useAppSelector } from 'store/hooks/useApp';
import { useGetCompanyDetailsQuery, useUpdateCompanyLanguagesMutation } from 'store/slices/companies/apis/companiesApi';
import { createFlowCompanyIdSelector, selectCompanyLanguages } from 'store/slices/companies/selectors';
import { companyMultilingualSchema } from 'utils/validators';

import MultilingualView from './MultilingualView';

import type IHandleFormSubmit from 'hooks/interfaces/IHandleFormSubmit';
import type { TCompanyMultilingualSchema } from 'utils/validators';

function Multilingual() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const newCompanyId = useAppSelector(createFlowCompanyIdSelector);
  const { isCreateFlow, clientId } = useCreateClient();

  const currentCompanyId = newCompanyId && isCreateFlow ? newCompanyId : +clientId;

  const fetchQuery = newCompanyId || !isCreateFlow ? {
    companyId: currentCompanyId,
  } : skipToken;

  const { languages, isFetching } = useGetCompanyDetailsQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, languages: selectCompanyLanguages(result.data) }),
    },
  );

  const [updateCompanyLanguages] = useUpdateCompanyLanguagesMutation();

  const {
    control,
    formState: { isDirty, isValid, isSubmitted },
    handleSubmit: onSubmit,
    reset,
  } = useForm<TCompanyMultilingualSchema>({
    mode: 'onChange',
    resolver: yupResolver(companyMultilingualSchema),
    defaultValues: {
      [LANGUAGES]: ['en'],
    },
  });

  useEffect(() => {
    if (languages) {
      reset(languages);
    }
  }, [reset, languages]);

  const disableButton = !isValid && isSubmitted;

  const onHandlePrevClick = () => {
    navigate(generatePath(AppRoutes.companyInfo, { clientId }));
  };
  const handleFormSubmit: IHandleFormSubmit<TCompanyMultilingualSchema> = ({ values, isDraftSave }) => {
    const data = {
      companyId: currentCompanyId,
      languages: values.languages,
    };

    updateCompanyLanguages(data).unwrap()
      .then(() => {
        reset(undefined, { keepValues: true });
        if (isDraftSave) {
          toast.success(t('notifications.clientCreatedInDraft'));
        } else if (isCreateFlow) {
          navigate(generatePath(AppRoutes.companyAdmin, { clientId }));
        }
      })
      .catch(() => {});
  };

  const { onDraftSave } = useCompanyDraft<TCompanyMultilingualSchema>({ handleFormSubmit, onSubmit });

  const onHandleNextClick = onSubmit((values) => {
    handleFormSubmit({ values });
  });

  return (
    <MultilingualView
      control={control}
      isCreatePage={isCreateFlow}
      onPrevClick={onHandlePrevClick}
      onSave={onHandleNextClick}
      isDirty={isDirty}
      isDisabledSave={disableButton}
      onDraftSave={onDraftSave}
      isLoading={isFetching}
    />
  );
}

export default Multilingual;
