import { Controller } from 'react-hook-form';
import { ReactComponent as LoaderIcon } from 'assets/icons/loader.svg';
import Checkbox from 'components/Checkbox/Checkbox';
import ClientPanelFooter from 'components/ClientPanel/ClientPanelFooter';
import Panel from 'components/Panel/Panel';
import Separator from 'components/Separator/Separator';
import {
  LANGUAGE,
} from 'constants/formFields';
import { SUPPORT_LANGUAGES } from 'constants/languages';

import type { IProfileLanguageView } from './interfaces/IProfileLanguageView';

import styles from './sass/ProfileLanguage.module.scss';

function ProfileLanguageView({
  control, onSave, onCancel, userLanguages, isLoadingUserData, isLoading,
}: IProfileLanguageView) {
  return (
    <div className={styles.root}>
      <Panel className={styles.panel}>
        <div className={styles.wrapper}>
          {!isLoadingUserData ? (
            <Controller
              control={control}
              render={({ field: { onChange, value: formValue } }) => (
                <>
                  {Object.values(SUPPORT_LANGUAGES).filter(
                    ({ value }) => !userLanguages || userLanguages.includes(value),
                  ).map(({
                    label, value, icon,
                  }) => (
                    <Checkbox
                      key={value}
                      name={value}
                      checked={formValue === value}
                      value={value}
                      onChange={() => onChange(value)}
                      type="radio"
                      label={(
                        <div className={styles.label}>
                          <span>{icon}</span>
                          <span>{label}</span>
                        </div>
                      )}
                    />
                  ))}
                </>
              )}
              name={LANGUAGE}
            />
          ) : <LoaderIcon /> }
        </div>
        <Separator />
        <ClientPanelFooter
          onSave={onSave}
          onCancel={onCancel}
          isLoading={isLoading}
        />
      </Panel>
    </div>
  );
}

export default ProfileLanguageView;
