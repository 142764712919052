import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'components/Modal/Modal';
import Textarea from 'components/Textarea/Textarea';
import { REASON } from 'constants/formFields';
import { DESCRIPTION_LENGTH } from 'constants/general';
import { deactivationSchema } from 'utils/validators';

import type { TDeactivationSchema } from 'utils/validators';
import type IDeactivationModal from './interfaces/IDeactivationModal';

function DeactivationModal({
  open,
  title,
  text,
  onReject,
  onSubmit,
  isLoading,
}: IDeactivationModal) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
  } = useForm<TDeactivationSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(deactivationSchema),
    defaultValues: {
      [REASON]: '',
    },
  });

  const onHandleConfirm = handleSubmit((values) => {
    onSubmit(values.reason);
  });

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Modal
      open={open}
      title={title || t('common.deactivationReason')}
      description={text}
      closeTitle={t('common.cancel')}
      onReject={onReject}
      onConfirm={onHandleConfirm}
      isLoading={isLoading}
    >
      <Controller
        control={control}
        name={REASON}
        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
          <Textarea
            name={name}
            value={value}
            label={t('common.reason')}
            placeholder={t('common.typeYourMessage')}
            onChange={(e) => onChange(e.target.value.slice(0, DESCRIPTION_LENGTH))}
            error={error?.message}
            charactersLimit={DESCRIPTION_LENGTH}
          />
        )}
      />
    </Modal>
  );
}

export default DeactivationModal;
