import Panel from 'components/Panel/Panel';

import Loader from '../Loader/Loader';
import Separator from '../Separator/Separator';
import EmptyList from './components/EmptyList/EmptyList';
import UploadFile from './components/UploadFile/UploadFile';

import type { IDataSetsEmptyListView } from './interfaces/IDataSetsEmptyListView';

import styles from './scss/DataSetsEmptyList.module.scss';

function DataSetsEmptyList({
  title, subTitle, uploadControl, isUploadFileVisible, isProfitCenterEmpty, isSupplierEmpty, onDownloadExample,
  isLoading,
}: IDataSetsEmptyListView) {
  return (
    <div className={styles.root}>
      <Loader isPageLoading={isLoading} />
      <Panel className={styles.panel}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div>{title}</div>
            <div className={styles.description}>{subTitle}</div>
          </div>
          <Separator className={styles.separator} />
          {isUploadFileVisible
            ? <UploadFile uploadControl={uploadControl} onDownloadExample={onDownloadExample} />
            : (
              <EmptyList
                title={title}
                isProfitCenterEmpty={isProfitCenterEmpty}
                isSupplierEmpty={isSupplierEmpty}
              />
            )}
        </div>
      </Panel>
    </div>
  );
}

export default DataSetsEmptyList;
