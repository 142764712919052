import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as CheckMark } from 'assets/icons/check-mark.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trashBox.svg';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import InfoPanel from 'components/InfoPanel/InfoPanel';
import RoleIcon from 'components/RoleIcon/RoleIcon';
import Separator from 'components/Separator/Separator';
import UserStatusBadge from 'components/UserStatusBadge/UserStatusBadge';
import AppRoutes from 'constants/AppRoutes';
import { USER_STATUS } from 'constants/interfaces';
import { USER_STATUSES } from 'constants/user';

import type IUserInfoSection from './interfaces/IUserInfoSection';

import styles from './scss/UserInfoSection.module.scss';

function UserInfoSection({
  user, onToggleDeleteModal, isNewUser, onChangeUserStatus, isActionsVisible, isLoading,
}: IUserInfoSection) {
  const navigate = useNavigate();
  const { userId = '' } = useParams();
  const { t } = useTranslation();

  const {
    firstName, lastName, email, phone, role,
    profile, status, latestDeactivation,
  } = user || {};

  const { emailVerificationStatus, phoneVerificationStatus } = profile || {};

  const isEmailVerified = emailVerificationStatus === 'enabled';

  const isPhoneVerified = phoneVerificationStatus === 'enabled';

  const onHandleEdit = () => {
    navigate(
      generatePath(AppRoutes.editUserInfo, { userId }),
      { state: { redirectPath: generatePath(AppRoutes.userInfo, { userId }) } },
    );
  };

  return (
    <InfoPanel
      title={t('userInfo.userInfoSection.title')}
      icon={<InfoIcon />}
      onEdit={onHandleEdit}
      isActionsVisible={isActionsVisible}
      isLoading={isLoading}
    >
      <div className={styles.item}>
        <h3>{t('userInfo.userInfoSection.fullName')}</h3>
        <p>
          {t('userManagement.fullName', {
            firstName,
            lastName,
          })}
        </p>
      </div>
      <Separator />
      <div className={styles.item}>
        <h3>{t('userInfo.userInfoSection.email')}</h3>
        <p>{email}</p>
        {isEmailVerified ? (
          <div className={styles.verified}>
            <CheckMark />
            <span>{t('common.verified')}</span>
          </div>
        ) : null}
      </div>
      <Separator />
      <div className={styles.item}>
        <h3>{t('userInfo.userInfoSection.phoneNumber')}</h3>
        <p>{phone || '-'}</p>
        {isPhoneVerified ? (
          <div className={styles.verified}>
            <CheckMark />
            <span>{t('common.verified')}</span>
          </div>
        ) : null}
      </div>
      <Separator />
      <div className={styles.item}>
        <h3>{t('userInfo.userInfoSection.role')}</h3>
        <p className={styles.role}>
          <RoleIcon role={role} />
          {t(`userManagement.roles.${role}`)}
        </p>
      </div>
      <Separator />
      {isActionsVisible ? (
        <div className={styles.item}>
          <h3>{t('userInfo.userInfoSection.status')}</h3>
          {status ? (
            <UserStatusBadge
              showInfoButton={status === USER_STATUS.deactivated}
              deactivationInfo={latestDeactivation}
            >
              <Badge
                status={USER_STATUSES[status].label}
                color={USER_STATUSES[status].color}
                textColor={USER_STATUSES[status].textColor}
              />
            </UserStatusBadge>
          ) : null}
          <div className={styles.status}>

            {status ? (
              <Button
                title={USER_STATUSES[status].actionLabel}
                startIcon={USER_STATUSES[status].actionIcon}
                variant="ghost"
                onClick={onChangeUserStatus}
              />
            ) : null}

            {isNewUser ? (
              <Button
                title={t('common.delete')}
                startIcon={<TrashIcon />}
                variant="ghost"
                onClick={onToggleDeleteModal}
                isLoading={isLoading}
              />
            ) : null}

          </div>
        </div>
      ) : null}
    </InfoPanel>
  );
}

export default UserInfoSection;
