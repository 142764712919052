import {
  type ChangeEvent, type ReactElement, useEffect, useState,
} from 'react';
import { batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { AppRoutes } from 'constants/AppRoutes';
import { EMAIL, PASSWORD } from 'constants/formFields';
import { EMPTY_SYMBOL } from 'constants/general';
import { ERROR_CODES } from 'constants/statusCodes';
import mainApi from 'store/apis/mainApi';
import { useAppDispatch, useAppSelector } from 'store/hooks/useApp';
import { useCheckTokenQuery, useLoginMutation } from 'store/slices/auth/apis/auth';
import { authTokensSelector } from 'store/slices/auth/selectors';
import { setAuthState } from 'store/slices/auth/slice';
import { useLoginInfoQuery } from 'store/slices/user/apis/user';
import authNavigation from 'utils/authNavigation';

import useLoginForm from './hooks/useLoginForm';
import LoginView from './LoginView';

import type ILoginResponse from 'store/slices/auth/interfaces/ILoginResponse';
import type IBaseQueryResponse from 'store/types/IBaseQueryResponse';

function Login(): ReactElement {
  const dispatch = useAppDispatch();

  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const {
    control,
    handleSubmit,
    setError,
  } = useLoginForm();

  const { verifyToken } = useAppSelector(authTokensSelector);

  const queryParams = verifyToken ? {} : skipToken;

  const { data: userData, isLoading } = useLoginInfoQuery(queryParams);

  const { accessToken } = useAppSelector(authTokensSelector);

  const heckTokenQueryParams = accessToken ? { token: accessToken } : skipToken;

  const { data: checkToken, isLoading: isCheckTokenLoading } = useCheckTokenQuery(heckTokenQueryParams);

  const { valid } = checkToken?.data || {};

  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const onSubmit = handleSubmit((values) => {
    login(values)
      .unwrap()
      .then(({ data }) => {
        if (data) {
          batch(() => {
            dispatch(mainApi.util.resetApiState());
            dispatch(
              setAuthState({
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
                verifyToken: data.verifyToken,
              }),
            );
          });

          if (!data.verifyToken) {
            navigate(AppRoutes.home);
          }
        }
      })
      .catch(({ data }: IBaseQueryResponse<ILoginResponse>) => {
        const isDeactivated = data?.message?.some((messageArray) => messageArray.some(
          (message) => message.errorCode === ERROR_CODES.deactivated,
        ));

        if (isDeactivated) {
          navigate(AppRoutes.deactivated);
        }

        if (data?.error) {
          setError(PASSWORD, { message: EMPTY_SYMBOL });
          setError(EMAIL, { message: EMPTY_SYMBOL });
        }
      });
  });

  useEffect(() => {
    if (!isLoading && verifyToken && userData) {
      authNavigation({ navigate, user: userData.data });
    }
  }, [userData, isLoading, verifyToken, navigate]);

  useEffect(() => {
    if (valid && !isCheckTokenLoading) {
      navigate(AppRoutes.launchpad);
    }
  }, [valid, isCheckTokenLoading, navigate]);

  const onChangeEmailField = (e: ChangeEvent<HTMLInputElement>) => {
    const isDisabled = !e?.target?.value;

    setIsButtonDisabled(isDisabled);
  };

  const onPasswordVisible = () => {
    setIsPasswordVisible(true);
  };

  const onLoginSubmit = !isPasswordVisible ? onPasswordVisible : onSubmit;

  return (
    <LoginView
      control={control}
      onLoginSubmit={onLoginSubmit}
      isButtonDisabled={isButtonDisabled || isLoading}
      onChangeEmailField={onChangeEmailField}
      isPasswordVisible={isPasswordVisible}
      isLoading={isLoginLoading || isCheckTokenLoading}
    />
  );
}

export default Login;
