import { Trans, useTranslation } from 'react-i18next';
import DataSetsEmptyList from 'components/DataSetsEmptyList/DataSetsEmptyList';
import DataSetsItems from 'components/DataSetsItems/DataSetsItems';
import DataSetsListFilters from 'components/DataSetsListFilters/DataSetsListFilters';
import DeactivationInfoModal from 'components/DeactivationModals/DeactivationInfoModal';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';
import EmptyList from 'components/EmptyList/EmptyList';
import ImportErrorsModal from 'components/ImportErrorsModal/ImportErrorsModal';
import Loader from 'components/Loader/Loader';
import MappingModal from 'components/MappingModal/MappingModal';
import Pagination from 'components/Pagination/Pagination';
import Panel from 'components/Panel/Panel';
import { DATA_SET_PAGINATION_COUNT, DATA_SETS_OWN_OPERATIONS_HEAD_CELLS } from 'constants/dataSets';

import FiltersBar from './components/FiltersBar/FiltersBar';
import OwnOperationsItem from './components/OwnOperationsItem/OwnOperationsItem';

import type { Control } from 'react-hook-form';
import type { TDataSetsListSchema } from 'utils/validators';
import type IOwnOperationsView from './interfaces/IOwnOperationsView';
import type { TUseOwnOperationImportRecordsMutation } from './interfaces/TUseOwnOperationImportRecordsMutation';

import styles from './sass/OwnOperationsView.module.scss';

function OwnOperationsView({
  isEmptyList, uploadControl, isUploadFileVisible, isProfitCenterEmpty, isMappingModalVisible, onCloseMappingModal,
  setIsImportErrorsModalVisible, isImportErrorsModalVisible, onDownloadExample, importRecords,
  meta, items, onSortChange, onSearchChange, onSelectAll, isLineMark, onArchive, setIsArchiveModalVisible,
  onArchiveConfirm, isArchiveModalVisible, isArchiveSelectedItemsVisible, isArchiveModalInfoVisible,
  setIsArchiveModalInfoVisible, latestArchivation, onArchiveModalInfoOpen, onMoveToArchive, onDeArchive, selectedItems,
  onPageChange, currentTotalItems, onEditRecord, isActionsVisible, onAddRecord,
  control, onFilterApply, orgTypePrefix, setIsFiltersBarVisible, isFiltersBarVisible, isApplyFilter, onFilterReset,
  geoTypePrefix, naceCodeTreeData, isWithSearch, isLoading, isLazyOwnOperationExampleLoading, onShowArchiveChange,
}: IOwnOperationsView) {
  const { t } = useTranslation();
  const emptyListText = (
    <Trans
      i18nKey="common.noResultsWithFilters"
      t={t}
      components={{
        br: <br />,
      }}
    />
  );
  return (
    <>
      {isEmptyList && !isWithSearch && (!isLoading || isLazyOwnOperationExampleLoading) ? (
        <DataSetsEmptyList
          title={t('ownOperations.title')}
          subTitle={t('ownOperations.subTitle')}
          uploadControl={uploadControl}
          isUploadFileVisible={isUploadFileVisible}
          isProfitCenterEmpty={isProfitCenterEmpty}
          onDownloadExample={onDownloadExample}
          isLoading={isLazyOwnOperationExampleLoading}
        />
      )
        : (
          <div className={styles.root}>
            <Panel className={styles.panel}>
              <Loader isPageLoading={isLoading} />
              <div className={styles.content}>
                <DataSetsListFilters
                  control={control}
                  meta={meta}
                  onSearchChange={onSearchChange}
                  onArchive={onArchive}
                  isArchiveSelectedItemsVisible={isArchiveSelectedItemsVisible}
                  selectedItems={selectedItems}
                  currentTotalItems={currentTotalItems}
                  isActionsVisible={isActionsVisible}
                  title={t('ownOperations.title')}
                  subTitle={t('ownOperations.subTitle')}
                  onAddRecord={onAddRecord}
                  setIsFiltersBarVisible={setIsFiltersBarVisible}
                  isFilterVisible
                  isApplyFilter={isApplyFilter}
                  onShowArchiveChange={onShowArchiveChange}
                />
                {isEmptyList ? (<EmptyList text={emptyListText} />) : (
                  <>
                    <DataSetsItems<Control<TDataSetsListSchema>>
                      control={control}
                      onSortChange={onSortChange}
                      onSelectAll={onSelectAll}
                      isLineMark={isLineMark}
                      isActionsVisible={isActionsVisible}
                      headCells={DATA_SETS_OWN_OPERATIONS_HEAD_CELLS}
                    >
                      {items?.map((item) => (
                        <OwnOperationsItem
                          key={item.id}
                          item={item}
                          control={control}
                          onArchiveModalInfoOpen={onArchiveModalInfoOpen}
                          onMoveToArchive={onMoveToArchive}
                          onDeArchive={onDeArchive}
                          onEditRecord={onEditRecord}
                          isActionsVisible={isActionsVisible}
                          naceCodeTreeData={naceCodeTreeData}
                        />
                      ))}
                    </DataSetsItems>
                    <Pagination
                      count={meta?.count || 0}
                      offset={meta?.offset || 0}
                      limit={DATA_SET_PAGINATION_COUNT}
                      onPageChange={onPageChange}
                    />
                  </>
                )}
              </div>

            </Panel>
            {isFiltersBarVisible ? (
              <FiltersBar
                control={control}
                onFilterApply={onFilterApply}
                orgTypePrefix={orgTypePrefix}
                setIsFiltersBarVisible={setIsFiltersBarVisible}
                onFilterReset={onFilterReset}
                geoTypePrefix={geoTypePrefix}
              />
            ) : null}
          </div>
        )}
      <MappingModal<TUseOwnOperationImportRecordsMutation>
        isMappingModalVisible={isMappingModalVisible}
        onCloseMappingModal={onCloseMappingModal}
        setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
        importRecords={importRecords}
        isLoading={isLoading}
      />
      <ImportErrorsModal
        isImportErrorsModalVisible={isImportErrorsModalVisible}
        setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
        isLoading={isLoading}
      />
      <DeactivationModal
        open={isArchiveModalVisible}
        title={t('profitCentres.archivingReasonTitle')}
        text={t('profitCentres.archivingReasonDescription')}
        onReject={() => { setIsArchiveModalVisible(false); }}
        onSubmit={onArchiveConfirm}
        isLoading={isLoading}
      />
      <DeactivationInfoModal
        open={isArchiveModalInfoVisible}
        title={t('profitCentres.archiveDetails')}
        deactivationInfo={latestArchivation}
        onClose={() => setIsArchiveModalInfoVisible(false)}
        isLoading={isLoading}
      />
    </>
  );
}

export default OwnOperationsView;
