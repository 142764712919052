import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { clsx } from 'clsx';
import Button from 'components/Button/Button';
import IconButton from 'components/IconButton/IconButton';
import { BUTTON_VARIANT } from 'constants/interfaces';

import Backdrop from './Backdrop';

import type IModal from './interfaces/IModal';

import styles from './scss/Modal.module.scss';

function Modal({
  open,
  className,
  title,
  description,
  children,
  onClose,
  onReject,
  onConfirm,
  confirmIcon,
  confirmTitle,
  confirmVariant,
  closeTitle,
  isButtonsVisible = true,
  isLoading,
}: IModal) {
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }, [open]);

  return open ? createPortal(
    <Backdrop>
      <div className={clsx(styles.root, className)}>
        {onClose ? (
          <IconButton
            className={styles.close}
            size="small"
            icon={<CloseIcon />}
            onClick={onClose}
          />
        ) : null}
        {title ? <h2 className={styles.title}>{title}</h2> : null}
        {description ? <p className={styles.description}>{description}</p> : null}
        {children}
        {onReject && onConfirm && isButtonsVisible ? (
          <div className={styles.actions}>
            <Button
              title={closeTitle || t('common.close')}
              variant={BUTTON_VARIANT.cancel}
              onClick={onReject}
            />
            <Button
              title={confirmTitle || t('common.confirm')}
              startIcon={confirmIcon}
              variant={confirmVariant}
              onClick={onConfirm}
              isLoading={isLoading}
            />
          </div>
        ) : isButtonsVisible && (
          <div className={styles.actions}>
            <Button
              title={closeTitle || t('common.close')}
              onClick={onReject}
            />
          </div>
        )}
      </div>
    </Backdrop>,
    document.body,
  ) : null;
}

export default Modal;
