import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { clsx } from 'clsx';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Panel from 'components/Panel/Panel';
import Select from 'components/Select/Select';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import Title from 'components/Title/Title';
import {
  EMAIL, FIRST_NAME, LAST_NAME, ROLE,
} from 'constants/formFields';
import { BUTTON_VARIANT } from 'constants/interfaces';
import { USER_ROLE } from 'constants/user';

import type { ICreateNewUserView } from './interfaces/ICreateNewUserView';

import styles from './sass/CreateNewUserView.module.scss';

function CreateNewUserView({
  control, onCreateNewUserSubmit, isConfirmationModalOpen, onHandleCloseConfirmation,
  onDiscardChangesConfirm, isLoading,
}: ICreateNewUserView) {
  const { t } = useTranslation();

  const { userId = '' } = useParams();

  const isUserEdit = userId;

  return (
    <div className={styles.root}>
      <Panel className={styles.panel}>
        <Title text={t('createNewUser.subTitle')} className={styles.subTitle} />
        <Separator />
        <div className={styles.wrapper}>
          <Controller
            control={control}
            name={FIRST_NAME}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                label={t('common.firstName')}
                error={error?.message}
                onChange={(event) => onChange(event.target.value)}
                required
              />
            )}
          />
          <Controller
            control={control}
            name={LAST_NAME}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                label={t('common.lastName')}
                error={error?.message}
                onChange={(event) => onChange(event.target.value)}
                required
              />
            )}
          />
          <Controller
            control={control}
            name={EMAIL}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <div>
                <TextField
                  name={name}
                  value={value}
                  label={t('common.email')}
                  error={error?.message}
                  onChange={(event) => onChange(event.target.value)}
                  required
                  disabled={!!isUserEdit}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name={ROLE}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                label={t('common.role')}
                placeholder={t('common.role')}
                options={USER_ROLE}
                value={value}
                onChange={onChange}
                error={error?.message}
                required
              />
            )}
          />
        </div>
        <Separator />
        <Modal
          open={isConfirmationModalOpen}
          title={t('createNewUser.cancelModalTitle')}
          description={t('clients.cancelModalText')}
          confirmTitle={t('common.discardChanges')}
          closeTitle={t('common.keepEditing')}
          confirmVariant="error"
          onClose={() => onHandleCloseConfirmation(false)}
          onReject={() => onHandleCloseConfirmation(false)}
          onConfirm={onDiscardChangesConfirm}
          isLoading={isLoading}
        />
        <div
          className={clsx(styles.buttonsWrapper, {
            [styles.editButtonsWrapper]: isUserEdit,
          })}
        >
          <Button
            className={styles.button}
            variant={BUTTON_VARIANT.cancel}
            title={t('common.cancel')}
            onClick={onHandleCloseConfirmation}
          />
          {isUserEdit ? (
            <Button
              className={styles.button}
              title={t('common.save')}
              onClick={onCreateNewUserSubmit}
              isLoading={isLoading}
            />
          ) : (
            <Button
              className={styles.button}
              title={t('common.next')}
              endIcon={<ArrowRightIcon />}
              onClick={onCreateNewUserSubmit}
              isLoading={isLoading}
            />
          )}
        </div>
      </Panel>
    </div>
  );
}

export default CreateNewUserView;
