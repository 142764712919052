import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/query';
import { AppRoutes } from 'constants/AppRoutes';
import { STATUS } from 'constants/interfaces';
import {
  useDeactivateSecurityAdminUserMutation,
  useGetAdminUserDetailsQuery,
} from 'store/slices/adminUsers/apis/adminUsersApi';
import { useForgotPasswordMutation, useResendPasswordLinkMutation } from 'store/slices/auth/apis/auth';
import { useUserDetailsQuery } from 'store/slices/companyAdminUsers/apis/companyAdminUsersApi';

import UserSecurityView from './UserSecurityView';

import type IUserSecurityDeactivation from 'store/slices/adminUsers/interfaces/IUserSecurityDeactivation';
import type IUserSecurity from './interfaces/IUserSecurity';

function UserSecurity({ isActionsVisible = true, isLoading }: IUserSecurity) {
  const { t } = useTranslation();
  const { userId = '', clientId = '' } = useParams();
  const [isDeactivationModalOpen, setIsDeactivationModalOpen] = useState(false);
  const [isResetMfaSms, setIsResetMfaSms] = useState(false);

  const isUserInfoPage = !!useMatch(AppRoutes.userInfo);

  const fetchAdminQuery = userId && clientId && !isUserInfoPage ? {
    userId: +userId,
    companyId: Number(clientId),
  } : skipToken;

  const fetchAdminCompanyQuery = userId && isUserInfoPage ? {
    userId: +userId,
  } : skipToken;

  const { data: adminDataUser, isFetching } = useGetAdminUserDetailsQuery(fetchAdminQuery);

  const { data: adminCompanyDataUser, isFetching: isUserDetailsFetching } = useUserDetailsQuery(fetchAdminCompanyQuery);

  const user = isUserInfoPage ? adminCompanyDataUser?.data : adminDataUser?.data;

  const [sendResetLink] = useForgotPasswordMutation();
  const [resendPasswordLink] = useResendPasswordLinkMutation();

  const [deactivateSecurity] = useDeactivateSecurityAdminUserMutation();

  const resetPassword = isUserInfoPage ? resendPasswordLink : sendResetLink;

  const onResetPassword = () => {
    resetPassword({ email: user?.email || '' }).unwrap()
      .then(() => toast.success(t('notifications.userResetPassword')))
      .catch(() => {});
  };

  const onResetMfa = (reason: string) => {
    const request: IUserSecurityDeactivation = {
      userId: +userId,
      companyId: Number(clientId),
      reason,
    };

    if (isResetMfaSms) {
      request.mfaSms = STATUS.disabled;
    } else {
      request.mfaApp = STATUS.disabled;
    }

    deactivateSecurity(request).unwrap()
      .then(() => {
        toast.success(t('notifications.userResetMfa'));
        setIsDeactivationModalOpen(false);
      })
      .catch(() => {});
  };

  const onToggleModal = (mfaSms?: boolean) => {
    setIsDeactivationModalOpen((prev) => !prev);
    setIsResetMfaSms(mfaSms || false);
  };

  const isPageLoading = isFetching || isUserDetailsFetching || isLoading;

  return (
    <UserSecurityView
      user={user}
      onResetPassword={onResetPassword}
      onResetMfa={onResetMfa}
      isModalOpen={isDeactivationModalOpen}
      onToggleModal={onToggleModal}
      isUserInfoPage={isUserInfoPage}
      isActionsVisible={isActionsVisible}
      isPageLoading={isPageLoading}
    />
  );
}

export default UserSecurity;
