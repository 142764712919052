import { useEffect, useState } from 'react';
import {
  generatePath, useBlocker, useNavigate,
} from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';
import { BLOCKER_STATES } from 'constants/general';
import { AUTHENTICATOR_SETUP_MODAL_STEPS, STATUS, USER_ROLE_TYPES } from 'constants/interfaces';
import { MFA_SETUP_TYPE } from 'constants/user';
import useAuthenticationSetupModal from 'pages/AuthPages/AuthenticationSetup/hooks/useAuthenticationSetupModal';
import { useAppDispatch, useAppSelector } from 'store/hooks/useApp';
import { useLazyVerifyTokenQuery } from 'store/slices/auth/apis/auth';
import { authTokensSelector } from 'store/slices/auth/selectors';
import { setAuthState } from 'store/slices/auth/slice';
import { useDeactivateSecurityMutation, useLazyUserMeQuery } from 'store/slices/user/apis/user';

import SecurityView from './SecurityView';

import type { ReactElement } from 'react';

function Security(): ReactElement {
  const dispatch = useAppDispatch();
  const [getUserMe, { data, isLoading: isLazyUserLoading }] = useLazyUserMeQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBlockerModalOpen, setIsBlockerModalOpen] = useState(false);
  const [isMfaAppCurrent, setIsMfaAppCurrent] = useState(false);
  const [isMfaPhoneModalVisible, setIsMfaPhoneModalVisible] = useState(false);
  const [isMfaAppModalVisible, setIsMfaAppModalVisible] = useState(false);

  const companySecurity = data?.data?.company?.security;
  const { phone, id } = data?.data || {};

  const [triggerVerifyToken, { data: verifyTokenData, isLoading: isLazyVerifyLoading }] = useLazyVerifyTokenQuery();

  const navigate = useNavigate();
  const [deactivateSecurity, { isLoading: isDeactivateSecurityLoading }] = useDeactivateSecurityMutation();

  const { accessToken } = useAppSelector(authTokensSelector);
  const { verifyToken } = verifyTokenData?.data || {};

  const isLoading = isLazyUserLoading || isLazyVerifyLoading || isDeactivateSecurityLoading;

  const {
    steps,
    isModalVisible,
    setIsSecretCodeVisible,
  } = useAuthenticationSetupModal({ isProfile: true });

  const [isBlocker, setIsBlocker] = useState(false);

  const [isUserBlocked, setIsUserBlocked] = useState(false);

  const profile = data?.data?.profile;

  const role = data?.data?.role || '';

  const isUserAdmin = role === USER_ROLE_TYPES.admin;

  const { mfaAppEnabled, mfaSmsEnabled } = profile || {};

  const isMfaAppEnabled = companySecurity?.mfaApp === STATUS.enabled || isUserAdmin;
  const isMfaSmsEnabled = companySecurity?.mfaSms === STATUS.enabled || isUserAdmin;

  const isMfaAppConnected = mfaAppEnabled === STATUS.enabled;
  const isMfaSmsConnected = mfaSmsEnabled === STATUS.enabled;

  const blocker = useBlocker(({ currentLocation, nextLocation }) => isBlocker
      && currentLocation.pathname !== nextLocation.pathname
      && !isModalOpen);

  useEffect(() => {
    if (verifyToken && !isLazyVerifyLoading) {
      dispatch(setAuthState({
        verifyToken,
      }));
    }
  }, [dispatch, isLazyVerifyLoading, verifyToken]);

  useEffect(() => {
    if (accessToken) {
      getUserMe();
    }
  }, [accessToken, getUserMe]);

  useEffect(() => {
    if (blocker.state === BLOCKER_STATES.blocked && !isBlockerModalOpen) {
      if (isBlocker) {
        setIsBlockerModalOpen(true);
      } else if (!isBlocker) {
        blocker.proceed?.();
      }
    }
  }, [blocker, isBlocker, isBlockerModalOpen]);

  const onDeactivateSecurity = () => {
    const mfaApp = isMfaAppCurrent ? STATUS.disabled : mfaAppEnabled;

    const mfaSms = isMfaAppCurrent ? mfaSmsEnabled : STATUS.disabled;

    const requestData = { mfaApp, mfaSms };
    deactivateSecurity(requestData).unwrap().then(({ data: deactivationData }) => {
      if (deactivationData?.status === STATUS.disabled) {
        setIsBlocker(true);
        setIsUserBlocked(true);
        if (id) {
          triggerVerifyToken().unwrap().then((tokenData) => {
            dispatch(setAuthState({ accessToken: '', refreshToken: '', verifyToken: tokenData?.data?.verifyToken }));
          }).catch(() => {});
        }
      }
      setIsModalOpen(false);
    }).catch(() => {});
  };

  const onConfirmationModalOpen = ({ isMfaApp }: { isMfaApp?: boolean }) => {
    setIsModalOpen(true);
    setIsMfaAppCurrent(!!isMfaApp);
  };

  const onMfaAppCreate = () => {
    setIsBlocker(false);
    setIsMfaAppModalVisible(true);
    navigate(generatePath(
      AppRoutes.profileSecurity,
      { step: AUTHENTICATOR_SETUP_MODAL_STEPS.authenticationSetup, type: MFA_SETUP_TYPE },
    ));
  };

  const onMfaSmsCreate = () => {
    setIsBlocker(false);
    setIsMfaPhoneModalVisible(true);
    navigate(generatePath(
      AppRoutes.profileSecurity,
      { step: AUTHENTICATOR_SETUP_MODAL_STEPS.phone, type: MFA_SETUP_TYPE },
    ));
  };

  const onModalClose = (isResetBlocker = false) => {
    navigate(generatePath(AppRoutes.profileSecurity));
    setIsMfaPhoneModalVisible(false);
    setIsMfaAppModalVisible(false);
    setIsBlockerModalOpen(false);
    if (isUserBlocked && !isResetBlocker) {
      setIsBlocker(true);
    }
  };

  const onResetBlockers = () => {
    setIsBlocker(false);
    setIsUserBlocked(false);
  };

  return (
    <SecurityView
      isMfaAppEnabled={isMfaAppEnabled}
      isMfaSmsEnabled={isMfaSmsEnabled}
      isMfaAppConnected={isMfaAppConnected}
      isMfaSmsConnected={isMfaSmsConnected}
      onDeactivateSecurity={onDeactivateSecurity}
      phone={phone}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onConfirmationModalOpen={onConfirmationModalOpen}
      onMfaAppCreate={onMfaAppCreate}
      steps={steps}
      isModalVisible={isModalVisible}
      setIsSecretCodeVisible={setIsSecretCodeVisible}
      onMfaSmsCreate={onMfaSmsCreate}
      isMfaPhoneModalVisible={isMfaPhoneModalVisible}
      isMfaAppModalVisible={isMfaAppModalVisible}
      onModalClose={onModalClose}
      isMfaAppCurrent={isMfaAppCurrent}
      isBlocker={isBlocker}
      isBlockerModalOpen={isBlockerModalOpen}
      setIsBlockerModalOpen={setIsBlockerModalOpen}
      onResetBlockers={onResetBlockers}
      isLoading={isLoading}
    />
  );
}

export default Security;
