import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';
import { useForgotPasswordMutation } from 'store/slices/auth/apis/auth';

import useRecoverPasswordForm from './hooks/useRecoverPasswordForm';
import RecoverPasswordView from './RecoverPasswordView';

import type { ReactElement } from 'react';

function RecoverPassword(): ReactElement {
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const {
    control,
    handleSubmit,
    isValid,
  } = useRecoverPasswordForm();

  const onRecoverPasswordSubmit = handleSubmit((values) => {
    forgotPassword(values)
      .unwrap()
      .then(() => {
        navigate(AppRoutes.recoverPasswordSuccess);
      }).catch(() => {});
  });

  return (
    <RecoverPasswordView
      control={control}
      onRecoverPasswordSubmit={onRecoverPasswordSubmit}
      isValid={isValid}
      isLoading={isLoading}
    />
  );
}

export default RecoverPassword;
