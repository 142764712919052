import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppRoutes } from 'constants/AppRoutes';
import i18n from 'i18next';
import { toggleLocale } from 'store/slices/translates/slice';

import { useAppDispatch } from '../../../store/hooks/useApp';
import { useUpdateUserLocalizationMutation, useUserMeQuery } from '../../../store/slices/user/apis/user';
import useProfileLanguageForm from './hooks/useProfileLanguageForm';
import ProfileLanguageView from './ProfileLanguageView';

import type { ReactElement } from 'react';

function ProfileLanguage(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, isLoading: isLoadingUserData } = useUserMeQuery();
  const [updateUserLocalization, { isLoading: isUpdateUserLoading }] = useUpdateUserLocalizationMutation();

  const { languages } = data?.data.company?.localization || {};

  const isLoading = isLoadingUserData || isUpdateUserLoading;

  const {
    control,
    handleSubmit,
  } = useProfileLanguageForm();

  const onSubmit = handleSubmit((values) => {
    updateUserLocalization(values).unwrap().then(() => {
      dispatch(toggleLocale(values.language));
      toast.success(i18n.t('toast.successChangeLanguage'));
    });
  });

  const onCancel = () => {
    navigate(AppRoutes.profile);
  };

  return (
    <ProfileLanguageView
      control={control}
      onSave={onSubmit}
      onCancel={onCancel}
      userLanguages={languages}
      isLoadingUserData={isLoadingUserData}
      isLoading={isLoading}
    />
  );
}

export default ProfileLanguage;
