import type IGetFormValue from './interfaces/IGetFormValue';

const getFormValue = ({
  value,
}: IGetFormValue) => {
  if (!value && value !== 0) {
    return '';
  }

  return String(value);
};

export default getFormValue;
