import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'constants/AppRoutes';
import { VERIFICATION_CODE } from 'constants/formFields';
import useVerificationCodeForm from 'hooks/useVerificationCodeForm';
import { useAppDispatch } from 'store/hooks/useApp';
import { useVerifyViaAppMutation } from 'store/slices/auth/apis/auth';
import { setAuthState } from 'store/slices/auth/slice';

import VerificationCodeView from './VerificationCodeView';

import type { ReactElement } from 'react';

function VerificationCode(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [verifyViaApp, { isLoading }] = useVerifyViaAppMutation();
  const {
    control,
    handleSubmit,
    isValid,
    setError,
  } = useVerificationCodeForm();

  const onVerificationCodeSubmit = handleSubmit((values) => {
    verifyViaApp(values)
      .unwrap().then(({ data }) => {
        if (data) {
          dispatch(
            setAuthState({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              verifyToken: data.verifyToken,
            }),
          );

          navigate(AppRoutes.home);
        }
      }).catch(({ data }) => {
        if (data?.error) {
          setError(VERIFICATION_CODE, { message: t('authenticationModal.invalidCode') });
        }
      });
  });

  return (
    <VerificationCodeView
      control={control}
      onVerificationCodeSubmit={onVerificationCodeSubmit}
      isValid={isValid}
      isLoading={isLoading}
    />
  );
}

export default VerificationCode;
