import { t } from 'i18next';

import {
  DEFAULT_CURRENCY, DEFAULT_LOCALE, MAXIMUM_FRACTION_DIGITS, MINIMUM_FRACTION_DIGITS,
} from '../constants/general';
import { NUMBER_FORMATS } from '../constants/interfaces';

import type IFormatNumberProps from './interfaces/IFormatNumberProps';

const formatNumber = ({ // TODO Rewrite using i18n Formatting
  value,
  type = NUMBER_FORMATS.currency,
  minimumFractionDigits = MINIMUM_FRACTION_DIGITS,
  maximumFractionDigits = MAXIMUM_FRACTION_DIGITS,
  isWithSign,
  currency = DEFAULT_CURRENCY,
}: IFormatNumberProps) => {
  const signDisplay = isWithSign ? 'exceptZero' : 'auto';

  if (!value && value !== 0) {
    return t('common.hyphen');
  }

  if (type === NUMBER_FORMATS.number) {
    return value;
  }

  if (type === NUMBER_FORMATS.percent) {
    return new Intl.NumberFormat(DEFAULT_LOCALE, {
      style: type,
      minimumFractionDigits: value === 0 ? 0 : minimumFractionDigits,
      maximumFractionDigits: value === 0 ? 0 : maximumFractionDigits,
      signDisplay,
    }).format(value / 100);
  }

  if (type === NUMBER_FORMATS.decimal) {
    return new Intl.NumberFormat(DEFAULT_LOCALE, {
      style: type,
      minimumFractionDigits,
      maximumFractionDigits,
      signDisplay,
    }).format(value);
  }

  return new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: type,
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
  }).format(value);
};

export default formatNumber;
