import { useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import AppRoutes from 'constants/AppRoutes';
import { SEARCH_PARAMS } from 'constants/dataSets';
import { GEO_TYPE_PREFIX, ORG_TYPE_PREFIX, SHOW_ARCHIVED } from 'constants/formFields';
import { USER_PILLAR_MODULES } from 'constants/interfaces';
import useDataSetListActions from 'hooks/useDataSetListActions';
import useDownloadExample from 'hooks/useDownloadExample';
import usePillarModulePermission from 'hooks/usePillarModulePermission';
import {
  useGetNaceCodeTreeQuery,
  useLazyOwnOperationExampleQuery,
  useLazyOwnOperationListQuery,
  useOwnOperationArchiveMutation,
  useOwnOperationDeArchiveMutation,
  useOwnOperationImportRecordsMutation,
  useOwnOperationListQuery,
  useProfitCenterListQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';

import useDataSetsListForm from '../ProfitCentres/hooks/useDataSetsListForm';
import OwnOperationsView from './OwnOperationsView';

import type IOwnItem from 'store/slices/dataSets/interfaces/IOwnItem';
import type { IBaseQueryPaginationResponse } from 'store/types/IBaseQueryPaginationResponse';
import type { TUseOwnOperationImportRecordsMutation } from './interfaces/TUseOwnOperationImportRecordsMutation';

function OwnOperations() {
  const navigate = useNavigate();
  const {
    isActionsVisible,
    isLoading: isPillarModuleLoading,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.dataSetsManagement },
  );

  const [getOwnOperationSheetExample, {
    isLoading: isLazyOwnOperationExampleLoading,
  }] = useLazyOwnOperationExampleQuery();
  const { data: naceCodeTreeData, isLoading: isNaceCodeLoading } = useGetNaceCodeTreeQuery();

  const {
    control, getValues, setValue, watch, handleSubmit,
  } = useDataSetsListForm();
  const [orderBy, setOrderBy] = useState('');
  const [searchParams] = useSearchParams();
  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [isFiltersBarVisible, setIsFiltersBarVisible] = useState(false);

  const [listDataLazy, setListData] = useState <IBaseQueryPaginationResponse<IOwnItem[]>>({
    data: {
      items: [],
      meta: {
        count: 0,
        limit: 0,
        offset: 0,
        hasNext: false,
      },
    },
  });

  const isArchived = watch(SHOW_ARCHIVED);
  const orgTypePrefix = watch(ORG_TYPE_PREFIX);

  const geoTypePrefix = watch(GEO_TYPE_PREFIX);

  const searchParam = searchParams.get(SEARCH_PARAMS.search) || '';
  const offset = Number(searchParams.get(SEARCH_PARAMS.offset) || 0);

  const isWithSearch = !!searchParam || !!orgTypePrefix || !!geoTypePrefix;

  const { data, isFetching: isOwnOperationListLoading } = useOwnOperationListQuery({
    orderBy,
    keyword: searchParam,
    archived: isArchived,
    offset,
  });

  const [ownOperationList, { isFetching: isLazyOwnOperationListLoading }] = useLazyOwnOperationListQuery();

  const { data: profitCenterData, isLoading: isProfitCenterListLoading } = useProfitCenterListQuery({});

  const [importRecords, { isLoading: isOwnOperationImportLoading }] = useOwnOperationImportRecordsMutation();

  const {
    uploadControl, isMappingModalVisible, onCloseMappingModal, isImportErrorsModalVisible,
    setIsImportErrorsModalVisible,
  } = useDownloadExample<TUseOwnOperationImportRecordsMutation>({ importRecords });

  const listData = isApplyFilter ? listDataLazy : data;

  const isEmptyList = !listData?.data?.meta?.count || listData?.data?.items?.length === 0;

  const { items, meta } = listData?.data || {};

  const [deArchive, { isLoading: isOwnOperationDeArchiveLoading }] = useOwnOperationDeArchiveMutation();

  const [archive, { isLoading: isOwnOperationArchiveLoading }] = useOwnOperationArchiveMutation();

  const isLoading = isOwnOperationListLoading || isOwnOperationImportLoading || isOwnOperationDeArchiveLoading
      || isOwnOperationArchiveLoading || isLazyOwnOperationListLoading || isLazyOwnOperationExampleLoading
      || isNaceCodeLoading || isProfitCenterListLoading || isPillarModuleLoading || isOwnOperationImportLoading;

  const {
    isArchiveModalVisible,
    isArchiveModalInfoVisible,
    latestArchivation,
    isLineMark,
    onSortChange,
    onSearchChange,
    onSelectAll,
    onArchiveConfirm,
    onArchive,
    onArchiveModalInfoOpen,
    onMoveToArchive,
    onDeArchive,
    onPageChange,
    isArchiveSelectedItemsVisible,
    setIsArchiveModalVisible,
    setIsArchiveModalInfoVisible,
    selectedItems,
    onShowArchiveChange,
  } = useDataSetListActions<IOwnItem[]>({
    items,
    archiveAction: archive,
    deArchiveAction: deArchive,
    setOrderBy,
    getValues,
    setValue,
    watch,
  });

  const isProfitCenterEmpty = !profitCenterData?.data?.meta?.count && isActionsVisible;

  const isUploadFileVisible = isActionsVisible && !isProfitCenterEmpty;

  const currentTotalItems = items?.length || 0;

  const onDownloadExample = () => {
    getOwnOperationSheetExample();
  };

  const onEditRecord = (recordId: number) => {
    navigate(generatePath(AppRoutes.ownOperationsEdit, { recordId: String(recordId) }));
  };

  const onAddRecord = () => {
    navigate(AppRoutes.ownOperationsCreate);
  };

  const onFilterApply = handleSubmit(() => {
    ownOperationList({
      orderBy,
      keyword: searchParam,
      archived: isArchived,
      offset,
      orgTypePrefix,
      geoTypePrefix,
    }).then((response) => {
      if (response.data) {
        setListData(response.data);
        setIsApplyFilter(true);
      }
    });
  });

  const onFilterReset = () => {
    setValue(ORG_TYPE_PREFIX, '');
    setValue(GEO_TYPE_PREFIX, '');
    ownOperationList({
      orderBy,
      keyword: searchParam,
      archived: isArchived,
      offset,
      orgTypePrefix: '',
      geoTypePrefix: '',
    }).then((response) => {
      if (response.data) {
        setListData(response.data);
        setIsApplyFilter(false);
      }
    });
  };

  return (
    <OwnOperationsView
      isEmptyList={isEmptyList}
      uploadControl={uploadControl}
      isUploadFileVisible={isUploadFileVisible}
      isProfitCenterEmpty={isProfitCenterEmpty}
      isMappingModalVisible={isMappingModalVisible}
      setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
      onCloseMappingModal={onCloseMappingModal}
      isImportErrorsModalVisible={isImportErrorsModalVisible}
      onDownloadExample={onDownloadExample}
      importRecords={importRecords}
      control={control}
      isArchiveModalVisible={isArchiveModalVisible}
      isArchiveModalInfoVisible={isArchiveModalInfoVisible}
      latestArchivation={latestArchivation}
      isLineMark={isLineMark}
      onSortChange={onSortChange}
      onSearchChange={onSearchChange}
      onSelectAll={onSelectAll}
      onArchiveConfirm={onArchiveConfirm}
      onArchive={onArchive}
      onArchiveModalInfoOpen={onArchiveModalInfoOpen}
      onMoveToArchive={onMoveToArchive}
      onDeArchive={onDeArchive}
      onPageChange={onPageChange}
      isArchiveSelectedItemsVisible={isArchiveSelectedItemsVisible}
      setIsArchiveModalVisible={setIsArchiveModalVisible}
      setIsArchiveModalInfoVisible={setIsArchiveModalInfoVisible}
      isActionsVisible={isActionsVisible}
      items={items}
      selectedItems={selectedItems}
      currentTotalItems={currentTotalItems}
      onEditRecord={onEditRecord}
      onAddRecord={onAddRecord}
      meta={meta}
      onFilterApply={onFilterApply}
      orgTypePrefix={orgTypePrefix}
      setIsFiltersBarVisible={setIsFiltersBarVisible}
      isFiltersBarVisible={isFiltersBarVisible}
      isApplyFilter={isApplyFilter}
      onFilterReset={onFilterReset}
      geoTypePrefix={geoTypePrefix}
      naceCodeTreeData={naceCodeTreeData}
      isWithSearch={isWithSearch}
      isLoading={isLoading}
      isLazyOwnOperationExampleLoading={isLazyOwnOperationExampleLoading}
      onShowArchiveChange={onShowArchiveChange}
    />
  );
}

export default OwnOperations;
