import React from 'react';
import { Controller } from 'react-hook-form';
import { ReactComponent as ListArrowIcon } from 'assets/icons/list-arrow.svg';
import { clsx } from 'clsx';
import Checkbox from 'components/Checkbox/Checkbox';
import { GEO_TYPE_PREFIX, ORG_TYPE_PREFIX } from 'constants/formFields';
import { FILTER_TABS } from 'constants/interfaces';

import type ICustomNode from './interfaces/ICustomNode';

import styles from './scss/CustomNode.module.css';

export function CustomNode({
  node, isOpen, onToggle, control, tab, isEdit,
}: ICustomNode) {
  const {
    id, text, data,
  } = node;

  const handleToggle = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    onToggle(node.id);
  };

  const checkboxName = tab === FILTER_TABS.organizational ? ORG_TYPE_PREFIX : GEO_TYPE_PREFIX;

  return (

    <div
      className={clsx(styles.root, {
        [styles.editRoot]: isEdit,
      })}
    >
      <div
        className={`${styles.expandIconWrapper} ${
          isOpen ? styles.isOpen : ''
        }`}
      />
      <div className={clsx(styles.itemWrapper, {
        [styles.withoutNesting]: !data?.isHaveNesting,
      })}
      >
        {data?.isHaveNesting ? (
          <div
            role="button"
            tabIndex={0}
            className={clsx(styles.expandIconWrapper, {
              [styles.isEdit]: isEdit,
              [styles.isOpen]: isOpen,
            })}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleToggle(e);
              }
            }}
            onClick={handleToggle}
          >
            <ListArrowIcon />
          </div>
        ) : null}

        {isEdit ? (
          <div className={styles.editItem}>
            <span className={styles.area}>...</span>
            <span className={styles.label}>{text}</span>
          </div>
        ) : (
          <Controller
            control={control}
            render={({
              field: {
                onChange,
                value,
                disabled,
              },
            }) => (
              <Checkbox
                key={id}
                name={`${checkboxName}-${id}`}
                checked={value === data?.path}
                value={id}
                disabled={disabled}
                onChange={(event) => {
                  onChange(event.target.checked
                    ? data?.path
                    : '');
                }}
                label={(<span>{text}</span>)}
              />
            )}
            name={checkboxName}
          />
        )}

      </div>
    </div>
  );
}

export default CustomNode;
