import { useTranslation } from 'react-i18next';
import { ReactComponent as TrashIcon } from 'assets/icons/trashBox.svg';
import Modal from 'components/Modal/Modal';
import { BUTTON_VARIANT } from 'constants/interfaces';

import type IDeleteUserModal from './interfaces/IDeleteUserModal';

function DeleteUserModal({
  open, onReject, onClose, onConfirm, isLoading,
}: IDeleteUserModal) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t('common.deleteUser')}
      description={t('common.infoWillLost')}
      closeTitle={t('common.cancel')}
      confirmTitle={t('common.delete')}
      confirmIcon={<TrashIcon />}
      confirmVariant={BUTTON_VARIANT.error}
      onReject={onReject}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
    />
  );
}

export default DeleteUserModal;
