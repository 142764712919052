import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'components/Button/Button';
import Separator from 'components/Separator/Separator';
import { MAPPING_TARGET } from 'constants/formFields';
import { EMPTY_SYMBOL, MAPPING_FIELDS_PREFIX } from 'constants/general';
import { BUTTON_VARIANT } from 'constants/interfaces';
import { useAppDispatch } from 'store/hooks/useApp';
import selectMappingColumns, { selectSourcesOptions, selfDataSets } from 'store/slices/dataSets/selectors';
import { setDataSetsState } from 'store/slices/dataSets/slice';

import i18n from '../../i18n';
import Loader from '../Loader/Loader';
import Backdrop from '../Modal/Backdrop';
import MappingItem from './components/MappingItem';
import useMappingForm from './hooks/useMappingForm';

import type IDataSetsMap from 'store/slices/dataSets/interfaces/IDataSetsMap';
import type IPercentageCSSProperties from 'utils/interfaces/ICustomCSSProperties';
import type {
  TUseProfitCenterImportRecordsMutation,
} from '../../pages/UserPages/ProfitCentres/interfaces/TUseProfitCenterImportRecordsMutation';
import type IMappingModal from './interfaces/IMappingModal';

import styles from './scss/MappingModal.module.scss';

function MappingModal<T extends TUseProfitCenterImportRecordsMutation>({
  isMappingModalVisible,
  onCloseMappingModal,
  setIsImportErrorsModalVisible,
  importRecords,
  isLoading,
}: IMappingModal<T>) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const mappingColumns = useSelector(selectMappingColumns);
  const sourcesOptions = useSelector(selectSourcesOptions);
  const dataSets = useSelector(selfDataSets);

  const { target, sources, info } = mappingColumns;
  const mappingTarget = Object.keys(target);
  const mappingSources = Object.keys(sources);

  const { matched, total } = info;

  const {
    control, handleSubmit, setError,
    setValue, watch,
  } = useMappingForm({ mappingColumns });

  const progressStyle: IPercentageCSSProperties = {
    '--percentage': `${matched && total ? (matched / total) * 100 : 100}%`,
  };

  useEffect(() => {
    if (isMappingModalVisible) {
      document.body.style.overflow = 'hidden';
    } else if (document.body.style.overflow === 'hidden') {
      document.body.style.overflow = '';
    }
  }, [isMappingModalVisible]);

  const onMappingModalSubmit = handleSubmit((values) => {
    const isHaveEmptyValue = Object.keys(values.sources)?.some((sourceKey) => !values?.sources?.[sourceKey]?.value);

    if (isHaveEmptyValue) {
      Object.keys(values.sources)?.forEach((sourceKey) => {
        const currentSource = values?.sources?.[sourceKey];
        if (!currentSource?.isMatched && !currentSource?.value) {
          setError(`${MAPPING_FIELDS_PREFIX.source}${currentSource?.name}`, { message: EMPTY_SYMBOL });
          setValue(`${MAPPING_FIELDS_PREFIX.source}${currentSource?.name}`, { ...currentSource, ...{ value: '' } });
        }
      });
    } else {
      const mapData = Object.keys(values.sources)?.map((sourceKey, index) => {
        const currentSource = values?.sources?.[sourceKey];
        return { source: currentSource?.label, target: target?.[`${MAPPING_TARGET}_${index}`] };
      }) as IDataSetsMap[];

      const url = dataSets?.mappingColumns?.url || '';
      const requestData = { dryRun: true, url, map: mapData };

      importRecords(requestData).unwrap().then((response) => {
        dispatch(setDataSetsState({ mappingColumns: response.data }));
        if (response?.data?.isMapValid) {
          importRecords({ ...requestData, ...{ dryRun: false } }).unwrap().then((importRecordsResponse) => {
            if (importRecordsResponse?.data?.errors) {
              dispatch(setDataSetsState({ mappingColumns: importRecordsResponse.data }));
              setIsImportErrorsModalVisible(true);
            }
            onCloseMappingModal();
          }).catch(() => { toast.error(i18n.t('notifications.somethingWentWrong')); });
        }
      });
    }
  });

  return isMappingModalVisible ? createPortal(
    <Backdrop>
      <Loader isPageLoading={isLoading} />
      <div className={styles.root}>
        <h2 className={styles.title}>{t('dataSetsManagement.mappingModal.title')}</h2>
        <Separator />
        <div className={styles.content}>
          <div className={styles.header}>
            <div>{t('dataSetsManagement.mappingModal.targetColumn')}</div>
            <div>{t('dataSetsManagement.mappingModal.sourceColumn')}</div>
          </div>
          <div className={styles.wrapper} ref={wrapperRef}>
            {mappingTarget?.map((name, index) => (
              <MappingItem
                wrapperRef={wrapperRef}
                control={control}
                nameTarget={name}
                nameSource={mappingSources?.[index]}
                sourcesOptions={sourcesOptions}
                watch={watch}
                key={name}
              />
            ))}
          </div>
          <Separator />
        </div>
        <div className={styles.footer}>
          <div className={styles.progressWrapper}>
            <div
              className={styles.progressBar}
              style={progressStyle}
            />
            <div>
              {t('dataSetsManagement.mappingModal.fieldsAreMapped', {
                matched,
                total,
              })}
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              title={t('common.close')}
              variant={BUTTON_VARIANT.cancel}
              onClick={onCloseMappingModal}
            />
            <Button
              title={t('common.confirm')}
              variant={BUTTON_VARIANT.primary}
              onClick={onMappingModalSubmit}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Backdrop>,
    document.body,
  ) : null;
}

export default MappingModal;
