import { useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import AppRoutes from 'constants/AppRoutes';
import { SEARCH_PARAMS } from 'constants/dataSets';
import { SHOW_ARCHIVED } from 'constants/formFields';
import { USER_PILLAR_MODULES } from 'constants/interfaces';
import useDataSetListActions from 'hooks/useDataSetListActions';
import useDownloadExample from 'hooks/useDownloadExample';
import usePillarModulePermission from 'hooks/usePillarModulePermission';
import {
  useCustomerArchiveMutation,
  useCustomerDeArchiveMutation,
  useCustomerImportRecordsMutation, useCustomerListQuery,
  useGetNaceCodeTreeQuery, useLazyCustomerExampleQuery,
  useProfitCenterListQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';

import useDataSetsListForm from '../ProfitCentres/hooks/useDataSetsListForm';
import CustomersView from './CustomersView';

import type ICustomerItem from 'store/slices/dataSets/interfaces/ICustomerItem';
import type { TUseCustomerImportRecordsMutation } from './interfaces/TUseCustomerImportRecordsMutation';

function Customers() {
  const navigate = useNavigate();
  const {
    isActionsVisible,
    isLoading: isPillarModuleLoading,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.dataSetsManagement },
  );

  const [getCustomersheetExample, { isLoading: isLazyCustomerExampleLoading }] = useLazyCustomerExampleQuery();
  const {
    control, getValues, setValue, watch,
  } = useDataSetsListForm();
  const [orderBy, setOrderBy] = useState('');
  const [searchParams] = useSearchParams();

  const isArchived = watch(SHOW_ARCHIVED);
  const searchParam = searchParams.get(SEARCH_PARAMS.search) || '';
  const offset = Number(searchParams.get(SEARCH_PARAMS.offset) || 0);

  const { data, isFetching: isCustomerListLoading } = useCustomerListQuery({
    orderBy, keyword: searchParam, archived: isArchived, offset,
  });

  const [importRecords, { isLoading: isCustomerImportRecords }] = useCustomerImportRecordsMutation();

  const {
    uploadControl, isMappingModalVisible, onCloseMappingModal, isImportErrorsModalVisible,
    setIsImportErrorsModalVisible,
  } = useDownloadExample<TUseCustomerImportRecordsMutation>({ importRecords });

  const { items } = data?.data || {};

  const [deArchive, { isLoading: isCustomerDeArchiveRecords }] = useCustomerDeArchiveMutation();

  const [archive, { isLoading: isCustomerArchiveRecords }] = useCustomerArchiveMutation();

  const { data: naceCodeTreeData = { data: {} }, isLoading: isGetNaceCodeTreeRecords } = useGetNaceCodeTreeQuery();

  const {
    isArchiveModalVisible,
    isArchiveModalInfoVisible,
    latestArchivation,
    isLineMark,
    onSortChange,
    onSearchChange,
    onSelectAll,
    onArchiveConfirm,
    onArchive,
    onArchiveModalInfoOpen,
    onMoveToArchive,
    onDeArchive,
    onPageChange,
    isArchiveSelectedItemsVisible,
    setIsArchiveModalVisible,
    setIsArchiveModalInfoVisible,
    selectedItems,
    onShowArchiveChange,
  } = useDataSetListActions<ICustomerItem[]>({
    items,
    archiveAction: archive,
    deArchiveAction: deArchive,
    setOrderBy,
    getValues,
    setValue,
    watch,
  });

  const { data: profitCenterData, isLoading: isProfitCenterListRecords } = useProfitCenterListQuery({});

  const isWithSearch = !!searchParam;

  const isProfitCenterEmpty = !profitCenterData?.data?.meta?.count && isActionsVisible;

  const isUploadFileVisible = isActionsVisible && !isProfitCenterEmpty;

  const isEmptyList = !data?.data?.meta?.count || data?.data?.items?.length === 0;

  const currentTotalItems = items?.length || 0;

  const onDownloadExample = () => {
    getCustomersheetExample();
  };

  const onEditRecord = (recordId: number) => {
    navigate(generatePath(AppRoutes.customerEdit, { recordId: String(recordId) }));
  };

  const onAddRecord = () => {
    navigate(AppRoutes.customerCreate);
  };

  const meta = data?.data?.meta;

  const isLoading = isCustomerListLoading || isCustomerImportRecords || isCustomerDeArchiveRecords
      || isCustomerArchiveRecords || isLazyCustomerExampleLoading || isGetNaceCodeTreeRecords
      || isProfitCenterListRecords || isPillarModuleLoading;

  return (
    <CustomersView
      isEmptyList={isEmptyList}
      uploadControl={uploadControl}
      isUploadFileVisible={isUploadFileVisible}
      isMappingModalVisible={isMappingModalVisible}
      setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
      onCloseMappingModal={onCloseMappingModal}
      isImportErrorsModalVisible={isImportErrorsModalVisible}
      onDownloadExample={onDownloadExample}
      importRecords={importRecords}
      control={control}
      isArchiveModalVisible={isArchiveModalVisible}
      isArchiveModalInfoVisible={isArchiveModalInfoVisible}
      latestArchivation={latestArchivation}
      isLineMark={isLineMark}
      onSortChange={onSortChange}
      onSearchChange={onSearchChange}
      onSelectAll={onSelectAll}
      onArchiveConfirm={onArchiveConfirm}
      onArchive={onArchive}
      onArchiveModalInfoOpen={onArchiveModalInfoOpen}
      onMoveToArchive={onMoveToArchive}
      onDeArchive={onDeArchive}
      onPageChange={onPageChange}
      isArchiveSelectedItemsVisible={isArchiveSelectedItemsVisible}
      setIsArchiveModalVisible={setIsArchiveModalVisible}
      setIsArchiveModalInfoVisible={setIsArchiveModalInfoVisible}
      isActionsVisible={isActionsVisible}
      items={items}
      selectedItems={selectedItems}
      currentTotalItems={currentTotalItems}
      onEditRecord={onEditRecord}
      onAddRecord={onAddRecord}
      isProfitCenterEmpty={isProfitCenterEmpty}
      meta={meta}
      naceCodeTreeData={naceCodeTreeData}
      isWithSearch={isWithSearch}
      isLoading={isLoading}
      isLazyCustomerExampleLoading={isLazyCustomerExampleLoading}
      onShowArchiveChange={onShowArchiveChange}
    />
  );
}

export default Customers;
