import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { SEARCH_PARAMS } from 'constants/dataSets';
import {
  FILTER_BY_STATUS,
  GEO_TYPE_PREFIX,
  ORDER_KEY,
  ORDER_TYPE,
  ORG_TYPE_PREFIX,
  SEARCH,
  SELECT_ALL,
  SELECTED_ITEMS,
  SHOW_ARCHIVED,
  SORT,
} from 'constants/formFields';
import { ORDERS } from 'constants/interfaces';
import { FILTER_USER_STATUSES } from 'constants/user';
import { dataSetsListSchema } from 'utils/validators';

import type { TDataSetsListSchema } from 'utils/validators';

const useDataSetsListForm = () => {
  const [searchParams] = useSearchParams();
  const searchParam = searchParams.get(SEARCH_PARAMS.search) || '';
  const isArchived = Boolean(searchParams.get(SEARCH_PARAMS.isArchived) === 'true');

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm<TDataSetsListSchema>({
    mode: 'onChange',
    resolver: yupResolver(dataSetsListSchema),
    defaultValues: {
      [SEARCH]: searchParam,
      [FILTER_BY_STATUS]: { value: FILTER_USER_STATUSES[0].value, label: FILTER_USER_STATUSES[0].label },
      [SHOW_ARCHIVED]: isArchived,
      [SELECT_ALL]: '',
      [SELECTED_ITEMS]: [],
      [SORT]: {
        [ORDER_KEY]: '',
        [ORDER_TYPE]: ORDERS.asc,
      },
      [ORG_TYPE_PREFIX]: '',
      [GEO_TYPE_PREFIX]: '',
    },
  });

  return {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
  };
};

export default useDataSetsListForm;
