import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { ReactComponent as NotificationsIcon } from 'assets/icons/bell.svg';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/user.svg';
import Banner from 'components/Banner/Banner';
import LinkButton from 'components/LinkButton/LinkButton';
import Separator from 'components/Separator/Separator';
import { AppRoutes } from 'constants/AppRoutes';
import { SEPARATOR_VARIANT } from 'constants/interfaces';
import useTheme from 'hooks/useTheme';
import { useAppDispatch, useAppSelector } from 'store/hooks/useApp';
import { authTokensSelector } from 'store/slices/auth/selectors';
import { setAuthState } from 'store/slices/auth/slice';
import { useUserMeQuery } from 'store/slices/user/apis/user';

import type { ReactElement } from 'react';

import styles from './sass/Header.module.scss';

function Header(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector(authTokensSelector);

  const queryParams = accessToken ? undefined : skipToken;
  const { data: user, refetch } = useUserMeQuery(queryParams);

  const { companyLogo, partnerLogo } = user?.data?.company?.uiConfiguration || {};
  const isInitiator = !!user?.data?.initiator;

  const { resetToDefaultTheme } = useTheme();

  const handleBackToAdmin = () => {
    resetToDefaultTheme();
    dispatch(setAuthState({ userAccessToken: '', userRefreshToken: '' }));
    refetch();
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logoContainer}>
          {partnerLogo ? (
            <Link to="/">
              <div
                className={styles.partnerLogo}
                style={{ background: `url(${partnerLogo}) no-repeat left / contain` }}
              />
            </Link>
          ) : (
            <Link to="/">
              <LogoIcon />
            </Link>
          )}
          <Separator className={styles.separator} position={SEPARATOR_VARIANT.vertical} />
          {companyLogo ? (
            <Link to="/">
              <div
                className={styles.companyLogo}
                style={{ background: `url(${companyLogo}) no-repeat left / contain` }}
              />
            </Link>
          ) : null}
        </div>
        {!isInitiator ? (
          <div className={styles.navigation}>
            <LinkButton to="/" title={t('common.notifications')} startIcon={<NotificationsIcon />} />
            <LinkButton to={AppRoutes.profile} title={t('profile.title')} startIcon={<ProfileIcon />} />
          </div>
        ) : null}
      </header>
      {isInitiator ? (
        <Banner
          title={t(
            'companyUsers.impersonationModeWarning',
            { userName: `${user.data.firstName} ${user.data.lastName}` },
          )}
          onButtonClick={handleBackToAdmin}
          buttonText={t('companyUsers.backToSuperAdmin')}
        />
      ) : null}
    </>
  );
}

export default Header;
