import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { AppRoutes } from 'constants/AppRoutes';
import { ACCESS, PILLAR_MODULES, PILLARS } from 'constants/formFields';
import { PILLAR_ACCESS_TYPES } from 'constants/interfaces';
import useCompanyDraft from 'hooks/useCompanyDraft';
import useCreateClient from 'hooks/useCreateClient';
import { useAppSelector } from 'store/hooks/useApp';
import { useGetCompanyPermissionsQuery, useUpdateCompanyPermissionsMutation } from 'store/slices/companies/apis/companiesApi';
import { createFlowCompanyIdSelector, selectCompanyPermissions } from 'store/slices/companies/selectors';
import { companyPermissionsSchema } from 'utils/validators';

import ModuleManagementView from './ModuleManagementView';

import type IHandleFormSubmit from 'hooks/interfaces/IHandleFormSubmit';
import type { IModule, IPillar } from 'store/slices/companies/interfaces/IPermissions';
import type { TCompanyPermissionsSchema } from 'utils/validators';

function ModuleManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const newCompanyId = useAppSelector(createFlowCompanyIdSelector);
  const { isCreateFlow, clientId } = useCreateClient();

  const currentCompanyId = newCompanyId && isCreateFlow ? newCompanyId : +clientId;

  const fetchQuery = newCompanyId || !isCreateFlow ? {
    companyId: currentCompanyId,
  } : skipToken;

  const { pillars, isFetching } = useGetCompanyPermissionsQuery(
    fetchQuery,
    {
      selectFromResult: (result) => ({ ...result, pillars: selectCompanyPermissions(result.data) }),
    },
  );

  const [updateCompanyPermissions] = useUpdateCompanyPermissionsMutation();

  const {
    control,
    setValue,
    formState: { isDirty, isValid, isSubmitted },
    handleSubmit: onSubmit,
    reset,
  } = useForm<TCompanyPermissionsSchema>({
    mode: 'onChange',
    resolver: yupResolver(companyPermissionsSchema),
    defaultValues: {
      [PILLARS]: [],
    },
  });

  useEffect(() => {
    if (pillars) {
      reset({
        [PILLARS]: pillars,
      });
    }
  }, [reset, pillars]);

  const handleFormSubmit: IHandleFormSubmit<TCompanyPermissionsSchema> = ({ values, isDraftSave }) => {
    const selectedPillars: IPillar[] = [];
    const selectedPillarModules: IModule[] = [];

    values.pillars.forEach((item) => {
      const { pillarModules, ...pillar } = item;

      const formattedModules = pillarModules.map((module) => ({
        id: module.id,
        access: module.access,
      }));

      selectedPillars.push({
        id: pillar.id,
        canView: pillar.canView,
      });
      selectedPillarModules.push(...formattedModules);
    });

    const data = {
      companyId: currentCompanyId,
      pillars: selectedPillars,
      pillarModules: selectedPillarModules,
    };

    updateCompanyPermissions(data).unwrap()
      .then(() => {
        reset(undefined, { keepValues: true });
        if (isDraftSave) {
          toast.success(t('notifications.clientCreatedInDraft'));
        } else if (isCreateFlow) {
          navigate(generatePath(AppRoutes.security, { clientId }));
        }
      })
      .catch(() => {});
  };

  const { onDraftSave } = useCompanyDraft<TCompanyPermissionsSchema>({ handleFormSubmit, onSubmit });

  const onHandleNextClick = onSubmit((values) => {
    handleFormSubmit({ values });
  });

  const onHandlePrevClick = () => {
    navigate(generatePath(AppRoutes.uiConfiguration, { clientId }));
  };

  const onResetPillarModules = (i: number, j: number) => setValue(
    `${PILLARS}.${i}.${PILLAR_MODULES}.${j}.${ACCESS}`,
    PILLAR_ACCESS_TYPES.invisible_disabled,
  );

  const disableButton = !isValid && isSubmitted;

  return (
    <ModuleManagementView
      control={control}
      pillars={pillars}
      isCreatePage={isCreateFlow}
      onPrevClick={onHandlePrevClick}
      onSave={onHandleNextClick}
      isDirty={isDirty}
      isDisabledSave={disableButton}
      onResetPillarModules={onResetPillarModules}
      onDraftSave={onDraftSave}
      isLoading={isFetching}
    />
  );
}

export default ModuleManagement;
