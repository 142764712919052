export default {
  title: 'List of Users',
  inputPlaceholder: 'User name, email',
  mfaResetDetails: 'MFA Reset details',
  impersonationModeTitle: 'Impersonation mode',
  impersonationModeDescription:
      'You are about to log in as {{userName}}.'
      + ' In this mode, you will have the same permissions and access as the selected user. '
      + 'Any actions taken while impersonating this user will be logged for auditing purposes.',
  impersonationModeNotice: 'You will temporarily lose your super admin privileges while impersonating another user.',
  impersonationModeReturnInstruction: 'To return to your own account, click "Back to Super Admin Panel" at any time.',
  impersonationModeConfirmationQuestion: 'Are you sure you want to proceed?',
  impersonationModeWarning: 'You are logged in as a {{userName}}. '
      + 'To return to the Super Admin Role click on the link.',
  impersonationModeWarningButton: 'Back to Super Admin Panel',
  backToSuperAdmin: 'Back to Super Admin Panel',
};
