import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  generatePath, useMatch, useNavigate, useParams,
} from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { clsx } from 'clsx';
import Button from 'components/Button/Button';
import Backdrop from 'components/Modal/Backdrop';
import Separator from 'components/Separator/Separator';
import { AppRoutes } from 'constants/AppRoutes';
import {
  BUTTON_VARIANT,
  MFA_SMS_SETUP_MODAL_STEPS,
} from 'constants/interfaces';
import { MFA_SETUP_TYPE } from 'constants/user';
import useVerificationSms from 'pages/AuthPages/VerificationSms/hooks/useVerificationSms';
import VerificationSmsView from 'pages/AuthPages/VerificationSms/VerificationSmsView';
import { useAppDispatch } from 'store/hooks/useApp';
import { useStartSyncWithPhoneMutation, useVerifyTokenQuery } from 'store/slices/auth/apis/auth';
import { setAuthState } from 'store/slices/auth/slice';
import { useUserMeQuery } from 'store/slices/user/apis/user';

import useSetupPhoneModalForm from '../hooks/useSetupPhoneModalForm';
import SetupPhoneModal from './SetupPhoneModal';

import type { ParsedCountry } from 'react-international-phone/dist/types';
import type { IMFAPhoneSetupModal } from './interfaces/IMFAPhoneSetupModal';

import baseModalStyles from 'components/Modal/scss/Modal.module.scss';
import styles from 'pages/AuthPages/AuthenticationSetup/components/sass/AuthenticationSetupModal.module.scss';

function MFAPhoneSetupModal({ onModalClose, onResetBlockers }: IMFAPhoneSetupModal) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data, isLoading: isUserMeLoading } = useUserMeQuery();
  const { id } = data?.data || {};
  const queryParams = id ? undefined : skipToken;
  const [phone, setPhone] = useState('');

  const { data: verifyTokenData, isLoading: isVerifyTokenLoading } = useVerifyTokenQuery(queryParams);
  const [startSyncWithApp, { isLoading: isStartSyncWithLoading }] = useStartSyncWithPhoneMutation();

  const { step, type = MFA_SETUP_TYPE } = useParams();

  const {
    control,
    onVerificationSmsSubmit,
    onResendCode,
    time,
    isTimeUp,
    isButtonDisabled,
    isLoading: isVerificationSmsLoading,
  } = useVerificationSms({ onModalClose, onResetBlockers });

  const isLoading = isUserMeLoading || isVerifyTokenLoading || isStartSyncWithLoading || isVerificationSmsLoading;

  const [parsedCountry, setParsedCountry] = useState<ParsedCountry | undefined>(undefined);

  const setupPhoneModalForm = useSetupPhoneModalForm({ parsedCountry });

  const { verifyToken } = verifyTokenData?.data || {};

  const isProfileSecurityPage = !!useMatch(AppRoutes.profileSecurity);

  const isModalVisible = !!step && isProfileSecurityPage;

  const isSetupPhoneStep = step === MFA_SMS_SETUP_MODAL_STEPS.phone;

  useEffect(() => {
    if (verifyToken && !isVerifyTokenLoading) {
      dispatch(setAuthState({
        verifyToken,
      }));
    }
  }, [dispatch, isVerifyTokenLoading, verifyToken]);

  const onContinueStep = setupPhoneModalForm.handleSubmit((values) => {
    const requestData = {
      data: values,
      verifyToken,
    };
    setPhone(values.phone);
    startSyncWithApp(requestData).unwrap().then(() => {
      navigate(generatePath(
        AppRoutes.profileSecurity,
        { step: MFA_SMS_SETUP_MODAL_STEPS.smsCode, type },
      ), { replace: true });
    }).catch(() => {});
  });

  const onPrev = () => {
    navigate(generatePath(
      AppRoutes.profileSecurity,
      { step: MFA_SMS_SETUP_MODAL_STEPS.phone, type },
    ));
  };

  return isModalVisible ? createPortal(
    <Backdrop>
      <div className={clsx(baseModalStyles.root, styles.root)}>
        <h2 className={baseModalStyles.title}>{t('mfaPhoneSetup.title')}</h2>
        <p className={clsx(baseModalStyles.description, styles.description)}>
          {isSetupPhoneStep ? (
            <SetupPhoneModal
              control={setupPhoneModalForm.control}
              setParsedCountry={setParsedCountry}
            />
          ) : (
            <VerificationSmsView
              control={control}
              onVerificationSmsSubmit={onVerificationSmsSubmit}
              onResendCode={onResendCode}
              time={time}
              isTimeUp={isTimeUp}
              phone={phone}
              isButtonDisabled={isButtonDisabled}
              isInsideModal
              isLoading={isLoading}
            />
          )}
        </p>

        <div className={styles.footer}>
          <Separator />
          <div className={styles.actions}>
            <Button
              title={t('common.close')}
              variant={BUTTON_VARIANT.cancel}
              onClick={() => onModalClose()}
              className={styles.closeButton}
            />
            {!isSetupPhoneStep && (
            <Button
              title={t('common.prev')}
              variant={BUTTON_VARIANT.secondary}
              startIcon={<ArrowLeftIcon />}
              onClick={onPrev}
            />
            )}
            {isSetupPhoneStep ? (
              <Button
                title={t('common.continue')}
                onClick={onContinueStep}
                isLoading={isLoading}
              />
            ) : (
              <Button
                title={t('common.confirm')}
                onClick={onVerificationSmsSubmit}
                disabled={isButtonDisabled}
                isLoading={isLoading}
              />
            )}

          </div>
        </div>

      </div>
    </Backdrop>,
    document.body,
  ) : null;
}

export default MFAPhoneSetupModal;
