import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BUTTON_VARIANT } from 'constants/interfaces';

import Button from '../Button/Button';

import type IDataSetsRecordButtons from './interfaces/IDataSetsRecordButtons';

import styles from './scss/DataSetsRecordButtons.module.scss';

function DataSetsRecordButtons({ onClose, onSubmit, isLoading }: IDataSetsRecordButtons) {
  const { t } = useTranslation();
  const { recordId = '' } = useParams();
  return (
    <div className={styles.root}>
      <Button
        variant={BUTTON_VARIANT.cancel}
        title={t('common.cancel')}
        onClick={onClose}
      />
      {recordId ? (
        <Button
          title={t('common.save')}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      ) : (
        <Button
          title={t('common.create')}
          onClick={onSubmit}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default DataSetsRecordButtons;
