import useVerificationSms from './hooks/useVerificationSms';
import VerificationSmsView from './VerificationSmsView';

import type { ReactElement } from 'react';

function VerificationSms(): ReactElement {
  const {
    control,
    onVerificationSmsSubmit,
    onResendCode,
    time,
    isTimeUp,
    phone,
    isButtonDisabled,
    isLoading,
  } = useVerificationSms({});
  return (
    <VerificationSmsView
      control={control}
      onVerificationSmsSubmit={onVerificationSmsSubmit}
      onResendCode={onResendCode}
      time={time}
      isTimeUp={isTimeUp}
      phone={phone}
      isButtonDisabled={isButtonDisabled}
      isLoading={isLoading}
    />
  );
}

export default VerificationSms;
