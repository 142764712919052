import { useEffect, useState } from 'react';
import {
  useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import AppRoutes from 'constants/AppRoutes';
import { DATA_SETS_ENUM_TYPES, UID_NAME_TUPLES } from 'constants/dataSets';
import {
  NACE_CODE,
  NACE_SECTION,
} from 'constants/formFields';
import { BLOCKER_STATES } from 'constants/general';
import useDataSetsDate from 'hooks/useDataSetsDate';
import useFieldsErrors from 'hooks/useFieldsErrors';
import useGeoLocations from 'hooks/useGeoLocations';
import i18n from 'i18n';
import {
  useEnumQuery,
  useGetNaceCodeTreeQuery,
  useOwnOperationsCreateMutation,
  useOwnOperationsRecordUpdateMutation,
  useUidNameTuplesQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectEnums, selectNaceCodes, selectUidNameTuples } from 'store/slices/dataSets/selectors';
import getRequestDataForOwnOperations from 'utils/formHelpers/getRequestDataForOwnOperations';

import useCreateOwnOperationsForm from './hooks/useCreateOwnOperationsForm';
import CreateOwnOperationsView from './CreateOwnOperationsView';

import type { ReactElement } from 'react';
import type { Path, PathValue } from 'react-hook-form';
import type IOperationsRecordRequest from 'store/slices/dataSets/interfaces/IOperationsRecordRequest';
import type { TCreateOperationsSchema } from 'utils/validators';

function CreateOwnOperations(): ReactElement {
  const navigate = useNavigate();
  const { recordId = '' } = useParams();

  const { data: naceCodesData, isLoading: isNaceCodesTreeLoading } = useGetNaceCodeTreeQuery(undefined, {
    selectFromResult: (result) => ({ ...result, data: selectNaceCodes(result.data) }),
  });

  const { data: uidNameTuples, isLoading: isNaceUidNameTuplesLoading } = useUidNameTuplesQuery({
    types: [UID_NAME_TUPLES.profitCenter],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectUidNameTuples(result.data) }),
  });

  const [ownOperationsCreate, { isLoading: isOwnOperationsCreateLoading }] = useOwnOperationsCreateMutation();

  const [ownOperationsRecordUpdate, {
    isLoading: isOwnOperationsRecordUpdateLoading,
  }] = useOwnOperationsRecordUpdateMutation();
  const { setFieldsErrors } = useFieldsErrors();

  const { data: enumData, isLoading: isEnumLoading } = useEnumQuery({
    types: [DATA_SETS_ENUM_TYPES.currency, DATA_SETS_ENUM_TYPES.ownership, DATA_SETS_ENUM_TYPES.valueChainSection,
      DATA_SETS_ENUM_TYPES.businessUnitType, DATA_SETS_ENUM_TYPES.assetCategory, DATA_SETS_ENUM_TYPES.ownedLeased],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectEnums(result.data) }),
  });
  const isPageLoading = isEnumLoading || isNaceCodesTreeLoading || isNaceUidNameTuplesLoading
      || isOwnOperationsCreateLoading || isOwnOperationsRecordUpdateLoading;

  const ownOperationsEndpoint = recordId ? ownOperationsRecordUpdate : ownOperationsCreate;
  const {
    control,
    handleSubmit,
    isDirty,
    reset,
    setError,
    fieldsName,
    watch,
    setValue,
  } = useCreateOwnOperationsForm({ recordId, isPageLoading });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => isDirty
      && currentLocation.pathname !== nextLocation.pathname
      && !isConfirmationModalOpen);

  useEffect(() => {
    if (blocker.state === BLOCKER_STATES.blocked) {
      if (isDirty) {
        setIsConfirmationModalOpen(true);
      } else {
        blocker.proceed?.();
      }
    }
  }, [blocker, isDirty]);

  const onOwnOperationsSubmit = handleSubmit((values) => {
    const requestData: IOperationsRecordRequest = getRequestDataForOwnOperations({
      values,
      recordId,
    });

    ownOperationsEndpoint(requestData).unwrap()
      .then(
        () => {
          toast.success(i18n.t(
            recordId ? 'notifications.recordHasBeenUpdated'
              : 'notifications.recordHasBeenAdded',
          ));
          reset(undefined, { keepValues: true });
          navigate(AppRoutes.ownOperations);
        },
      ).catch((errors) => {
        setFieldsErrors<TCreateOperationsSchema>({ errors, setError, fieldsName });
      });
  });

  const onHandleCloseConfirmation = (isOpen = false) => {
    if (!isConfirmationModalOpen && !isDirty) {
      navigate(-1);
    } else {
      setIsConfirmationModalOpen(isOpen);
    }
  };

  const onDiscardChangesConfirm = () => {
    const currentLocation = blocker?.location?.pathname;
    if (currentLocation) {
      navigate(currentLocation);
    } else {
      navigate(-1);
    }
    reset(undefined, { keepValues: true });
  };

  const naceDivisions = watch(NACE_SECTION)?.divisions || [];

  const onNaceSelectionChange = () => {
    const emptyOption = null as PathValue<TCreateOperationsSchema, Path<TCreateOperationsSchema>>;
    setValue(NACE_CODE, emptyOption);
  };

  const {
    onFindCountries, onFindStates, onFindCities, isStatesDisabled, onChangeCountry, onChangeState,
    isCitiesDisabled,
  } = useGeoLocations<TCreateOperationsSchema>({
    watch, setValue,
  });

  const { dateOpened, onDateOpenedChange } = useDataSetsDate<TCreateOperationsSchema>({ watch, setValue });

  return (
    <CreateOwnOperationsView
      control={control}
      onOwnOperationsSubmit={onOwnOperationsSubmit}
      onHandleCloseConfirmation={onHandleCloseConfirmation}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onDiscardChangesConfirm={onDiscardChangesConfirm}
      enumData={enumData}
      naceCodesData={naceCodesData}
      naceDivisions={naceDivisions}
      onNaceSelectionChange={onNaceSelectionChange}
      uidNameTuples={uidNameTuples}
      isPageLoading={isPageLoading}
      onFindCountries={onFindCountries}
      onFindStates={onFindStates}
      onFindCities={onFindCities}
      isStatesDisabled={isStatesDisabled}
      isCitiesDisabled={isCitiesDisabled}
      onChangeCountry={onChangeCountry}
      onChangeState={onChangeState}
      dateOpened={dateOpened}
      onDateOpenedChange={onDateOpenedChange}
    />
  );
}

export default CreateOwnOperations;
