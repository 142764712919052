import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import Button from 'components/Button/Button';
import Select from 'components/Select/Select';
import TextField from 'components/TextField/TextField';
import Title from 'components/Title/Title';
import { FILTER_BY_STATUS, SEARCH } from 'constants/formFields';
import { FILTER_USER_STATUSES } from 'constants/user';

import UsersTable from './components/UsersTable/UsersTable';

import type { IUserManagementView } from './interfaces/IUserManagementView';

import styles from './sass/UserManagementView.module.scss';

function UserManagementView({
  control, onUserManagementSubmit, onCreateNewUser, users, onSearch, onFilterChange, isActionsVisible, onSortChange,
  isLoading,
}: IUserManagementView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Title className={styles.title} text={t('pillarsAndModules.settings/user-management')} />
        {isActionsVisible ? (
          <Button
            title={t('userManagement.createNewUser')}
            onClick={onCreateNewUser}
          />
        ) : null}
      </div>
      <div className={styles.form}>
        <div className={styles.search}>
          <Controller
            control={control}
            name={SEARCH}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                placeholder={t('userManagement.searchPlaceholder')}
                error={error?.message}
                icon={<SearchIcon />}
                onChange={(event) => {
                  onChange(event.target.value);
                  onSearch({ search: event.target.value, onChange });
                  onUserManagementSubmit();
                }}
                required
              />
            )}
          />
        </div>

        <div className={styles.filter}>
          <div className={styles.label}>
            <FilterIcon />
            <p>{t('clients.filterByStatus')}</p>
          </div>
          <div className={styles.select}>
            <Controller
              control={control}
              name={FILTER_BY_STATUS}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <Select
                  name={name}
                  value={value}
                  error={error?.message}
                  options={FILTER_USER_STATUSES}
                  onChange={(selectValue) => {
                    onChange(selectValue);
                    onFilterChange();
                    onUserManagementSubmit();
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <UsersTable
          users={users}
          isActionsVisible={isActionsVisible}
          control={control}
          onSortChange={onSortChange}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default UserManagementView;
