import { useTranslation } from 'react-i18next';
import { ReactComponent as Language } from 'assets/icons/language.svg';
import { ReactComponent as LoginSecurity } from 'assets/icons/login-security.svg';
import { ReactComponent as Notifications } from 'assets/icons/notifications.svg';
import { ReactComponent as PersonalInfo } from 'assets/icons/personal-info.svg';
import Loader from 'components/Loader/Loader';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import { AppRoutes } from 'constants/AppRoutes';

import type IProfileView from './interfaces/IProfileView';

import styles from './sass/ProfileView.module.scss';

function ProfileView({ companyLanguages, isLoading }: IProfileView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Loader isPageLoading={isLoading} isFullLoader={false} />
      <div className={styles.wrapper}>
        <ProfileCard
          icon={<PersonalInfo />}
          title={t('profile.personalInfo')}
          description={t('profile.personalInfoDescription')}
          navLink={AppRoutes.personalInfo}
        />
        <ProfileCard
          icon={<LoginSecurity />}
          title={t('profile.loginSecurity')}
          description={t('profile.loginSecurityDescription')}
          navLink={AppRoutes.profilePassword}
        />
        <ProfileCard
          icon={<Notifications />}
          title={t('profile.notifications')}
          description={t('profile.notificationsDescription')}
          navLink={AppRoutes.home}
        />
        {companyLanguages.length > 1 && (
          <ProfileCard
            icon={<Language />}
            title={t('profile.language')}
            description={t('profile.languageDescription')}
            navLink={AppRoutes.profileLanguage}
          />
        )}
      </div>
    </div>
  );
}

export default ProfileView;
