import { clsx } from 'clsx';

import type ILoader from './interfaces/ILoader';

import styles from './sass/Loader.module.scss';

export default function Loader({ isPageLoading, className, isFullLoader = true }: ILoader) {
  return (
    <div className={clsx(styles.root, {
      [styles.isPageLoading]: isPageLoading,
      className,
    })}
    >
      {isFullLoader ? <div className={styles.fullLoader} /> : null }
      <div className={styles.loader} />
    </div>
  );
}
