import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import TextField from 'components/TextField/TextField';
import { EMAIL } from 'constants/formFields';

import type { IRecoverPasswordView } from './interfaces/IRecoverPasswordView';

import styles from './sass/RecoverPasswordView.module.scss';

function RecoverPasswordView({
  control,
  onRecoverPasswordSubmit,
  isValid,
  isLoading,
}: IRecoverPasswordView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className="authTitle">
        {t('recoverPassword.title')}
      </div>
      <div className={styles.subTitle}>{t('recoverPassword.subTitle')}</div>
      <div className={styles.formWrapper}>
        <Controller
          control={control}
          name={EMAIL}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextField
              name={name}
              value={value}
              label={t('common.email')}
              placeholder={t('common.emailPlaceholder')}
              error={error?.message}
              onChange={(event) => onChange(event.target.value)}
              required
            />
          )}
        />
      </div>
      <Button
        title={t('common.continue')}
        onClick={onRecoverPasswordSubmit}
        disabled={!isValid}
        isLoading={isLoading}
      />
    </div>
  );
}

export default RecoverPasswordView;
