import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DataSetsPanel from 'components/DataSetsPanel/DataSetsPanel';
import DataSetsRecordButtons from 'components/DataSetsRecordButtons/DataSetsRecordButtons';
import FieldWithCurrency from 'components/FieldWithCurrency/FieldWithCurrency';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import NumberField from 'components/NumberField/NumberField';
import Panel from 'components/Panel/Panel';
import Select from 'components/Select/Select';
import Separator from 'components/Separator/Separator';
import TextField from 'components/TextField/TextField';
import { DATA_SETS_ENUM_TYPES } from 'constants/dataSets';
import {
  FINISHED_GOOD_STOCK_AMOUNT, FINISHED_GOOD_STOCK_AMOUNT_CURRENCY,
  GROSS_MARGIN_ROLLING_AMOUNT, GROSS_MARGIN_ROLLING_CURRENCY,
  GROSS_MARGIN_ROLLING_PERCENTS, PROFIT_CENTRE_NAME,
  PROFIT_CENTRE_UNIQUE_IDENTIFIER, REVENUE_ROLLING_AMOUNT, REVENUE_ROLLING_CURRENCY,
  REVENUE_ROLLING_PERCENTS,
} from 'constants/formFields';
import { NUMBER_FORMATS } from 'constants/interfaces';

import type { ICreateProfitCentreView } from './interfaces/ICreateProfitCentreView';

import styles from './sass/CreateProfitCentreView.module.scss';
import stylesCurrency from 'components/FieldWithCurrency/scss/FieldWithCurrency.module.scss';

function CreateProfitCentreView({
  control, onProfitCentreSubmit, isConfirmationModalOpen, onHandleCloseConfirmation,
  onDiscardChangesConfirm, enumData, isPageLoading,
}: ICreateProfitCentreView) {
  const { t } = useTranslation();
  const { recordId = '' } = useParams();
  const placeholder = recordId ? t('common.notProvided') : '';

  return (
    <div className={styles.root}>
      <Panel className={styles.panel}>
        <div className={styles.wrapper}>
          <Loader isPageLoading={isPageLoading} isFullLoader={false} />
          <DataSetsPanel title={t('profitCentres.createProfitCentre.generalPanel')}>
            <Controller
              control={control}
              name={PROFIT_CENTRE_NAME}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  name={name}
                  value={value}
                  label={t('profitCentres.createProfitCentre.fields.name')}
                  error={error?.message}
                  onChange={(event) => onChange(event.target.value)}
                  required
                  placeholder={placeholder}
                />
              )}
            />
            <Controller
              control={control}
              name={PROFIT_CENTRE_UNIQUE_IDENTIFIER}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <TextField
                  name={name}
                  value={value}
                  label={t('profitCentres.createProfitCentre.fields.uniqueIdentifier')}
                  error={error?.message}
                  onChange={(event) => onChange(event.target.value)}
                  required
                  placeholder={placeholder}
                  disabled={!!recordId}
                />
              )}
            />
          </DataSetsPanel>

          <DataSetsPanel title={t('profitCentres.createProfitCentre.financialsPanel')}>
            <Controller
              control={control}
              name={REVENUE_ROLLING_PERCENTS}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <NumberField
                  name={name}
                  value={value}
                  label={t('profitCentres.createProfitCentre.fields.totalRevenue')}
                  error={error?.message}
                  onChange={(values) => onChange(values.value)}
                  type={NUMBER_FORMATS.percent}
                  placeholder={placeholder}
                />
              )}
            />
            <Controller
              control={control}
              name={REVENUE_ROLLING_AMOUNT}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <FieldWithCurrency name={name} label={t('profitCentres.createProfitCentre.fields.actualRevenue')}>
                  <div className={stylesCurrency.fieldWithCurrency}>
                    <NumberField
                      name={name}
                      value={value}
                      error={error?.message}
                      onChange={(values) => onChange(values.value)}
                      placeholder={placeholder}
                    />
                    <Controller
                      control={control}
                      name={REVENUE_ROLLING_CURRENCY}
                      render={({
                        field: { value: valueCurrency, onChange: onChangeCurrency },
                        fieldState: { error: errorCurrency },
                      }) => (
                        <Select
                          placeholder={t('common.currency')}
                          options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                          value={valueCurrency?.value ? valueCurrency : undefined}
                          onChange={onChangeCurrency}
                          error={errorCurrency?.message}
                          className={stylesCurrency.select}
                        />
                      )}
                    />
                  </div>
                </FieldWithCurrency>
              )}
            />
            <Controller
              control={control}
              name={GROSS_MARGIN_ROLLING_PERCENTS}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <NumberField
                  name={name}
                  value={value}
                  label={t('profitCentres.createProfitCentre.fields.totalGross')}
                  error={error?.message}
                  onChange={(values) => onChange(values.value)}
                  type={NUMBER_FORMATS.percent}
                  placeholder={placeholder}
                />
              )}
            />
            <Controller
              control={control}
              name={GROSS_MARGIN_ROLLING_AMOUNT}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <FieldWithCurrency name={name} label={t('profitCentres.createProfitCentre.fields.actualGross')}>
                  <div className={stylesCurrency.fieldWithCurrency}>
                    <NumberField
                      name={name}
                      value={value}
                      error={error?.message}
                      onChange={(values) => onChange(values.value)}
                      placeholder={placeholder}
                    />
                    <Controller
                      control={control}
                      name={GROSS_MARGIN_ROLLING_CURRENCY}
                      render={({
                        field: { value: valueCurrency, onChange: onChangeCurrency },
                        fieldState: { error: errorCurrency },
                      }) => (
                        <Select
                          placeholder={t('common.currency')}
                          options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                          value={valueCurrency?.value ? valueCurrency : undefined}
                          onChange={onChangeCurrency}
                          error={errorCurrency?.message}
                          className={stylesCurrency.select}
                        />
                      )}
                    />
                  </div>
                </FieldWithCurrency>
              )}
            />
            <Controller
              control={control}
              name={FINISHED_GOOD_STOCK_AMOUNT}
              render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                <FieldWithCurrency name={name} label={t('profitCentres.createProfitCentre.fields.goodsStock')}>
                  <div className={stylesCurrency.fieldWithCurrency}>
                    <NumberField
                      name={name}
                      value={value}
                      error={error?.message}
                      onChange={(values) => onChange(values.value)}
                      placeholder={placeholder}
                    />
                    <Controller
                      control={control}
                      name={FINISHED_GOOD_STOCK_AMOUNT_CURRENCY}
                      render={({
                        field: { value: valueCurrency, onChange: onChangeCurrency },
                        fieldState: { error: errorCurrency },
                      }) => (
                        <Select
                          placeholder={t('common.currency')}
                          options={enumData?.[DATA_SETS_ENUM_TYPES.currency]}
                          value={valueCurrency?.value ? valueCurrency : undefined}
                          onChange={onChangeCurrency}
                          error={errorCurrency?.message}
                          className={stylesCurrency.select}
                        />
                      )}
                    />
                  </div>
                </FieldWithCurrency>
              )}
            />
          </DataSetsPanel>

        </div>
        <Separator />

        <Modal
          open={isConfirmationModalOpen}
          title={t('createNewUser.cancelModalTitle')}
          description={t('clients.cancelModalText')}
          confirmTitle={t('common.discardChanges')}
          closeTitle={t('common.keepEditing')}
          confirmVariant="error"
          onClose={() => onHandleCloseConfirmation(false)}
          onReject={() => onHandleCloseConfirmation(false)}
          onConfirm={onDiscardChangesConfirm}
          isLoading={isPageLoading}
        />
        <DataSetsRecordButtons
          onClose={onHandleCloseConfirmation}
          onSubmit={onProfitCentreSubmit}
          isLoading={isPageLoading}
        />
      </Panel>
    </div>
  );
}

export default CreateProfitCentreView;
