import {
  ADDRESS_BUILDING,
  ADDRESS_CITY,
  ADDRESS_COORDINATES,
  ADDRESS_COUNTRY,
  ADDRESS_POST_CODE,
  ADDRESS_STATE,
  ADDRESS_STREET,
  BUSINESS_UNIT_TYPE,
  CODE,
  DATE_CLOSED,
  DATE_OPENED,
  EQUITY_SHAREHOLDING,
  EXPECTED_GROWTH_NEXT_12M,
  FINANCIAL_CONTROL,
  NACE_CODE,
  NACE_SECTION,
  NAME,
  OWNERSHIP,
  PRIORITY_RATING,
  PROFIT_CENTER_ID,
  REVENUE_ROLLING_AMOUNT,
  REVENUE_ROLLING_CURRENCY,
  REVENUE_ROLLING_PERCENTS,
  UNIQUE_IDENTIFIER,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { DATA_SETS_DATE_FORMAT, DATE_FORMAT_ISO } from 'constants/general';
import dayjs from 'dayjs';

import type { IGetRequestDataForCustomer } from './interfaces/IGetRequestDataForCustomer';

function getRequestDataForCustomer({ values, recordId }: IGetRequestDataForCustomer) {
  return {
    [NAME]: values?.[NAME] || null,
    [CODE]: values?.[CODE] || null,
    [PROFIT_CENTER_ID]: values?.[PROFIT_CENTER_ID]?.map((item) => item.value) || null,
    [REVENUE_ROLLING_PERCENTS]: values?.[REVENUE_ROLLING_PERCENTS]
      ? Number.parseFloat((values[REVENUE_ROLLING_PERCENTS])) : null,
    [REVENUE_ROLLING_AMOUNT]: values?.[REVENUE_ROLLING_AMOUNT] ? {
      amount: Number(values?.[REVENUE_ROLLING_AMOUNT]),
      currency: values?.[REVENUE_ROLLING_CURRENCY].value,
    } : null,
    [EXPECTED_GROWTH_NEXT_12M]: values?.[EXPECTED_GROWTH_NEXT_12M] ? Number(values?.[EXPECTED_GROWTH_NEXT_12M]) : null,
    [OWNERSHIP]: values[OWNERSHIP]?.value || null,
    [EQUITY_SHAREHOLDING]: values?.[EQUITY_SHAREHOLDING] ? Number(values?.[EQUITY_SHAREHOLDING]) : null,
    [VALUE_CHAIN_SECTION]: values?.[VALUE_CHAIN_SECTION]?.value || null,
    [FINANCIAL_CONTROL]: values?.[FINANCIAL_CONTROL] ? Number(values?.[FINANCIAL_CONTROL]) : null,
    [BUSINESS_UNIT_TYPE]: values?.[BUSINESS_UNIT_TYPE]?.value || null,
    [DATE_OPENED]: values?.[DATE_OPENED] ? dayjs(values?.[DATE_OPENED], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [DATE_CLOSED]: values?.[DATE_CLOSED] ? dayjs(values?.[DATE_CLOSED], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [PRIORITY_RATING]: values?.[PRIORITY_RATING]?.value ? Number(values?.[PRIORITY_RATING]?.value) : null,
    [NACE_SECTION]: values?.[NACE_SECTION]?.value || null,
    [NACE_CODE]: values[NACE_CODE]?.value || null,
    [ADDRESS_COUNTRY]: values?.[ADDRESS_COUNTRY]?.label || null,
    [ADDRESS_STATE]: values?.[ADDRESS_STATE]?.label || null,
    [ADDRESS_POST_CODE]: values?.[ADDRESS_POST_CODE] || null,
    [ADDRESS_CITY]: values?.[ADDRESS_CITY]?.label || null,
    [ADDRESS_STREET]: values?.[ADDRESS_STREET] || null,
    [ADDRESS_BUILDING]: values?.[ADDRESS_BUILDING] || null,
    [ADDRESS_COORDINATES]: values?.[ADDRESS_COORDINATES] || null,
    [ADDRESS_COORDINATES]: values?.[ADDRESS_COORDINATES] || null,
    ...(!recordId && { [UNIQUE_IDENTIFIER]: values?.[UNIQUE_IDENTIFIER] }),
    ...(recordId && { id: Number(recordId) }),
  };
}

export default getRequestDataForCustomer;
