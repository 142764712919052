import { useEffect, useState } from 'react';
import {
  useBlocker,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { skipToken } from '@reduxjs/toolkit/query';
import AppRoutes from 'constants/AppRoutes';
import { DATA_SETS_ENUM_TYPES } from 'constants/dataSets';
import { BLOCKER_STATES } from 'constants/general';
import useFieldsErrors from 'hooks/useFieldsErrors';
import i18n from 'i18n';
import {
  useEnumQuery,
  useProfitCenterCreateMutation, useProfitCenterRecordQuery,
  useProfitCenterRecordUpdateMutation,
} from 'store/slices/dataSets/apis/dataSetsApi';
import { selectEnums } from 'store/slices/dataSets/selectors';
import getRequestDataForProfitCentre from 'utils/formHelpers/getRequestDataForProfitCentre';

import useCreateProfitCentreForm from './hooks/useCreateProfitCentreForm';
import CreateProfitCentreView from './CreateProfitCentreView';

import type { ReactElement } from 'react';
import type IProfitCenterRecordRequest from 'store/slices/dataSets/interfaces/IProfitCenterRecordRequest';
import type { TCreateProfitCentreSchema } from 'utils/validators';

function CreateProfitCentre(): ReactElement {
  const navigate = useNavigate();
  const { recordId = '' } = useParams();

  const [profitCenterCreate, { isLoading: isProfitCenterCreateLoading }] = useProfitCenterCreateMutation();

  const [profitCenterRecordUpdate, { isLoading: isProfitCenterRecordLoading }] = useProfitCenterRecordUpdateMutation();
  const { setFieldsErrors } = useFieldsErrors();

  const { data: enumData, isLoading: isEnumLoading } = useEnumQuery({
    types: [DATA_SETS_ENUM_TYPES.currency],
  }, {
    selectFromResult: (result) => ({ ...result, data: selectEnums(result.data) }),
  });

  const fetchQuery = recordId ? {
    recordId,
  } : skipToken;
  const { isLoading } = useProfitCenterRecordQuery(fetchQuery);

  const isPageLoading = isEnumLoading || isLoading || isProfitCenterCreateLoading || isProfitCenterRecordLoading;
  const profitCenterEndpoint = recordId ? profitCenterRecordUpdate : profitCenterCreate;
  const {
    control,
    handleSubmit,
    isDirty,
    reset,
    setError,
    fieldsName,
  } = useCreateProfitCentreForm({ recordId, isPageLoading });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => isDirty
      && currentLocation.pathname !== nextLocation.pathname
      && !isConfirmationModalOpen);

  useEffect(() => {
    if (blocker.state === BLOCKER_STATES.blocked) {
      if (isDirty) {
        setIsConfirmationModalOpen(true);
      } else {
        blocker.proceed?.();
      }
    }
  }, [blocker, isDirty]);

  const onProfitCentreSubmit = handleSubmit((values) => {
    const requestData: IProfitCenterRecordRequest = getRequestDataForProfitCentre({ values, recordId });

    profitCenterEndpoint(requestData).unwrap()
      .then(
        () => {
          toast.success(i18n.t(
            recordId ? 'notifications.recordHasBeenUpdated'
              : 'notifications.recordHasBeenAdded',
          ));
          reset(undefined, { keepValues: true });
          navigate(AppRoutes.profitCentres);
        },
      ).catch((errors) => {
        setFieldsErrors<TCreateProfitCentreSchema>({ errors, setError, fieldsName });
      });
  });

  const onHandleCloseConfirmation = (isOpen = false) => {
    if (!isConfirmationModalOpen && !isDirty) {
      navigate(-1);
    } else {
      setIsConfirmationModalOpen(isOpen);
    }
  };

  const onDiscardChangesConfirm = () => {
    const currentLocation = blocker?.location?.pathname;
    if (currentLocation) {
      navigate(currentLocation);
    } else {
      navigate(-1);
    }
    reset(undefined, { keepValues: true });
  };

  return (
    <CreateProfitCentreView
      control={control}
      onProfitCentreSubmit={onProfitCentreSubmit}
      onHandleCloseConfirmation={onHandleCloseConfirmation}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onDiscardChangesConfirm={onDiscardChangesConfirm}
      enumData={enumData}
      isPageLoading={isPageLoading}
    />
  );
}

export default CreateProfitCentre;
