import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useMatch, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { skipToken } from '@reduxjs/toolkit/query';
import { AppRoutes } from 'constants/AppRoutes';
import {
  COMPANY_LOGO,
  HEADINGS_COLOR_1,
  HEADINGS_COLOR_2,
  PARTNER_LOGO, PRIMARY_COLOR,
  SECONDARY_COLOR, SUBTITLE_COLOR,
} from 'constants/formFields';
import { USER_PILLAR_MODULES, USER_ROLE_TYPES } from 'constants/interfaces';
import useCompanyDraft from 'hooks/useCompanyDraft';
import useCreateClient from 'hooks/useCreateClient';
import usePillarModulePermission from 'hooks/usePillarModulePermission';
import { useAppSelector } from 'store/hooks/useApp';
import { useGetCompanyDetailsQuery, useUpdateUiConfigurationMutation } from 'store/slices/companies/apis/companiesApi';
import { createFlowCompanyIdSelector, selectUiConfiguration } from 'store/slices/companies/selectors';
import {
  useCompanyAbilitiesDetailsQuery,
  useUpdateCompanyUiConfigurationMutation,
} from 'store/slices/companyAdminUsers/apis/companyAdminUsersApi';
import { selectCompanyUiConfiguration } from 'store/slices/companyAdminUsers/selectors';
import { useGetPresignedPostMutation } from 'store/slices/files/apis/filesApi';
import { useUserMeQuery } from 'store/slices/user/apis/user';
import { uploadFileToS3 } from 'utils/helpers';
import { companyUIConfigurationSchema } from 'utils/validators';

import UIConfigurationView from './UICofigurationView';

import type { TPresignedFileTypes } from 'constants/interfaces';
import type IHandleFormSubmit from 'hooks/interfaces/IHandleFormSubmit';
import type { TCompanyUiConfigurationSchema } from 'utils/validators';

function UIConfiguration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const newCompanyId = useAppSelector(createFlowCompanyIdSelector);
  const { isCreateFlow, clientId } = useCreateClient();

  const currentCompanyId = newCompanyId && isCreateFlow ? newCompanyId : +clientId;

  const { data: me, isLoading: isUserMeLoading } = useUserMeQuery();

  const { role } = me?.data || {};

  const isSuperAdmin = role === USER_ROLE_TYPES.admin;

  const fetchCompanyAbilitiesDetailsParams = isSuperAdmin ? skipToken : undefined;

  const {
    uiConfiguration: companyAdminUiConfiguration,
    isLoading: isCompanyAbilitiesLoading,
  } = useCompanyAbilitiesDetailsQuery(
    fetchCompanyAbilitiesDetailsParams,
    {
      selectFromResult: (result) => ({ ...result, uiConfiguration: selectCompanyUiConfiguration(result.data) }),
    },
  );

  const fetchParams = (newCompanyId || !isCreateFlow) && isSuperAdmin ? {
    companyId: currentCompanyId,
  } : skipToken;

  const { uiConfiguration, isFetching: isCompanyDetailsLoading } = useGetCompanyDetailsQuery(
    fetchParams,
    {
      selectFromResult: (result) => ({ ...result, uiConfiguration: selectUiConfiguration(result.data) }),
    },
  );

  const [updateUiConfiguration, { isLoading: isUpdateUiConfigurationLoading }] = useUpdateUiConfigurationMutation();
  const [getPresignedPost, { isLoading: isGetPresignedPostLoading }] = useGetPresignedPostMutation();
  const [updateCompanyUiConfiguration,
    { isLoading: isUpdateCompanyUiLoading },
  ] = useUpdateCompanyUiConfigurationMutation();

  const {
    control,
    handleSubmit: onSubmit,
    formState: {
      isValid, isSubmitted, isDirty,
    },
    reset,
  } = useForm<TCompanyUiConfigurationSchema>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(companyUIConfigurationSchema),
    defaultValues: {
      [COMPANY_LOGO]: undefined,
      [PARTNER_LOGO]: undefined,
      [PRIMARY_COLOR]: '#115E67',
      [SECONDARY_COLOR]: '#C9E1E5',
      [HEADINGS_COLOR_1]: '#07272D',
      [HEADINGS_COLOR_2]: '#07272D',
      [SUBTITLE_COLOR]: '#8EA4A6',
    },
  });

  const {
    isActionsVisible,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.uiConfiguration },
  );
  const isCompanyUiConfigurationPage = !!useMatch(AppRoutes.companyUiConfiguration);
  const configuration = isSuperAdmin ? uiConfiguration : companyAdminUiConfiguration;
  const updateConfiguration = isSuperAdmin ? updateUiConfiguration : updateCompanyUiConfiguration;
  const disableButton = !isValid && isSubmitted;
  const isCreatePage = isCreateFlow && isSuperAdmin;

  const isLoading = isUserMeLoading || isCompanyAbilitiesLoading || isCompanyDetailsLoading
      || isUpdateUiConfigurationLoading || isGetPresignedPostLoading || isUpdateCompanyUiLoading;

  useEffect(() => {
    if (configuration) {
      reset({
        [COMPANY_LOGO]: configuration?.companyLogo,
        [PARTNER_LOGO]: configuration?.partnerLogo,
        [PRIMARY_COLOR]: configuration.colorPalette.primary,
        [SECONDARY_COLOR]: configuration.colorPalette.secondary,
        [HEADINGS_COLOR_1]: configuration.colorPalette.headings1,
        [HEADINGS_COLOR_2]: configuration.colorPalette.headings2,
        [SUBTITLE_COLOR]: configuration.colorPalette.subtitle,
      });
    }
  }, [reset, configuration]);

  const uploadLogo = (logo: File | string, fileType: TPresignedFileTypes) => {
    if (typeof logo === 'object') {
      return getPresignedPost({ fileType })
        .unwrap()
        .then((presignedData) => uploadFileToS3(logo, presignedData.data)
          .then(() => presignedData.data.fields.Url))
        .catch(() => '');
    }
    return Promise.resolve(logo);
  };

  const handleFormSubmit: IHandleFormSubmit<TCompanyUiConfigurationSchema> = ({ values, isDraftSave }) => {
    const data = {
      companyLogo: '',
      ...(isSuperAdmin && { partnerLogo: '' }),
      colorPalette: {
        primary: values.primary,
        secondary: values.secondary,
        headings1: values.headings1,
        headings2: values.headings2,
        subtitle: values.subtitle,
      },
    };

    Promise.all([
      uploadLogo(values.companyLogo, 'company-logo').then((url) => { data.companyLogo = url; }),
      isSuperAdmin && uploadLogo(values.partnerLogo || '', 'partner-logo').then((url) => { data.partnerLogo = url; }),
    ])
      .then(() => updateConfiguration({
        ...(isSuperAdmin && { companyId: currentCompanyId }),
        ...data,
      }).unwrap())
      .then(() => {
        reset(undefined, { keepValues: true });

        if (isDraftSave) {
          toast.success(t('notifications.clientCreatedInDraft'));
        } else if (isSuperAdmin && isCreateFlow) {
          navigate(generatePath(AppRoutes.moduleManagement, { clientId }));
        }
      })
      .catch(() => {});
  };

  const { onDraftSave } = useCompanyDraft<TCompanyUiConfigurationSchema>({ handleFormSubmit, onSubmit });

  const onHandleNextClick = onSubmit((values) => {
    handleFormSubmit({ values });
  });

  const onHandlePrevClick = () => {
    navigate(generatePath(AppRoutes.companyAdmin, { clientId }));
  };

  return (
    <UIConfigurationView
      control={control}
      isCreatePage={isCreatePage}
      isDisabledSave={disableButton}
      onPrevClick={onHandlePrevClick}
      onSave={onHandleNextClick}
      isDirty={isDirty}
      isSuperAdmin={isSuperAdmin}
      onDraftSave={onDraftSave}
      isActionsVisible={isActionsVisible}
      isCompanyUiConfigurationPage={isCompanyUiConfigurationPage}
      isLoading={isLoading}
    />
  );
}

export default UIConfiguration;
