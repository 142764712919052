import { useState } from 'react';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import AppRoutes from 'constants/AppRoutes';
import { SEARCH_PARAMS } from 'constants/dataSets';
import { SHOW_ARCHIVED } from 'constants/formFields';
import { USER_PILLAR_MODULES } from 'constants/interfaces';
import useDataSetListActions from 'hooks/useDataSetListActions';
import useDownloadExample from 'hooks/useDownloadExample';
import usePillarModulePermission from 'hooks/usePillarModulePermission';
import {
  useGetNaceCodeTreeQuery,
  useLazySupplierExampleQuery,
  useSupplierArchiveMutation, useSupplierDeArchiveMutation,
  useSupplierImportRecordsMutation,
  useSupplierListQuery,
} from 'store/slices/dataSets/apis/dataSetsApi';

import useDataSetsListForm from '../ProfitCentres/hooks/useDataSetsListForm';
import SuppliersView from './SuppliersView';

import type ISupplierItem from 'store/slices/dataSets/interfaces/ISupplierItem';
import type { TUseSupplierImportRecordsMutation } from './interfaces/TUseSupplierImportRecordsMutation';

function Suppliers() {
  const navigate = useNavigate();
  const {
    isActionsVisible,
    isLoading: isPillarModuleLoading,
  } = usePillarModulePermission(
    { pillarModuleKey: USER_PILLAR_MODULES.settings.dataSetsManagement },
  );

  const [getSuppliersheetExample, { isLoading: isLazySupplierExampleLoading }] = useLazySupplierExampleQuery();
  const {
    control, getValues, setValue, watch,
  } = useDataSetsListForm();
  const [orderBy, setOrderBy] = useState('');
  const [searchParams] = useSearchParams();

  const isArchived = watch(SHOW_ARCHIVED);
  const searchParam = searchParams.get(SEARCH_PARAMS.search) || '';
  const offset = Number(searchParams.get(SEARCH_PARAMS.offset) || 0);

  const { data, isFetching: isSupplierListLoading } = useSupplierListQuery({
    orderBy, keyword: searchParam, archived: isArchived, offset,
  });

  const [importRecords, { isLoading: isSupplierImportRecordsLoading }] = useSupplierImportRecordsMutation();

  const {
    uploadControl, isMappingModalVisible, onCloseMappingModal, isImportErrorsModalVisible,
    setIsImportErrorsModalVisible,
  } = useDownloadExample<TUseSupplierImportRecordsMutation>({ importRecords });

  const { items } = data?.data || {};

  const [deArchive, { isLoading: isSupplierDeArchiveLoading }] = useSupplierDeArchiveMutation();

  const [archive, { isLoading: isSupplierArchiveLoading }] = useSupplierArchiveMutation();

  const { data: naceCodeTreeData = { data: {} }, isLoading: isNaceCodeTreeLoading } = useGetNaceCodeTreeQuery();

  const {
    isArchiveModalVisible,
    isArchiveModalInfoVisible,
    latestArchivation,
    isLineMark,
    onSortChange,
    onSearchChange,
    onSelectAll,
    onArchiveConfirm,
    onArchive,
    onArchiveModalInfoOpen,
    onMoveToArchive,
    onDeArchive,
    onPageChange,
    isArchiveSelectedItemsVisible,
    setIsArchiveModalVisible,
    setIsArchiveModalInfoVisible,
    selectedItems,
    onShowArchiveChange,
  } = useDataSetListActions<ISupplierItem[]>({
    items,
    archiveAction: archive,
    deArchiveAction: deArchive,
    setOrderBy,
    getValues,
    setValue,
    watch,
  });

  const isWithSearch = !!searchParam;

  const isUploadFileVisible = isActionsVisible;

  const isEmptyList = !data?.data?.meta?.count || data?.data?.items?.length === 0;

  const currentTotalItems = items?.length || 0;

  const onDownloadExample = () => {
    getSuppliersheetExample();
  };

  const onEditRecord = (recordId: number) => {
    navigate(generatePath(AppRoutes.supplierEdit, { recordId: String(recordId) }));
  };

  const onAddRecord = () => {
    navigate(AppRoutes.supplierCreate);
  };

  const meta = data?.data?.meta;

  const isLoading = isSupplierListLoading || isSupplierImportRecordsLoading || isSupplierDeArchiveLoading
      || isPillarModuleLoading || isSupplierArchiveLoading || isLazySupplierExampleLoading || isNaceCodeTreeLoading;

  return (
    <SuppliersView
      isEmptyList={isEmptyList}
      uploadControl={uploadControl}
      isUploadFileVisible={isUploadFileVisible}
      isMappingModalVisible={isMappingModalVisible}
      setIsImportErrorsModalVisible={setIsImportErrorsModalVisible}
      onCloseMappingModal={onCloseMappingModal}
      isImportErrorsModalVisible={isImportErrorsModalVisible}
      onDownloadExample={onDownloadExample}
      importRecords={importRecords}
      control={control}
      isArchiveModalVisible={isArchiveModalVisible}
      isArchiveModalInfoVisible={isArchiveModalInfoVisible}
      latestArchivation={latestArchivation}
      isLineMark={isLineMark}
      onSortChange={onSortChange}
      onSearchChange={onSearchChange}
      onSelectAll={onSelectAll}
      onArchiveConfirm={onArchiveConfirm}
      onArchive={onArchive}
      onArchiveModalInfoOpen={onArchiveModalInfoOpen}
      onMoveToArchive={onMoveToArchive}
      onDeArchive={onDeArchive}
      onPageChange={onPageChange}
      isArchiveSelectedItemsVisible={isArchiveSelectedItemsVisible}
      setIsArchiveModalVisible={setIsArchiveModalVisible}
      setIsArchiveModalInfoVisible={setIsArchiveModalInfoVisible}
      isActionsVisible={isActionsVisible}
      items={items}
      selectedItems={selectedItems}
      currentTotalItems={currentTotalItems}
      onEditRecord={onEditRecord}
      onAddRecord={onAddRecord}
      meta={meta}
      naceCodeTreeData={naceCodeTreeData}
      isWithSearch={isWithSearch}
      isLoading={isLoading}
      isLazySupplierExampleLoading={isLazySupplierExampleLoading}
      onShowArchiveChange={onShowArchiveChange}
    />
  );
}

export default Suppliers;
