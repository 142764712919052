import { useTranslation } from 'react-i18next';
import { ReactComponent as FingerprintIcon } from 'assets/icons/fingerprint.svg';
import { clsx } from 'clsx';
import DeactivationModal from 'components/DeactivationModals/DeactivationModal';
import Loader from 'components/Loader/Loader';
import Panel from 'components/Panel/Panel';
import Separator from 'components/Separator/Separator';
import Subtitle from 'components/Subtitle/Subtitle';
import Title from 'components/Title/Title';

import UserSecuritySection from './components/UserSecuritySection/UserSecuritySection';

import type IUserSecurityView from './interfaces/IUserSecurityView';

import styles from './scss/UserSecurityView.module.scss';

function UserSecurityView({
  user, onResetPassword, onResetMfa,
  isModalOpen, onToggleModal, isUserInfoPage, isActionsVisible, isPageLoading,
}: IUserSecurityView) {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.root, {
      [styles.companyAdminRoot]: isUserInfoPage,
    })}
    >
      {!isUserInfoPage ? (
        <Title
          className={styles.title}
          text={t('editUserInfo.title')}
        />
      ) : null}
      <Panel className={styles.panel}>
        <Loader isPageLoading={isPageLoading} isFullLoader={false} />
        <div className={styles.content}>
          <Subtitle
            className={styles.subtitle}
            text={t('editUserInfo.subtitle')}
            icon={isUserInfoPage ? <FingerprintIcon /> : undefined}
          />
          <Separator />
          <UserSecuritySection
            user={user}
            onResetPassword={onResetPassword}
            onResetMfa={onToggleModal}
            isUserInfoPage={isUserInfoPage}
            isActionsVisible={isActionsVisible}
          />
        </div>

      </Panel>
      <DeactivationModal
        open={isModalOpen}
        title={t('editUserInfo.resetMfaReason')}
        onReject={() => onToggleModal()}
        onSubmit={onResetMfa}
        isLoading={isPageLoading}
      />
    </div>
  );
}

export default UserSecurityView;
