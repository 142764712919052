import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Badge from 'components/Badge/Badge';
import Button from 'components/Button/Button';
import DeactivationInfoModal from 'components/DeactivationModals/DeactivationInfoModal';
import IconButton from 'components/IconButton/IconButton';
import InfoPanel from 'components/InfoPanel/InfoPanel';
import Separator from 'components/Separator/Separator';
import { AppRoutes } from 'constants/AppRoutes';
import { COMPANY_STATUSES } from 'constants/company';
import { COMPANY_STATUS } from 'constants/interfaces';

import type ICompanyInfoSection from './interfaces/ICompanyInfoSection';

import styles from './scss/CompanyInfoSection.module.scss';

function CompanyInfoSection({
  title, type, description, onChangeCompanyStatus,
  deactivationInfo, status = COMPANY_STATUS.draft,
}: ICompanyInfoSection) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId = '' } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onHandleEdit = () => {
    const redirectPath = generatePath(AppRoutes.clientProfile, { clientId });
    navigate(generatePath(AppRoutes.companyInfo, { clientId }), { state: { redirectPath } });
  };

  return (
    <InfoPanel
      title={t('clients.companyInfo')}
      icon={<InfoIcon />}
      onEdit={onHandleEdit}
    >
      <div className={styles.item}>
        <h3>{t('common.name')}</h3>
        <p>{title || '-'}</p>
      </div>
      <Separator />
      <div className={styles.item}>
        <h3>{t('common.type')}</h3>
        <p>{t(`common.${type}`) || '-'}</p>
      </div>
      {description ? (
        <>
          <Separator />
          <div className={styles.item}>
            <h3>{t('common.description')}</h3>
            <p>{description}</p>
          </div>
        </>
      ) : null}
      <Separator />
      <div className={styles.item}>
        <h3>{t('common.status')}</h3>
        <div className={styles.action}>
          <div className={styles.wrapper}>
            <Badge
              status={COMPANY_STATUSES[status].label}
              color={COMPANY_STATUSES[status].color}
              textColor={COMPANY_STATUSES[status].textColor}
            />
            {status === COMPANY_STATUS.deactivated ? (
              <IconButton
                className={styles.info}
                size="small"
                icon={<InfoIcon />}
                onClick={() => setIsModalOpen(true)}
                isWarning
              />
            ) : null}
          </div>
          <Button
            title={COMPANY_STATUSES[status].actionLabel}
            startIcon={COMPANY_STATUSES[status].actionIcon}
            variant="ghost"
            onClick={onChangeCompanyStatus}
          />
        </div>
      </div>
      <DeactivationInfoModal
        open={isModalOpen}
        deactivationInfo={deactivationInfo}
        onClose={() => setIsModalOpen(false)}
      />
    </InfoPanel>
  );
}

export default CompanyInfoSection;
