import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogOutIcon } from 'assets/icons/logOut.svg';
import { clsx } from 'clsx';
import IconButton from 'components/IconButton/IconButton';
import AppRoutes from 'constants/AppRoutes';
import { USERS_ROLE } from 'constants/interfaces';
import { sidebarNav } from 'constants/navigationLists';
import { useAppDispatch } from 'store/hooks/useApp';
import { useLogoutMutation } from 'store/slices/auth/apis/auth';
import { logoutUser } from 'store/slices/auth/slice';
import { useUserMeQuery } from 'store/slices/user/apis/user';

import useTheme from '../../hooks/useTheme';
import SidebarItem from './components/SidebarItem/SidebarItem';

import type { ReactElement } from 'react';

import styles from './sass/Sidebar.module.scss';

function Sidebar(): ReactElement {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();
  const { data } = useUserMeQuery();
  const { resetToDefaultTheme } = useTheme();

  const { role } = data?.data || {};
  const navigate = useNavigate();
  const isSuperAdmin = role === USERS_ROLE.admin;
  const isInitiator = !!data?.data?.initiator;

  const onClickLogout = (() => {
    logout()
      .unwrap()
      .then(() => {
        dispatch(
          logoutUser(),
        );
        resetToDefaultTheme();
        navigate(AppRoutes.login);
      });
  });
  return (
    <aside className={clsx(styles.aside, {
      [styles.initiator]: isInitiator,
    })}
    >
      <div className={styles.links}>
        {role ? sidebarNav[role].map(({ to, icon, key }) => (
          <SidebarItem to={to} icon={icon} pillarKey={key} key={key} isSuperAdmin={isSuperAdmin} />
        )) : null}

      </div>
      <IconButton
        icon={<LogOutIcon width={30} height={30} />}
        onClick={onClickLogout}
        className={styles.logout}
      />
    </aside>
  );
}

export default Sidebar;
