import {
  UID_NAME_TUPLES,
  VALUE_CHAIN_SECTION_DEFAULT_VALUES,
} from 'constants/dataSets';
import {
  ADDRESS_BUILDING,
  ADDRESS_CITY,
  ADDRESS_COORDINATES,
  ADDRESS_COUNTRY,
  ADDRESS_POST_CODE,
  ADDRESS_STATE,
  ADDRESS_STREET,
  BUSINESS_UNIT_TYPE,
  CODE,
  DATE_CLOSED,
  DATE_OPENED,
  EQUITY_SHAREHOLDING,
  EXPECTED_GROWTH_NEXT_12M,
  FINANCIAL_CONTROL,
  NACE_CODE,
  NACE_SECTION,
  NAME,
  OWNERSHIP,
  PRIORITY_RATING,
  PROFIT_CENTER_ID,
  REVENUE_ROLLING_AMOUNT,
  REVENUE_ROLLING_CURRENCY,
  REVENUE_ROLLING_PERCENTS,
  UNIQUE_IDENTIFIER,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { DATA_SETS_DATE_FORMAT } from 'constants/general';
import dayjs from 'dayjs';

import createUidNameTuplesOptions from '../createUidNameTuplesOptions';
import getFormValue from '../getFormValue';
import getNaceLabel from '../getNaceLabel';

import type { IGetCustomerDefaultValues } from './interfaces/IGetCustomerDefaultValues';

function getCustomerDefaultValues({ values, naceCodeTreeData, uidNameTuples }: IGetCustomerDefaultValues) {
  const {
    name,
    code,
    profitCenterId,
    addressBuilding,
    addressCity,
    addressCountry,
    addressPostCode,
    addressState,
    addressStreet,
    businessUnitType,
    dateClosed,
    dateOpened,
    naceCode,
    equityShareholding,
    financialControl,
    expectedGrowthNext12M,
    totalRevenueRolling12MActual,
    totalRevenueRolling12MPercents,
    ownership,
    uniqueIdentifier,
    valueChainSection,
    naceSection,
    addressCoordinates,
    priorityRating,
  } = values || {};

  const defaultOption = undefined;

  const profitCenterOptions = createUidNameTuplesOptions({
    uidNameTuples,
    values: profitCenterId || [],
    tuplesKey: UID_NAME_TUPLES.profitCenter,
  });

  const { naceSectionLabel, naceDvisions, naceCodeLabel } = getNaceLabel(
    { naceCode: naceCode || '', naceSection: naceSection || '', naceCodeTreeData },
  );

  return {
    [NAME]: name || '',
    [CODE]: code || '',
    [UNIQUE_IDENTIFIER]: uniqueIdentifier || '',
    [PROFIT_CENTER_ID]: profitCenterOptions?.length > 0 ? profitCenterOptions : [],
    [VALUE_CHAIN_SECTION]: valueChainSection ? {
      value: valueChainSection, label: valueChainSection,
    } : { value: VALUE_CHAIN_SECTION_DEFAULT_VALUES.customers, label: VALUE_CHAIN_SECTION_DEFAULT_VALUES.customers },
    [OWNERSHIP]: ownership ? {
      value: ownership, label: ownership,
    } : defaultOption,
    [EQUITY_SHAREHOLDING]: getFormValue({ value: equityShareholding }),
    [FINANCIAL_CONTROL]: getFormValue({ value: financialControl }),
    [BUSINESS_UNIT_TYPE]: businessUnitType ? {
      value: businessUnitType, label: businessUnitType,
    } : defaultOption,
    [PRIORITY_RATING]: priorityRating ? {
      value: priorityRating, label: priorityRating,
    } : defaultOption,
    [DATE_OPENED]: dateOpened ? dayjs(dateOpened).format(DATA_SETS_DATE_FORMAT) : '',
    [DATE_CLOSED]: dateClosed ? dayjs(dateClosed).format(DATA_SETS_DATE_FORMAT) : '',
    [NACE_SECTION]: naceSection ? {
      value: naceSection,
      label: naceSectionLabel || '',
      divisions: naceDvisions,
    } : defaultOption,
    [NACE_CODE]: naceCode ? {
      value: naceCode,
      label: naceCodeLabel || '',
    } : defaultOption,
    [EXPECTED_GROWTH_NEXT_12M]: getFormValue({ value: expectedGrowthNext12M }),
    [REVENUE_ROLLING_PERCENTS]: getFormValue({ value: totalRevenueRolling12MPercents }),
    [REVENUE_ROLLING_AMOUNT]: getFormValue({ value: totalRevenueRolling12MActual?.amount }),
    [REVENUE_ROLLING_CURRENCY]: totalRevenueRolling12MActual?.currency ? {
      value: totalRevenueRolling12MActual.currency, label: totalRevenueRolling12MActual.currency,
    } : defaultOption,
    [ADDRESS_COUNTRY]: addressCountry ? {
      value: addressCountry, label: addressCountry,
    } : defaultOption,
    [ADDRESS_STATE]: addressState ? {
      value: addressState, label: addressState,
    } : defaultOption,
    [ADDRESS_POST_CODE]: addressPostCode || '',
    [ADDRESS_CITY]: addressCity ? {
      value: addressCity, label: addressCity,
    } : defaultOption,
    [ADDRESS_STREET]: addressStreet || '',
    [ADDRESS_BUILDING]: addressBuilding || '',
    [ADDRESS_COORDINATES]: addressCoordinates || '',
  };
}

export default getCustomerDefaultValues;
