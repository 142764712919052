import { clsx } from 'clsx';

import type IDotsLoader from './interfaces/IDotsLoader';

import styles from './sass/DotsLoader.module.scss';

export default function DotsLoader({ className }: IDotsLoader) {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.dotsLoader}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
