import type { DATA_SETS_ENUM_TYPES, UID_NAME_TUPLES } from './dataSets';

export const BUTTON_VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
  cancel: 'cancel',
  action: 'action',
  error: 'error',
  ghost: 'ghost',
} as const;

export const ICON_BUTTON_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export const USER_STATUS = {
  draft: 'draft',
  invited: 'invited',
  active: 'active',
  deactivated: 'deactivated',
} as const;

export const SEPARATOR_VARIANT = {
  vertical: 'vertical',
  horizontal: 'horizontal',
} as const;

export const STATUS = {
  enabled: 'enabled',
  disabled: 'disabled',
} as const;

export const PILLAR_ACCESS_TYPES = {
  invisible_disabled: 'invisible-disabled',
  dimmed_disabled: 'dimmed-disabled',
  visible_enabled: 'visible-enabled',
} as const;

export const COMPANY_STATUS = {
  draft: 'draft',
  active: 'active',
  deactivated: 'deactivated',
} as const;

export const PRESIGNED_FILE_TYPES = {
  company_logo: 'company-logo',
  partner_logo: 'partner-logo',
  dataSet: 'data-set',
} as const;

export const ORDERS = {
  asc: 'ASC',
  desc: 'DESC',
} as const;

export const USER_ROLE_TYPES = {
  admin: 'admin',
  companyAdmin: 'company-admin',
  facilitator: 'facilitator',
  contributor: 'contributor',
  auditor: 'auditor',
} as const;

export const THEME = {
  light: 'light',
  dark: 'dark',
};

export const INPUT_TYPES = {
  text: 'text',
  password: 'password',
  email: 'email',
  tel: 'tel',
};

export const TRANSLATE_STATUS = {
  idle: 'idle',
  loading: 'loading',
  failed: 'failed',
};

export const AUTHENTICATOR_SETUP_MODAL_STEPS = {
  phone: 'phone',
  smsCode: 'smsCode',
  authenticationSetup: 'authentication',
  downloadApp: 'downloadApp',
  qrCode: 'qrCode',
  secretCode: 'secretCode',
  verificationCode: 'verificationCode',
} as const;

export const MFA_SMS_SETUP_MODAL_STEPS = {
  phone: 'phone',
  smsCode: 'smsCode',
} as const;

export const MFA_ENDPOINTS = {
  startSyncWithApp: 'startSyncWithApp',
  endSyncWithApp: 'endSyncWithApp',
  verifyViaApp: 'verifyViaApp',
  startSyncWithPhone: 'startSyncWithPhone',
  endSyncWithPhone: 'endSyncWithPhone',
  verifyViaPhone: 'verifyViaPhone',
  sendSms: 'sendSms',
  loginInfo: 'loginInfo',
  newPassword: 'newPassword',
  forgotPasswordConfirm: 'forgotPasswordConfirm',
} as const;

export const USERS_ROLE = {
  admin: 'admin',
  companyAdmin: 'company-admin',
  administrator: 'administrator',
  facilitator: 'facilitator',
  contributor: 'contributor',
  auditor: 'auditor',
} as const;

export const USER_PILLAR_ACCESS_TYPES = {
  noAccess: 'no-access',
  viewOnly: 'view-only',
  viewEdit: 'view-edit',
  viewAudit: 'view-audit',
  dimmedDisabled: 'dimmed-disabled',
  invisibleDisabled: 'invisible-disabled',
} as const;

export const USER_PILLARS = {
  valueChainAssessment: 'value-chain-assessment',
  materialityAssessment: 'materiality-assessment',
  settings: 'settings',
} as const;

export const NUMBER_FORMATS = {
  percent: 'percent',
  currency: 'currency',
  decimal: 'decimal',
  number: 'number',
} as const;

export const USER_PILLAR_MODULES = {
  [USER_PILLARS.valueChainAssessment]: {
    entityEditing: 'value-chain-assessment/entity-editing',
    entityLinking: 'value-chain-assessment/entity-linking',
  },
  [USER_PILLARS.materialityAssessment]: {
    entityEditing: 'materiality-assessment/entity-editing',
    entityLinking: 'materiality-assessment/entity-linking',
  },
  [USER_PILLARS.settings]: {
    userManagement: 'settings/user-management',
    multiLingual: 'settings/multi-lingual',
    uiConfiguration: 'settings/ui-configuration',
    dataSetsManagement: 'settings/data-sets-management',
  },
} as const;

export const FILTER_TABS = {
  organizational: 'organizational',
  geographical: 'geographical',
} as const;

export type TUsersRole = typeof USERS_ROLE[keyof typeof USERS_ROLE];
export type TTheme = typeof THEME[keyof typeof THEME];
export type TInputTypes = typeof INPUT_TYPES[keyof typeof INPUT_TYPES];
export type TTranslateStatus = typeof TRANSLATE_STATUS[keyof typeof TRANSLATE_STATUS];
export type TButtonVariant = typeof BUTTON_VARIANT[keyof typeof BUTTON_VARIANT];
export type TSeparatorVariant = typeof SEPARATOR_VARIANT[keyof typeof SEPARATOR_VARIANT];
export type TIconButtonSizes = typeof ICON_BUTTON_SIZES[keyof typeof ICON_BUTTON_SIZES];
export type TStatus = typeof STATUS[keyof typeof STATUS];
export type TUserStatus = typeof USER_STATUS[keyof typeof USER_STATUS];
export type TPillarAccessTypes = typeof PILLAR_ACCESS_TYPES[keyof typeof PILLAR_ACCESS_TYPES];
export type TUserPillarAccessTypes = typeof USER_PILLAR_ACCESS_TYPES[keyof typeof USER_PILLAR_ACCESS_TYPES];
export type TCompanyStatus = typeof COMPANY_STATUS[keyof typeof COMPANY_STATUS];
export type TPresignedFileTypes = typeof PRESIGNED_FILE_TYPES[keyof typeof PRESIGNED_FILE_TYPES];
export type TOrders = typeof ORDERS[keyof typeof ORDERS];
export type TOrderByIdentifier = string;
export type TUserRoleTypes = typeof USER_ROLE_TYPES[keyof typeof USER_ROLE_TYPES];
export type TMfaEndpoints = typeof MFA_ENDPOINTS[keyof typeof MFA_ENDPOINTS];
export type TAuthenticatorSetupModalSteps = typeof AUTHENTICATOR_SETUP_MODAL_STEPS[
  keyof typeof AUTHENTICATOR_SETUP_MODAL_STEPS
];
export type TUserPillars = typeof USER_PILLARS[keyof typeof USER_PILLARS] | string; // TODO: Remove string
export type TNumberFormats = typeof NUMBER_FORMATS[keyof typeof NUMBER_FORMATS];
export type TDataSetEnumTypes = typeof DATA_SETS_ENUM_TYPES[keyof typeof DATA_SETS_ENUM_TYPES];
export type TUidNameTuples = typeof UID_NAME_TUPLES[keyof typeof UID_NAME_TUPLES];
export type TEnumTypes = typeof DATA_SETS_ENUM_TYPES[keyof typeof DATA_SETS_ENUM_TYPES];

export type TFilterTabs = typeof FILTER_TABS[keyof typeof FILTER_TABS];

export type TUserPillarModules =
    | 'settings/user-management'
    | 'settings/multi-lingual'
    | 'settings/ui-configuration'
    | 'settings/data-sets-management'
    | 'value-chain-assessment/entity-editing'
    | 'value-chain-assessment/entity-linking'
    | 'materiality-assessment/entity-editing'
    | 'materiality-assessment/entity-linking';
