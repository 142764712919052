import ClientsFilters from './components/ClientsFilters/ClientsFilters';
import ClientsHeading from './components/ClientsHeading/ClientsHeading';
import ClientsTable from './components/ClientsTable/ClientsTable';

import type IClientsView from './interfaces/IClientsView';

import styles from './scss/ClientsView.module.scss';

function ClientsView({
  control, companies, onSearch, onFilterChange, onSortChange,
  onReadMoreClients, hasNext, isWithSearch, isPageLoading,
}: IClientsView) {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <ClientsHeading />
        <ClientsFilters
          control={control}
          onSearch={onSearch}
          onFilterChange={onFilterChange}
        />
        <ClientsTable
          control={control}
          onSortChange={onSortChange}
          companies={companies}
          onReadMoreClients={onReadMoreClients}
          hasNext={hasNext}
          isWithSearch={isWithSearch}
          isPageLoading={isPageLoading}
        />
      </div>
    </div>
  );
}

export default ClientsView;
