import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppRoutes from 'constants/AppRoutes';
import { useUserMeQuery } from 'store/slices/user/apis/user';

import ProfileView from './ProfileView';

import type { ReactElement } from 'react';

function Profile(): ReactElement {
  const navigate = useNavigate();
  const { data, isLoading } = useUserMeQuery();
  const companyLanguages = data?.data?.company?.localization?.languages || [];
  const isInitiator = !!data?.data?.initiator;

  useEffect(() => {
    if (isInitiator) {
      navigate(AppRoutes.launchpad);
    }
  }, [isInitiator, navigate]);

  return (
    <ProfileView companyLanguages={companyLanguages} isLoading={isLoading} />
  );
}

export default Profile;
