import {
  ADDRESS_BUILDING,
  ADDRESS_CITY,
  ADDRESS_COORDINATES,
  ADDRESS_COUNTRY, ADDRESS_POST_CODE, ADDRESS_STATE, ADDRESS_STREET,
  ASSET_CATEGORY,
  BUSINESS_UNIT_TYPE,
  DATE_CLOSED,
  DATE_OPENED,
  EQUITY_SHAREHOLDING, EU_TAXONOMY_CODE,
  FINANCIAL_CONTROL, LEGAL_NAME,
  NACE_CODE,
  NACE_SECTION, NAME,
  NET_BOOK,
  NET_BOOK_CURRENCY,
  NUMBER_OF_EMPLOYEES, ORG_PATH,
  OWNED_LEASED,
  OWNERSHIP,
  PROFIT_CENTER_ID, UNIQUE_IDENTIFIER,
  VALUE_CHAIN_SECTION,
} from 'constants/formFields';
import { DATA_SETS_DATE_FORMAT, DATE_FORMAT_ISO } from 'constants/general';
import dayjs from 'dayjs';

import type { IGetRequestDataForOwnOperations } from './interfaces/IGetRequestDataForOwnOperations';

function getRequestDataForOwnOperations({ values, recordId }: IGetRequestDataForOwnOperations) {
  return {
    [NAME]: values?.[NAME] || null,
    [ORG_PATH]: values?.[ORG_PATH] || null,
    [LEGAL_NAME]: values?.[LEGAL_NAME] || null,
    [ASSET_CATEGORY]: (values?.[ASSET_CATEGORY]?.length && values?.[ASSET_CATEGORY]?.map((item) => item.value)) || null,
    [BUSINESS_UNIT_TYPE]: values?.[BUSINESS_UNIT_TYPE].value || null,
    [NACE_SECTION]: values?.[NACE_SECTION].value || null,
    [NACE_CODE]: values?.[NACE_CODE]?.value || null,
    [NET_BOOK]: values?.[NET_BOOK] ? {
      amount: Number(values?.[NET_BOOK]),
      currency: values?.[NET_BOOK_CURRENCY]?.value,
    } : null,
    [OWNED_LEASED]: values?.[OWNED_LEASED]?.value || null,
    [OWNERSHIP]: values?.[OWNERSHIP]?.value || null,
    [VALUE_CHAIN_SECTION]: values?.[VALUE_CHAIN_SECTION]?.value || null,
    [PROFIT_CENTER_ID]: values?.[PROFIT_CENTER_ID]?.map((item) => item.value) || null,
    [EQUITY_SHAREHOLDING]: values?.[EQUITY_SHAREHOLDING] ? Number(values?.[EQUITY_SHAREHOLDING]) : null,
    [FINANCIAL_CONTROL]: values?.[FINANCIAL_CONTROL] ? Number(values?.[FINANCIAL_CONTROL]) : null,
    [NUMBER_OF_EMPLOYEES]: values?.[NUMBER_OF_EMPLOYEES] ? Number(values?.[NUMBER_OF_EMPLOYEES]) : null,
    [DATE_OPENED]: values?.[DATE_OPENED] ? dayjs(values?.[DATE_OPENED], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [DATE_CLOSED]: values?.[DATE_CLOSED] ? dayjs(values?.[DATE_CLOSED], DATA_SETS_DATE_FORMAT).format(DATE_FORMAT_ISO)
      : null,
    [EU_TAXONOMY_CODE]: values?.[EU_TAXONOMY_CODE] || null,
    [ADDRESS_COUNTRY]: values?.[ADDRESS_COUNTRY]?.label || null,
    [ADDRESS_STATE]: values?.[ADDRESS_STATE]?.label || null,
    [ADDRESS_POST_CODE]: values?.[ADDRESS_POST_CODE] || null,
    [ADDRESS_CITY]: values?.[ADDRESS_CITY]?.label || null,
    [ADDRESS_STREET]: values?.[ADDRESS_STREET] || null,
    [ADDRESS_BUILDING]: values?.[ADDRESS_BUILDING] || null,
    [ADDRESS_COORDINATES]: values?.[ADDRESS_COORDINATES] || null,
    ...(!recordId && { [UNIQUE_IDENTIFIER]: values?.[UNIQUE_IDENTIFIER] }),
    ...(recordId && { id: Number(recordId) }),
  };
}

export default getRequestDataForOwnOperations;
