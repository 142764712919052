import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ClientPanelFooter from 'components/ClientPanel/ClientPanelFooter';
import Modal from 'components/Modal/Modal';
import Panel from 'components/Panel/Panel';
import PasswordRules from 'components/Password/PasswordRules/PasswordRules';
import PasswordStrengthProgress from 'components/Password/PasswordStrengthProgress/PasswordStrengthProgress';
import Separator from 'components/Separator/Separator';
import Subtitle from 'components/Subtitle/Subtitle';
import TextField from 'components/TextField/TextField';
import {
  CURRENT_PASSWORD, PASSWORD,
  REPEAT_NEW_PASSWORD,
} from 'constants/formFields';
import { REPLACE_DOUBLE_SPACE_REGEX } from 'constants/general';
import { INPUT_TYPES } from 'constants/interfaces';

import type { IProfilePasswordView } from './interfaces/IProfilePasswordView';

import styles from './sass/ProfilePasswordView.module.scss';

function ProfilePasswordView({
  control, onProfilePasswordSubmit, passwordStrengthMeter, onCancel, isModalOpen,
  onConfirmationModalOpen, isValid, onReject, isRecoverPasswordPath, isLoading,
}: IProfilePasswordView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Panel className={styles.panel}>
        <Subtitle
          text={t('profilePassword.title')}
          className={styles.title}
        />
        <Separator />
        <div className={styles.wrapper}>
          <Controller
            control={control}
            name={CURRENT_PASSWORD}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                label={t('profilePassword.currentPasswordLabel')}
                type={INPUT_TYPES.password}
                placeholder={t('profilePassword.enterPassword')}
                error={error?.message}
                onChange={(event) => onChange(event.target.value.replace(REPLACE_DOUBLE_SPACE_REGEX, ' '))}
                required
              />
            )}
          />
          <Controller
            control={control}
            name={PASSWORD}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <TextField
                name={name}
                value={value}
                type={INPUT_TYPES.password}
                label={t('profilePassword.newPasswordLabel')}
                placeholder={t('profilePassword.enterPassword')}
                error={error?.message}
                className={styles.newPassword}
                onChange={(event) => onChange(event.target.value.replace(REPLACE_DOUBLE_SPACE_REGEX, ' '))}
                required
              />
            )}
          />
          <Controller
            control={control}
            name={REPEAT_NEW_PASSWORD}
            render={({ field: { name, value, onChange }, fieldState: { error } }) => (
              <>
                <TextField
                  name={name}
                  value={value}
                  type={INPUT_TYPES.password}
                  label={t('profilePassword.repeatNewPasswordLabel')}
                  placeholder={t('profilePassword.enterPassword')}
                  error={error?.message}
                  className={styles.repeatNewPassword}
                  onChange={(event) => onChange(event.target.value.replace(REPLACE_DOUBLE_SPACE_REGEX, ' '))}
                  required
                />
                <div className={styles.passwordRuleWrapper}>
                  <PasswordStrengthProgress progress={passwordStrengthMeter} />
                  <PasswordRules isRecoverPasswordPath={isRecoverPasswordPath} />
                </div>
              </>
            )}
          />

        </div>
        <Separator />
        <ClientPanelFooter
          onSave={onConfirmationModalOpen}
          onCancel={onCancel}
          isDisabledSave={!isValid}
          isLoading={isLoading}
        />

      </Panel>
      <Modal
        open={isModalOpen}
        title={t('profilePassword.confirmModalTitle')}
        description={t('profilePassword.confirmModalDescription')}
        confirmTitle={t('profilePassword.confirmModalButton')}
        closeTitle={t('common.close')}
        onConfirm={onProfilePasswordSubmit}
        onReject={onReject}
      />
    </div>
  );
}

export default ProfilePasswordView;
