import { useTranslation } from 'react-i18next';
import ClientPanel from 'components/ClientPanel/ClientPanel';

import SecurityForm from './components/SecurityForm/SecurityForm';

import type ISecurityView from './interfaces/ISecurityView';

function SecurityView({
  isCreatePage, isLastStep, onPrevClick, onSave,
  isDirty, isDisabledSave, control, isLoading,
}: ISecurityView) {
  const { t } = useTranslation();

  return (
    <ClientPanel
      title={t('clients.security')}
      text={t('clients.setAuthentication')}
      onPrevClick={onPrevClick}
      onSave={onSave}
      isCreate={isCreatePage}
      isLastStep={isLastStep}
      isDirty={isDirty}
      isDisabledSave={isDisabledSave}
      isLoading={isLoading}
    >
      <SecurityForm
        control={control}
      />
    </ClientPanel>
  );
}

export default SecurityView;
