import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import Panel from 'components/Panel/Panel';
import Separator from 'components/Separator/Separator';
import Subtitle from 'components/Subtitle/Subtitle';
import AuthenticationSetupModal from 'pages/AuthPages/AuthenticationSetup/components/AuthenticationSetupModal';

import MFA from './components/MFA';
import MFAPhoneSetupModal from './components/MFAPhoneSetupModal';

import type { ISecurityView } from './interfaces/ISecurityView';

import styles from './sass/SecurityView.module.scss';

function SecurityView({
  isMfaAppEnabled, isMfaSmsEnabled, isMfaAppConnected, isMfaSmsConnected, onDeactivateSecurity, phone,
  isModalOpen, setIsModalOpen, onConfirmationModalOpen, onMfaAppCreate,
  steps, isModalVisible, setIsSecretCodeVisible, onMfaSmsCreate, isMfaPhoneModalVisible,
  isMfaAppModalVisible, onModalClose, isMfaAppCurrent, isBlocker, isBlockerModalOpen, onResetBlockers, isLoading,
}:ISecurityView) {
  const { t } = useTranslation();

  const isLastConnectedMfa = !isMfaAppConnected || !isMfaSmsConnected;

  const titleTranslationKey = isMfaAppCurrent
    ? 'confirmAppRemovalTitle' : 'confirmPhoneRemovalTitle';

  const descriptionTranslationKey = isLastConnectedMfa
    ? 'confirmRequiredRemovalDescription' : 'confirmRemovalDescription';

  return (
    <div className={styles.root}>
      <Panel className={styles.panel}>
        <Loader isPageLoading={isLoading} isFullLoader={false} />
        <Subtitle
          text={t('profileSecurity.title')}
          className={styles.title}
        />
        <Separator />
        <div className={styles.wrapper}>
          {isMfaAppEnabled ? (
            <MFA
              isMfaApp
              isConnected={isMfaAppConnected}
              isSeparator={isMfaSmsEnabled}
              onConfirmationModalOpen={onConfirmationModalOpen}
              onMfaAppCreate={onMfaAppCreate}
            />
          ) : null}

          {isMfaSmsEnabled ? (
            <MFA
              isMfaApp={false}
              isConnected={isMfaSmsConnected}
              phone={phone}
              isSeparator={false}
              onConfirmationModalOpen={onConfirmationModalOpen}
              onMfaSmsCreate={onMfaSmsCreate}
            />
          ) : null}

        </div>

        <Modal
          open={isModalOpen}
          title={t(`profileSecurity.${isBlocker ? 'requiredMfaTitle' : titleTranslationKey}`)}
          description={t(`profileSecurity.${isBlocker ? 'requiredMfaDescription' : descriptionTranslationKey}`)}
          confirmTitle={t('common.reset')}
          closeTitle={t('common.close')}
          onConfirm={() => { onDeactivateSecurity(); }}
          onReject={() => setIsModalOpen(false)}
          isLoading={isLoading}
        />

        <Modal
          open={isBlockerModalOpen}
          title={t('profileSecurity.requiredMfaTitle')}
          description={t('profileSecurity.requiredMfaDescription')}
          closeTitle={t('common.ok')}
          onReject={onModalClose}
        />

        {isMfaAppModalVisible ? (
          <AuthenticationSetupModal
            steps={steps}
            isModalVisible={isModalVisible}
            setIsSecretCodeVisible={setIsSecretCodeVisible}
            isProfile
            onModalClose={onModalClose}
            onResetBlockers={onResetBlockers}
          />
        ) : null}

        {isMfaPhoneModalVisible ? (
          <MFAPhoneSetupModal
            onModalClose={onModalClose}
            onResetBlockers={onResetBlockers}
          />
        ) : null}

      </Panel>

    </div>
  );
}

export default SecurityView;
