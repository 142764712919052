import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import PhoneField from 'components/PhoneField/PhoneField';
import { PHONE } from 'constants/formFields';

import type { IVerificationSmsSetupView } from './interfaces/IVerificationSmsSetupView';

import styles from './sass/VerificationSmsSetupView.module.scss';

function VerificationSmsSetupView({
  control,
  onVerificationCodeSubmit,
  isValid,
  setParsedCountry,
  isLoading,
}:IVerificationSmsSetupView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className="authTitle">
        {t('login.title')}
      </div>
      <div className={styles.formWrapper}>
        <Controller
          control={control}
          name={PHONE}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <PhoneField
              name={name}
              value={value}
              label={t('common.phoneNumber')}
              error={error?.message}
              onChange={(valuePhone, { country }) => {
                setParsedCountry(country);
                onChange(valuePhone);
              }}
              required
              className={styles.phone}
            />
          )}
        />
      </div>
      <Button
        className={styles.button}
        title={t('common.continue')}
        onClick={onVerificationCodeSubmit}
        disabled={!isValid}
        isLoading={isLoading}
      />
    </div>
  );
}

export default VerificationSmsSetupView;
