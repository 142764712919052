import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { USER_ROLE_TYPES, USER_STATUS } from 'constants/interfaces';
import {
  useActivateAdminUserMutation, useDeactivateAdminUserMutation, useDeleteAdminUserMutation,
} from 'store/slices/adminUsers/apis/adminUsersApi';
import { useInviteUserMutation } from 'store/slices/auth/apis/auth';
import {
  useActivateCompanyAdminUserMutation, useDeactivateCompanyAdminUserMutation, useDeleteCompanyAdminUserMutation,
} from 'store/slices/companyAdminUsers/apis/companyAdminUsersApi';

import i18n from '../i18n';
import { useUserMeQuery } from '../store/slices/user/apis/user';

import type IUseUserStatusChange from './interfaces/IUseUserStatusChange';

const useUserStatusChange = ({
  userId, status, email,
}: IUseUserStatusChange) => {
  const { clientId = '' } = useParams();
  const [isDeactivationModalOpen, setIsDeactivationModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { data } = useUserMeQuery();

  const [inviteUser, { isLoading: isInviteUserLoading }] = useInviteUserMutation();
  const [activateAdminUser, { isLoading: isActivateAdminLoading }] = useActivateAdminUserMutation();
  const [deactivateUser, { isLoading: isDeactivateAdminLoading }] = useDeactivateAdminUserMutation();
  const [deleteUser, { isLoading: isDeleteAdminLoading }] = useDeleteAdminUserMutation();

  const isNewUser = USER_STATUS.draft === status || USER_STATUS.invited === status;
  const [activateCompanyAdminUser, { isLoading: isActivateCompanyLoading }] = useActivateCompanyAdminUserMutation();
  const [deactivateCompanyAdminUser, {
    isLoading: isDeactivateCompanyLoading,
  }] = useDeactivateCompanyAdminUserMutation();
  const [deleteCompanyAdminUser, { isLoading: isDeleteCompanyLoading }] = useDeleteCompanyAdminUserMutation();

  const { role } = data?.data || {};

  const isCompanyAdmin = role === USER_ROLE_TYPES.companyAdmin;

  const activateUserAction = isCompanyAdmin ? activateCompanyAdminUser : activateAdminUser;
  const deactivateUserAction = isCompanyAdmin ? deactivateCompanyAdminUser : deactivateUser;
  const deleteUserAction = isCompanyAdmin ? deleteCompanyAdminUser : deleteUser;

  const onToggleDeactivationModal = () => {
    setIsDeactivationModalOpen((prev) => !prev);
  };

  const onToggleDeleteModal = () => {
    setIsDeleteModalOpen((prev) => !prev);
  };

  const onChangeUserStatus = () => {
    if (isNewUser && email) {
      inviteUser({ email }).unwrap().then(
        () => {
          toast.success(i18n.t('notifications.userInvited'));
        },
      ).catch(() => {});
    } else if (USER_STATUS.active === status) {
      setIsDeactivationModalOpen(true);
    } else if (userId) {
      activateUserAction({ userId, ...(clientId && { companyId: Number(clientId) }) }).unwrap()
        .catch(() => {});
    }
  };

  const onUserDeactivate = (reason: string) => {
    if (userId) {
      deactivateUserAction({
        userId,
        reason,
        ...(clientId && { companyId: Number(clientId) }),
      }).unwrap()
        .then(() => {
          setIsDeactivationModalOpen(false);
        })
        .catch(() => {});
    }
  };

  const onUserDelete = () => new Promise((resolve, reject) => {
    if (userId) {
      deleteUserAction({ userId, ...(clientId && { companyId: Number(clientId) }) }).unwrap()
        .then(() => {
          setIsDeleteModalOpen(false);
          resolve(userId);
        })
        .catch(() => {});
    } else {
      reject(new Error('No user ID provided'));
    }
  });

  const isActionsLoading = isInviteUserLoading || isActivateAdminLoading || isDeactivateAdminLoading
      || isDeleteAdminLoading || isActivateCompanyLoading || isDeactivateCompanyLoading || isDeleteCompanyLoading;

  return {
    isNewUser,
    isDeactivationModalOpen,
    isDeleteModalOpen,
    onToggleDeactivationModal,
    onToggleDeleteModal,
    onChangeUserStatus,
    onUserDeactivate,
    onUserDelete,
    isActionsLoading,
  };
};

export default useUserStatusChange;
