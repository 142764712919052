import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TimerIcon } from 'assets/icons/timer.svg';
import { clsx } from 'clsx';
import Button from 'components/Button/Button';
import TextField from 'components/TextField/TextField';
import { SMS_CODE } from 'constants/formFields';
import { VERIFICATION_SMS_MASK } from 'constants/inputMasks';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import type { IVerificationSmsView } from './interfaces/IVerificationSmsView';

import styles from './sass/VerificationSmsView.module.scss';

dayjs.extend(duration); // TODO: Create global dayjs configuration

function VerificationSmsView({
  control,
  onVerificationSmsSubmit,
  onResendCode,
  time,
  isTimeUp,
  phone,
  isButtonDisabled,
  isInsideModal,
  isLoading,
}: IVerificationSmsView) {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      {!isInsideModal && (
      <div className="authTitle">
        {t('login.title')}
      </div>
      )}
      <div className={clsx(styles.subTitle, {
        [styles.subTitleModal]: isInsideModal,
      })}
      >
        {t('verificationSms.subTitle', { phone })}
      </div>
      <div className={styles.formWrapper}>
        <Controller
          control={control}
          name={SMS_CODE}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextField
              name={name}
              value={value}
              label={t('verificationSms.SMSCode')}
              error={error?.message}
              onChange={(event) => onChange(event.target.value)}
              mask={VERIFICATION_SMS_MASK}
              alwaysShowMask
              required
              className={styles.inputWrapper}
            />
          )}
        />
      </div>
      <div className={styles.timerWrapper}>
        <div className={styles.timer}>
          <TimerIcon />
          <span>
            {t(
              'verificationSms.timeLeft',
              {
                minutes: dayjs.duration(time, 'seconds').format('m'),
                seconds: dayjs.duration(time, 'seconds').format('ss'),
              },
            )}
          </span>
        </div>
        {isTimeUp ? (
          <Button
            className={styles.resendCode}
            title={t('verificationSms.resendCode')}
            onClick={onResendCode}
          />
        ) : null}

      </div>
      {!isInsideModal && (
        <Button
          className={styles.button}
          title={t('common.continue')}
          onClick={onVerificationSmsSubmit}
          disabled={isButtonDisabled}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default VerificationSmsView;
