import { useTranslation } from 'react-i18next';
import ClientPanel from 'components/ClientPanel/ClientPanel';
import SaveDraftButton from 'components/SaveDraftButton/SaveDraftButton';

import ModuleManagementForm from './components/ModuleManagementForm/ModuleManagementForm';

import type IMultilingualView from './interfaces/IModuleManagementView';

function ModuleManagementView({
  control, isCreatePage, onPrevClick, onSave,
  pillars, isDisabledSave, isDirty, onResetPillarModules, onDraftSave, isLoading,
}: IMultilingualView) {
  const { t } = useTranslation();

  return (
    <>
      {isCreatePage ? <SaveDraftButton isDisabled={!isDirty || isDisabledSave} onDraftSave={onDraftSave} /> : null }
      <ClientPanel
        title={t('clients.moduleManagement')}
        text={t('clients.configureModules')}
        onPrevClick={onPrevClick}
        onSave={onSave}
        isCreate={isCreatePage}
        isDirty={isDirty}
        isDisabledSave={isDisabledSave}
        isLoading={isLoading}
      >
        <ModuleManagementForm
          control={control}
          pillars={pillars}
          onResetPillarModules={onResetPillarModules}
        />
      </ClientPanel>
    </>
  );
}

export default ModuleManagementView;
